import { Button, useTheme } from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { radiologyColor } from './Utils/RadiologyColors'

type Props = {
  title: string
  loading?: boolean
  compact?: boolean
  disable?: boolean
  onClick: () => void
}
const styles = () => ({
  basebutton: {
    border: 'none',
    borderRadius: '4px',
    backgroundColor: radiologyColor?.corporateBlue,
    color: radiologyColor?.white,

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: radiologyColor?.corporateBlue,
    },
  },
  createButton: {
    padding: '6px 12px',
  },

  compactButton: {
    fontSize: '13px',
    padding: '4px 8px',
  },
})

const RadiologyLoadingButton = ({
  title,
  loading,
  compact,
  disable,
  onClick,
}: Props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const handleClick = () => {
    if (!loading) {
      onClick()
    }
  }

  return (
    <Button
      onClick={handleClick}
      disabled={(loading || disable) ?? false}
      size='small'
      variant='contained'
      className={`${classes?.basebutton} ${
        compact ? classes?.compactButton : classes?.createButton
      }`}
    >
      {title}
    </Button>
  )
}

export default RadiologyLoadingButton

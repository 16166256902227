import React from 'react'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import IpdMedicationDashboard from './IpdMedicationDashboard'
import IpdLabOrderSummary from './IpdLabOrderSummary'
import IpdDiagnosisSummary from './IpdDiagnosisSummary'
import './IpdVisitSummary.scss'
import IpdTreatmentPlanSummary from './IpdTreatmentPlanSummary'
import IpdMedicationAdministration from './IpdMedicationAdministration'
import IpdRadiologyOrderSummary from './IpdRadiologyOrderSummary'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function IpdSummaryTabs(props: any) {
  const { allMedications, patientRegisteredId, setRefresh } = props
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          sx={{
            backgroundColor: '#4682b4',
            '.MuiButtonBase-root': {
              textTransform: 'capitalize',
              color: '#ffffff',
            },
            '&.MuiTabs-root .MuiTab-root': {
              color: '#ffffff',
              fontSize: '12px',
              fontWeight: '500',
            },
            '&.MuiTabs-root .Mui-selected': {
              color: '#F7AC25',
              fontWeight: '600',
              fontSize: '12px',
            },
            '&.MuiTabs-root .MuiTabs-indicator': { backgroundColor: '#F7AC25' },
          }}
        >
          <Tab label='Initial Assessment' {...a11yProps(0)} />
          <Tab label='Diagnosis' {...a11yProps(1)} />
          <Tab label='Medications' {...a11yProps(2)} />
          <Tab label=' Medication Administration' {...a11yProps(3)} />
          <Tab label='Treatment Plan' {...a11yProps(4)} />
          <Tab label='Lab Orders' {...a11yProps(5)} />
          <Tab label='Radiology Orders' {...a11yProps(6)} />
          <Tab label='ICU Chart' {...a11yProps(7)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        {/* <InitialAssessment /> */}
        <div>Initial Assessment</div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <IpdDiagnosisSummary patientRegisteredId={patientRegisteredId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <IpdMedicationDashboard
          allMedications={allMedications}
          setRefresh={setRefresh}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <IpdMedicationAdministration
          patientRegisteredId={patientRegisteredId}
        />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={4}>
        <IpdTreatmentPlanSummary patientRegisteredId={patientRegisteredId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={5}>
        <IpdLabOrderSummary patientRegisteredId={patientRegisteredId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={6}>
        <IpdRadiologyOrderSummary patientRegisteredId={patientRegisteredId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={7}>
        ICU Chart
      </CustomTabPanel>
    </Box>
  )
}

import { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Asterisk from '../../../components/Asterisk'
import { startSxpProxy } from '../../../utils/api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import ProfilePicture from '../../patientRegstration/ProfilePicture'
import '../admin.scss'
import {
  formValues,
  forms,
  intent,
  messages,
  placeHolder,
  tableText,
} from '../constants'
import { emptyString } from '../../Radiology/Constants/constants'
import { Box, Button } from '@mui/material'

export type FormPathologist = {
  id: string
  salutation: string
  firstName: string
  middleName?: string
  lastName: string
  speciality: string
  qualificationValue: string
  regNumber: string
  email: string
  phone: string
  gender: string
  dob: string
  active: boolean
  registeredOn?: string
  photo?: string
  signature?: string
}
const initialData: FormPathologist = {
  id: '',
  salutation: '',
  firstName: '',
  middleName: '',
  lastName: '',
  speciality: '',
  qualificationValue: '',
  regNumber: '',
  email: '',
  phone: '',
  gender: '',
  dob: '',
  active: true,
  registeredOn: '',
  photo: '',
  signature: '',
}

const PathologistForm = ({
  pathologist,
}: {
  pathologist: FormPathologist | null
}) => {
  const [formData, setFormData] = useState<FormPathologist>(
    pathologist ?? initialData
  )
  const [specialty, setSpecialty] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.getLabDepartments)
      .then((data) => {
        const specialtyList =
          data?.data?.entry?.[0]?.resource?.compose?.include[0]?.concept
        if (specialtyList) {
          setSpecialty(specialtyList)
          setLoading(false)
        }
      })
      .catch((err) => console.error(err))
  }, [])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()
    if (formData.phone.length !== 10 || !/^\d{10}$/.test(formData.phone)) {
      toast.error(messages.validPhoneMessage)
      return false
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (
      formData.email.trim() !== '' &&
      !emailRegex.test(formData.email.trim())
    ) {
      toast.error(messages.ValidEmailMessage)
      return false
    }
    if (
      !/^[a-z A-Z]+$/.test(formData.firstName) ||
      !/^[a-z A-Z]+$/.test(formData.lastName)
    ) {
      toast.error(messages.validNameMessage)
      return false
    }
    const spec = formData.speciality.split('::')
    const state = {
      id: '',
      active: true,
      lastName: formData.lastName,
      firstName: formData.firstName,
      middleName: '',
      salutation: formData.salutation,
      email: formData.email,
      mobileNumber: formData.phone,
      regNumber: formData.regNumber,
      qualificationValue: formData.qualificationValue,
      gender: formData.gender,
      dob: formData.dob,
      specialtyId: spec[1],
      specialtyName: spec[0],
      photo: formData.photo,
      signature: formData.signature,
    }
    if (pathologist) {
      state.id = pathologist.id
      state.active = pathologist.active
      startSxpProxy(ADMIN_PROJECT_ID, intent.editLabIncharge, state)
        .then(() => {
          toast.success(messages.successDoctorMessage, toastOptions)
          navigate('/administration/pathologist')
        })
        .catch((error) => {
          toast.error(
            <ToastMessage message={error?.response?.data?.message} />,
            toastOptions
          )
        })
    } else {
      startSxpProxy(ADMIN_PROJECT_ID, intent.createLabIncharge, state)
        .then(() => {
          toast.success(messages.createdDoctorSuccess, toastOptions)
          navigate('/administration/pathologist')
        })
        .catch((error) => {
          toast.error(
            <ToastMessage message={error?.response?.data?.message} />,
            toastOptions
          )
          setFormData((prev) => ({ ...prev, phone: emptyString }))
        })
    }
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    setFormData({
      ...formData,
      [name]: value,
    })
  }

  const handlePhotoChange = (photo: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photo: photo,
    }))
  }
  const handleSignatureChange = (signature: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      signature: signature,
    }))
  }

  return loading ? (
    <p>{forms.loading}</p>
  ) : (
    <>
      <div className='main-container'>
        <form className='admin-form' onSubmit={handleSubmit}>
          <div className='admin-form-flex w-72'>
            <div className='pathology-container'>
              <label htmlFor='salutation' className='pathology-input-label'>
                {forms?.salutation}
                <Asterisk />
                &nbsp;:
              </label>
              <select
                required
                name='salutation'
                className='pathology-input-content'
                value={formData?.salutation}
                onChange={handleChange}
              >
                <option value=''></option>
                <option value='Mr'>Mr</option>
                <option value='Mrs'>Mrs</option>
                <option value='Miss'>Miss</option>
                <option value='Dr.'>Dr.</option>
                <option value='Master'>Master</option>
              </select>
            </div>
            <div className='pathology-container'>
              <label htmlFor='firstName' className='pathology-input-label'>
                {forms?.firstName}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                required
                name='firstName'
                type='text'
                className='pathology-input-content'
                placeholder={placeHolder?.firstName}
                value={formData?.firstName}
                onChange={handleChange}
                maxLength={40}
              />
            </div>
            <div className='pathology-container'>
              <label htmlFor='lastName' className='pathology-input-label'>
                {forms?.lastName}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                required
                name='lastName'
                type='text'
                className='pathology-input-content'
                placeholder={placeHolder?.lastName}
                value={formData?.lastName}
                onChange={handleChange}
                maxLength={40}
              />
            </div>
            <div className='pathology-container'>
              <label htmlFor='gender' className='pathology-input-label'>
                {forms?.gender}
                <Asterisk />
                &nbsp;:
              </label>
              <select
                required
                name='gender'
                className='pathology-input-content'
                value={formData?.gender}
                onChange={handleChange}
              >
                <option value={emptyString}></option>
                <option value={formValues?.male}>{formValues?.male}</option>
                <option value={formValues?.female}>{formValues?.female}</option>
                <option value={formValues?.other}>{formValues?.other}</option>
              </select>
            </div>
            <div className='pathology-container'>
              <label htmlFor='dob' className='pathology-input-label'>
                {forms?.dob}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                required
                type='date'
                id='dob'
                className='pathology-input-content'
                value={formData?.dob}
                onChange={(e) =>
                  setFormData({ ...formData, dob: e.target.value })
                }
              />
            </div>
            <div className='pathology-container'>
              <label htmlFor='department' className='pathology-input-label'>
                {forms?.department}
                <Asterisk />
                &nbsp;:
              </label>
              <select
                required
                name='speciality'
                className='pathology-input-content'
                value={formData?.speciality}
                onChange={handleChange}
              >
                <option value={emptyString}></option>
                {specialty.map((item: any) => (
                  <option
                    key={item?.code}
                    value={`${item?.display}::${item?.code}`}
                  >
                    {item.display}
                  </option>
                ))}
              </select>
            </div>
            <div className='pathology-container'>
              <label htmlFor='qualification' className='pathology-input-label'>
                {forms?.qualification}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                name='qualificationValue'
                type='text'
                className='pathology-input-content'
                placeholder='Enter Qualification'
                value={formData?.qualificationValue}
                onChange={handleChange}
                maxLength={40}
              />
            </div>
            <div className='pathology-container'>
              <label htmlFor='regNumber' className='pathology-input-label'>
                {forms?.registrationNumber}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                name='regNumber'
                type='text'
                className='pathology-input-content'
                placeholder='Enter Registration Number'
                value={formData?.regNumber}
                onChange={handleChange}
                maxLength={40}
              />
            </div>
            <div className='pathology-container'>
              <label htmlFor='email' className='pathology-input-label'>
                {tableText?.email}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                name='email'
                type='text'
                className='pathology-input-content'
                placeholder={placeHolder?.email}
                value={formData?.email}
                onChange={handleChange}
              />
            </div>
            <div className='pathology-container'>
              <label htmlFor='phone' className='pathology-input-label'>
                {tableText?.phone}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                name='phone'
                type='text'
                className='pathology-input-content'
                placeholder={placeHolder?.phone}
                value={formData?.phone}
                onChange={handleChange}
                maxLength={10}
              />
            </div>
            <div className='pathology-container'>
              <div className='pathology-input-label'></div>
              <div className='pathology-input-content'>
                <Box className='box-btn-pathology'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className='submit-btn-pathology'
                  >
                    Submit
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </form>
        <div className='picture-container'>
          <ProfilePicture
            photo={formData?.photo as string}
            onPhotoChange={handlePhotoChange}
            item='admin'
          />
          <ProfilePicture
            photo={formData?.signature as string}
            onPhotoChange={handleSignatureChange}
            item='signature'
          />
        </div>
      </div>
    </>
  )
}

export default PathologistForm

import React, { useRef, useEffect } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsAdminSearch,
  selectedLmsLocationSearch,
} from '../../lmsSlice'
import { getBedResults } from '../../utils'
import LinkButton from './LinkButton'
import { bedType } from '../../constants'
import { allocation } from '../../../bedManagement/modals'
import { emptyString } from '../../../Radiology/Constants/constants'

const Beds = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const locationSearch = useAppSelector(selectedLmsLocationSearch)
  const filtered = getBedResults(data, searchParam, locationSearch)
  filtered?.sort?.((a: { bed_number: string }, b: { bed_number: string }) =>
    a.bed_number?.toLowerCase() < b.bed_number?.toLowerCase() ? -1 : 1
  )
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={sampleTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead className='sticky'>
          <tr>
            <th className='table-w-5'>{bedType.seqNo}</th>
            <th>{bedType.bedName}</th>
            <th>{bedType.roomName}</th>
            <th>{bedType.bedCategory}</th>
            <th>{bedType.floor}</th>
            <th>{bedType.location}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.sequence_number ?? '-'}</td>
                <td>
                  <LinkButton id={d.id} name={d.bed_number} onClick={onEdit} />
                </td>
                <td>{d?.room_detail?.room_name}</td>
                <td>
                  {d?.bed_category_id === 3
                    ? allocation.critical
                    : allocation.nonCritical}
                </td>
                <td>{d.room_detail.floor_number ?? emptyString}</td>
                <td>{d?.location_id}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {bedType.noBeds}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Beds

/* eslint-disable camelcase */
import { useEffect, useState } from 'react'
import { Patient } from 'fhir/r4'
import { useParams } from 'react-router-dom'
import { startSxpProxy } from '../../../../utils/api'
import { OPD_PROJECT_ID } from '../../../../utils/constants'
import RadiologyOrder from './RadiologyOrder'
import {
  setRadiologyPatientCheck,
  setRadiologyShowBack,
} from '../../RadiologySlice'
import { useAppDispatch } from '../../../../app/hooks'
import { patientText, radiologyIntents } from '../../Constants/constants'
import RadiologyPatientInfo from './RadiologyPatientInfo'

const CreateRadiologyOrder = () => {
  const { id } = useParams<{ id?: string }>()
  const [loading, setLoading] = useState(false)
  const [patient, setPatient] = useState<Patient | null>(null)
  const dispatch = useAppDispatch()
  const fetchData = async () => {
    setLoading(true)
    try {
      const patientData = await startSxpProxy(
        OPD_PROJECT_ID,
        radiologyIntents?.getPatientApi,
        { id }
      )
      setPatient(patientData?.data ?? null)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    if (id) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  useEffect(() => {
    if (!patient) {
      dispatch(setRadiologyShowBack(''))
      dispatch(setRadiologyPatientCheck(false))
    } else {
      dispatch(setRadiologyPatientCheck(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient])
  return (
    <div>
      <RadiologyPatientInfo patient={patient} loading={loading} />
      <br />
      {patient && <RadiologyOrder patient={patient} modeType={patientText} />}
    </div>
  )
}

export default CreateRadiologyOrder

import { IconButton } from '@mui/material'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { useNavigate } from 'react-router-dom'
import PartnerForm from './PartnerForm'
import { radiologyEndPoint } from '../Radiology/RadiologyEndPoints'

const CreateOrganizationForm = () => {
  const navigate = useNavigate()
  return (
    <>
      <IconButton onClick={() => navigate(radiologyEndPoint?.referredOut)}>
        <KeyboardBackspaceIcon className='back-btn-admin' />
      </IconButton>
      <PartnerForm />
    </>
  )
}
export default CreateOrganizationForm

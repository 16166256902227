import '../bms.scss'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { emptyString, toastOptions } from '../../Radiology/Constants/constants'
import { BedType, bedFilter, filterType } from '../constants'
import { allocateApi, allocation, tableHeadText } from '../modals'
import KeycloakService from '../../../utils/keycloakService'
import AddDischargeDialog from '../dialog/AddDischargeDialog'
import EditDischargeDate from '../dialog/EditDischargeDate'
import Dialog from '../../../components/Dialog'
import RemarksText from '../dialog/RemarksText'
import EditRemarksText from '../dialog/EditRemarksText'
import { ipVisitApi } from '../models'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { styled } from '@mui/system'

const CustomDataGridContainer = styled('div')({
  height: '84vh',
  width: '100%',
  marginTop: '1rem',
  overflow: 'auto',
  '& .MuiDataGrid-footerContainer': { justifyContent: 'start' },
})
interface StatusBoxProps {
  status?: string
}
const StatusBox = styled('div')<StatusBoxProps>(({ status }) => ({
  backgroundColor: status === 'AVAILABLE' ? 'green' : '#f47421',
  color: 'white',
  padding: '5px',
  borderRadius: '3px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: status === 'AVAILABLE' ? '#5cb85c' : '#f38c4c',
  },
}))
const CustomDataGrid = styled(DataGrid)({
  '& .MuiDataGrid-cell': {
    padding: '1px',
    fontSize: '12px',
  },
  '& .MuiDataGrid-columnHeader': {
    height: '10px',
    lineHeight: '10px',
    fontSize: '12px',
    color: 'white',
    backgroundColor: '#4682b4',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    padding: '0',
  },
  '& .MuiDataGrid-sortIcon': {
    opacity: 1,
  },
  '& .MuiDataGrid-iconButtonContainer': {
    visibility: 'visible',
  },
})

// const style = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: '60%',
//   height: 400,
//   bgcolor: 'background.paper',
//   boxShadow: 24,
//   p: 4,
//   outline: 'none',
// }

const BedManagement = ({ locationName }: any) => {
  const navigate = useNavigate()
  const [isAllocateOpen, setIsAllocateOpen] = useState<boolean>(false)
  const [refresh, setRefresh] = useState<boolean>(false)
  const [patientAllocate, setPatientAllocate] = useState<boolean>(false)
  // const [wardDetails, setWardDetails] = useState<wards[]>([])
  const [activeIndex, setActiveIndex] = useState(-1)
  // const [isEditDoctor, setIsEditDoctor] = useState(false)
  const [isEditRemarks, setIsEditRemarks] = useState(false)
  const [assignedDoctor, setAssignedDoctor] = useState('')
  const [expandedId, setExpandedId] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')
  const [wardId, setWardId] = useState<bedFilter>({
    id: '',
    patientId: '',
    patientName: '',
    bedNumber: '',
    bedCategory: 0,
    roomDetail: '',
    status: '',
    admission: '',
    dischargedDate: '',
    ward: 0,
    wardName: '',
    floorNumber: '',
    locationId: '',
    patientAssignmentMap: [],
    patientRegisteredId: '',
    assignedDoctor: '',
  })
  const [bedTable, setBedTable] = useState<bedFilter[]>([])
  const [filter, setFilter] = useState<filterType>({
    patientUhid: '',
    status: '',
    ward: '',
    category: '',
  })

  useEffect(() => {
    setTimeout(
      () =>
        setFilter((prevState) => ({
          ...prevState,
          patientUhid: '',
          status: '',
          ward: '',
          category: '',
        })),
      5000
    )
  }, [locationName])
  const [patientId, setPatientId] = useState('')
  const dateObject = (dateString: string) => {
    const dateObject = new Date(dateString)
    const formattedDate = dateObject.toISOString().split('T')[0]
    return formattedDate
  }

  const filtered = useMemo(() => {
    if (
      filter?.status === emptyString &&
      filter?.ward === emptyString &&
      filter?.patientUhid === emptyString &&
      filter?.category === emptyString
    ) {
      return bedTable
    }
    return bedTable?.filter((item: bedFilter) => {
      return (
        (filter.status === emptyString || item.status === filter.status) &&
        (filter.ward === emptyString || item.wardName === filter.ward) &&
        (filter.patientUhid === emptyString ||
          item.patientId
            ?.toLowerCase()
            ?.startsWith(filter?.patientUhid?.toLowerCase())) &&
        (filter?.category === emptyString ||
          item?.bedCategory === filter?.category)
      )
    })
  }, [
    bedTable,
    filter?.status,
    filter?.ward,
    filter?.patientUhid,
    filter?.category,
  ])
  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getWardsByLocation, {
      locationId: locationName,
    })?.then(() => {
      // const wards = data?.data?.ward_details?.map((e: WardDetail) => {
      //   const id = e?.id
      //   const name = e?.ward_name
      //   return {
      //     id,
      //     name,
      //   }
      // })
      // setWardDetails(wards)
    })
  }, [locationName])

  const tableRefresh = () => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getAllBedsByLocation, {
      locationId: locationName,
    })?.then((data) => {
      const bedData = data?.data.bed.map((e: BedType) => {
        const id = e.id
        const patientId = e.patient_id ?? ''
        const patientName = e.patient_name ?? '-'
        const bedNumber = e?.bed_number
        const locationId = e?.location_id
        const bedCategory = e.bed_category?.name
        const roomDetail = `${e?.room_detail?.room_name} ${
          e?.room_detail?.room_number ?? ''
        }`
        const status = e?.status
        const patientAssignmentMap = e?.bed_patient_assignment_maps
        const assignedDoctor = e?.bed_patient_assignment_maps
        const admission = e?.admission_date
        const dischargedDate = e.discharged_date ?? ''
        const ward = e?.room_detail?.ward_id
        const wardName = e?.room_detail?.ward_detail?.ward_name
        const floorNumber = e?.room_detail?.floor_number
        return {
          id: id,
          patientId: patientId,
          patientName: patientName,
          bedNumber: bedNumber,
          bedCategory: bedCategory,
          roomDetail: roomDetail,
          status: status,
          admission: admission,
          dischargedDate,
          patientAssignmentMap,
          ward,
          wardName,
          floorNumber,
          locationId,
          assignedDoctor,
        }
      })
      setBedTable(bedData)
    })
  }
  useEffect(() => {
    tableRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationName, refresh])
  useEffect(() => {
    if (patientId !== emptyString) {
      const state = {
        id: parseInt(wardId.id),
        status: allocation.occupied,
        patientId: patientId,
        createdBy: KeycloakService?.getUsername(),
        admissionDate: selectedDate ? selectedDate : new Date().toISOString(),
        dateCreated: new Date().toISOString(),
        patientName: wardId.patientName ?? emptyString,
        assignedDoctor: assignedDoctor,
        patientRegisteredId: wardId.patientRegisteredId,
      }
      startSxpProxy(IPD_PROJECT_ID, allocateApi?.createAndUpdateBed, state)
        ?.then(() => {
          startSxpProxy(IPD_PROJECT_ID, ipVisitApi?.masterEncounter, {
            patientId: wardId?.patientRegisteredId,
          })
            .then(() => {
              setWardId((prevBed: any) => ({
                ...prevBed,
                patientName: '',
                patientId: '',
                gender: '',
                age: '',
              }))
              navigate('/bedIpd')
              setPatientId('')
              setSelectedDate('')
              setAssignedDoctor('')
              setRefresh(true)
            })
            .catch((err) => console.error(err))
        })
        .catch((err) => {
          console.error(err, 'err')
          toast.error(
            <ToastMessage
              message={err?.response?.data?.message ?? 'Unable to fetch api'}
            />,
            toastOptions
          )
          setPatientId('')
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    patientId,
    wardId.id,
    navigate,
    wardId.patientName,
    wardId.patientRegisteredId,
    assignedDoctor,
  ])

  const handleRemarksEdit = (i: number) => {
    // setIsEditDoctor(false)
    setIsEditRemarks(true)
    setActiveIndex(i)
  }

  const handleAllocateClose = () => {
    setIsAllocateOpen(false)
  }
  const handleAllocatePatientClose = () => {
    setPatientAllocate(false)
  }
  const handleDischargeConfirm = (e: boolean) => {
    setIsAllocateOpen(e)
    setRefresh(!e)
  }

  // const handleAllocateConfirm = (
  //   e: boolean,
  //   bed: bedFilter,
  //   doctor?: any,
  //   date?: any
  // ) => {
  //   setIsAllocateOpen(e)
  //   setWardId(bed)
  //   setAssignedDoctor(doctor)
  //   setPatientId(bed?.patientId)
  //   setSelectedDate(date)
  // }

  const handleAllocate = (event: any, e: boolean, i: bedFilter) => {
    event.preventDefault()
    event.stopPropagation()
    setWardId(i)
    setIsAllocateOpen(e)
  }
  const handlePatientAllocate = (e: boolean) => {
    setPatientAllocate(e)
    setRefresh((prevState: boolean) => !prevState)
  }
  const toggleExpanded = (id: any) => {
    setExpanded(true)
    setExpandedId(expandedId === id ? null : id)
  }

  const handleRemarksChange = (d: bedFilter, remarks: string) => {
    setIsEditRemarks(false)
    setActiveIndex(-1)
    const state = {
      id: parseInt(d?.assignedDoctor?.[0]?.id),
      bedId: d?.id,
      patientId: d?.patientId,
      remarks: remarks,
      assignedDoctor: d?.assignedDoctor?.[0]?.assigned_doctor ?? '',
    }
    startSxpProxy(
      IPD_PROJECT_ID,
      allocateApi?.editBedPatientAssignment,
      state
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ).then((_) => {
      tableRefresh()
    })
  }
  const getDoctorName = (e: any) => {
    if (e?.length === 0) {
      return ''
    } else {
      return e?.[0]?.assigned_doctor ?? 'Add Doctor'
    }
  }

  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container?.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const [expanded, setExpanded] = useState(false)
  const columns = [
    {
      field: 'bedNo',
      headerName: tableHeadText.bedNo,
      width: 130,
      renderCell: (params: any) => {
        const filteredRow = filtered?.find((row) => row?.id === params?.row?.id)
        if (!filteredRow) {
          return null
        }
        return (
          <StatusBox
            status={filteredRow?.status}
            onClick={(e) => handleAllocate(e, true, filteredRow)}
          >
            <span className='hover-pointer'>
              {filteredRow?.bedNumber ?? ''}
            </span>
          </StatusBox>
        )
      },
    },

    {
      field: 'ward',
      headerName: tableHeadText.ward,
      width: 200,
      renderCell: (params: any) => {
        const filteredRow = filtered?.find((row) => row?.id === params?.row?.id)
        return (
          <StatusBox status={filteredRow?.status}>
            {filteredRow ? filteredRow?.roomDetail : 'N/A'}
          </StatusBox>
        )
      },
    },
    {
      field: 'bedType',
      headerName: tableHeadText.bedType,
      width: 150,
      renderCell: (params: any) => {
        const filteredRow = filtered?.find((row) => row?.id === params?.row?.id)

        return (
          <StatusBox status={filteredRow?.status}>
            {filteredRow ? filteredRow?.bedCategory : 'N/A'}
          </StatusBox>
        )
      },
    },
    {
      field: 'status',
      headerName: tableHeadText.status,
      width: 150,
      renderCell: (params: any) => {
        const filteredRow = filtered?.find((row) => row?.id === params?.row.id)

        return (
          <StatusBox status={filteredRow?.status}>
            {filteredRow?.status === 'AVAILABLE' ? 'Available' : 'Occupied'}
          </StatusBox>
        )
      },
    },
    {
      field: 'uhid',
      headerName: tableHeadText?.uhid,
      width: 150,
    },
    {
      field: 'name',
      headerName: tableHeadText?.name,
      width: 250,
    },
    {
      field: 'doctorName',
      headerName: tableHeadText?.doctorName,
      width: 220,
    },
    {
      field: 'admission',
      headerName: tableHeadText?.admission,
      width: 120,
    },
    {
      field: 'discharge',
      headerName: tableHeadText?.discharge,
      width: 160,
    },
    {
      field: 'additionalInfo',
      headerName: tableHeadText?.additionalInfo,
      width: 220,
      renderCell: (params: any) => {
        const filteredRow = filtered?.find((row) => row?.id === params?.row?.id)
        if (!filteredRow) return null
        const rowIndex = rows?.findIndex((row) => row?.id === params?.row?.id)
        const isOccupied = filteredRow?.status === allocation?.occupied
        return (
          <div className={`${isOccupied ? 'hover-occupied' : 'invalid-hover'}`}>
            {isOccupied && (
              <>
                {isEditRemarks && rowIndex === activeIndex ? (
                  <>
                    <EditRemarksText
                      data={filteredRow}
                      id={rowIndex}
                      onChange={handleRemarksChange}
                    />
                  </>
                ) : (
                  <span onClick={() => handleRemarksEdit(rowIndex)}>
                    {filteredRow?.assignedDoctor?.length === 0 ? (
                      allocation?.addRemarks
                    ) : filteredRow?.assignedDoctor[0]?.remarks ? (
                      <RemarksText
                        text={filteredRow?.assignedDoctor[0]?.remarks}
                        expanded={expandedId === filteredRow?.id && expanded}
                      />
                    ) : (
                      allocation?.addRemarks
                    )}
                  </span>
                )}
                {isEditRemarks === false &&
                filteredRow?.assignedDoctor[0]?.remarks?.length > 10 ? (
                  <span
                    className='view-more'
                    onClick={() => toggleExpanded(filteredRow?.id)}
                  >
                    {expandedId === filteredRow?.id
                      ? allocation?.viewLess
                      : allocation?.viewMore}
                  </span>
                ) : null}
              </>
            )}
          </div>
        )
      },
    },
  ]
  const rows = filtered?.map((d) => ({
    id: d?.id,
    bedNo: d?.bedNumber,
    ward: d?.roomDetail,
    bedType: d?.bedCategory,
    status: d?.status === 'AVAILABLE' ? 'Available' : 'Occupied',
    uhid: d?.patientId || allocation.hyphen,
    name: d?.patientName || allocation.hyphen,
    doctorName: getDoctorName(d?.assignedDoctor),
    admission: d?.admission ? dateObject(d?.admission) : allocation?.hyphen,
    discharge: allocation?.hyphen,
    additionalInfo: allocation?.addRemarks,
  }))

  return (
    <>
      {patientAllocate && (
        <Dialog
          open={patientAllocate}
          onClose={handleAllocatePatientClose}
          style={{ width: '50%', padding: '1%' }}
        >
          <EditDischargeDate
            onClose={() => setPatientAllocate(false)}
            onConfirm={handlePatientAllocate}
            wardId={wardId}
          />
        </Dialog>
      )}

      {/* {isAllocateOpen && wardId.status === allocation.available && (
        <Modal open={isAllocateOpen} onClose={handleAllocateClose}>
          <Box sx={style}>
            <AddAlllocationDialog
              onClose={() => setIsAllocateOpen(false)}
              onConfirm={handleAllocateConfirm}
              wardId={wardId}
            />
          </Box>
        </Modal>
      )} */}

      {isAllocateOpen && wardId.status === allocation.occupied && (
        <Dialog
          open={isAllocateOpen}
          onClose={handleAllocateClose}
          style={{ width: '67%' }}
        >
          <AddDischargeDialog
            onConfirm={handleDischargeConfirm}
            wardId={wardId}
            setRefresh={setRefresh}
            onClose={() => setIsAllocateOpen(false)}
            refresh={refresh}
            tableRefresh={tableRefresh}
            mode='list'
          />
        </Dialog>
      )}

      <CustomDataGridContainer>
        <CustomDataGrid
          rows={rows}
          columns={columns}
          columnHeaderHeight={30}
          rowHeight={30}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 50 },
            },
            sorting: {
              sortModel: [{ field: 'status', sort: 'desc' }],
            },
          }}
          pageSizeOptions={[5, 10, 20, 30, 40, 50]}
          // disableColumnFilter
          disableColumnSelector
          disableDensitySelector
          slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: {
                placeholder: 'Search By Patient UHID, Name, Bed, Status.',
                style: { width: '29%', textTransform: 'capitalize' },
              },
            },
          }}
        />
      </CustomDataGridContainer>
    </>
  )
}
export default BedManagement

/* eslint-disable camelcase */
import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { useSingleRadiologyOrder } from '../../UseSingleRadiologyOrder'
import { getAgeInYears, readableDateFormat } from '../../../../utils/dateUtils'
import PrintLabel from '../../../lms/components/dashboard/PrintLabel'
import { CLabel } from '../../../lms/models'
import { useReactToPrint } from 'react-to-print'
import LabelLogo from '../../../../assets/images/sampleLabelPrint.png'
import { encodeNewLineText, makeName } from '../../../lms/utils'
import { startSxpProxy } from '../../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  RADIOLOGY_PROJECT_ID,
} from '../../../../utils/constants'
import {
  dialogMessages,
  emptyString,
  enterResults,
  error,
  handleRadiologyUpdate,
  loadingResults,
  noData,
  noResultsfound,
  radiologyConditions,
  radiologyIntents,
  radiologyTestStatus,
  success,
  tabs,
  toastOptions,
} from '../../Constants/constants'
import {
  RadiologyResult,
  Reason,
  radiologists,
  radiologyDepartments,
  radiologyValues,
} from '../../models'
import RadiologyTestGroup from './RadiologyTestGroup'
import RadiologyLoadingButton from '../../RadiologyLoadingButton'
import KeycloakService from '../../../../utils/keycloakService'
import {
  RADIOLOGY_TESTING_WRITE,
  RADIOLOGY_VALIDATION_WRITE,
} from '../../../../utils/roles'
import { toast } from 'react-toastify'
import ToastMessage from '../../../lms/components/ToastMessage'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectedRadiologyActiveTab,
  setRadiologyActiveTab,
} from '../../RadiologySlice'
import {
  TestEnteredFilter,
  TestSubmittedFilter,
  filteredTabResults,
} from '../../Utils/utils'
import { useRadiologyOrganizations } from '../../useRadiologyOrganizations'
import { radiologyEndPoint } from '../../RadiologyEndPoints'
import LocationByPrefix from '../../../patients/dashboard/LocationByPrefix'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'
const styles = () => ({
  dataTable: {
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${radiologyColor?.softGrey}`,
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: '6px',
      fontSize: '12px',
      border: `1px solid ${radiologyColor?.softGrey}`,
    },
    '& th': {
      fontWeight: 500,
      backgroundColor: radiologyColor?.steelBlue,
      color: radiologyColor?.white,
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: radiologyColor?.whisperGray,
    },
  },
  serialNoContent: {
    width: '5%',
  },
  lrNumberContent: {
    width: '10%',
  },
  radiologyTestContainer: {
    marginTop: '12px',
    minWidth: '300px',
  },
  radiologyTestGroupContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5px',
  },
  radiologyAdminHeader: {
    fontSize: '14px',
    fontWeight: 500,
    margin: '2px 0 8px',
  },
  radiologySubmittedContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    alignItems: 'center',
  },
  radiologyApprove: {
    display: 'flex',
    gap: '10px',
  },
  saveCtn: {
    display: 'flex',
    gap: '10px',
  },
  submitCtn: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  tableContainer: {
    height: '65vh',
  },
  tableHead: {
    backgroundColor: radiologyColor?.steelBlue,
    position: 'sticky',
    top: 0,
    zIndex: 2,
  },
  tableHeadCell: {
    color: radiologyColor?.pureWhite,
    borderRight: `1px solid ${radiologyColor?.pureWhite}`,
    padding: '7px',
  },
  testNameCell: {
    width: '40%',
  },
  referredOutCell: {
    width: '35%',
  },
  actionsCell: {
    width: '25%',
  },
  tableBody: {
    backgroundColor: radiologyColor?.gray,
    borderRight: `1px solid ${radiologyColor?.pureWhite}`,
  },
})
const EnterRadiologyOrder = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [apiLoading, setApiLoading] = useState(false)
  const navigate = useNavigate()
  const { id } = useParams()
  const [reload, setReload] = useState(1)
  const [rowEditing, setRowEditing] = useState(0)
  const { order, patient } = useSingleRadiologyOrder(id ?? '', reload)
  const [results, setResults] = useState<RadiologyResult[]>([])
  const [updated, setUpdated] = useState<Set<number>>(() => new Set())
  const [radiologyDepartments, setRadiologyDepartments] = useState<
    radiologyDepartments[]
  >([])
  const [active, setActive] = useState(0)
  const labelRef = useRef(null)
  const [allCheck, setAllCheck] = useState(false)
  const [checkedTestIds, setCheckedTestIds] = useState<number[]>([])
  const organizations = useRadiologyOrganizations()
  const [checkedApproveIds, setCheckedApproveIds] = useState<number[]>([])
  const [allChecked, setAllChecked] = useState(false)
  const [testingCount, setTestingCount] = useState(0)
  const [checkedTests, setCheckedTests] = useState<RadiologyResult[]>([])
  const [check, setCheck] = useState('')
  const [allApproveCheck, setAllApproveCheck] = useState(false)
  const [allApproveChecked, setAllApproveChecked] = useState(false)
  const [approveCheck, setApproveCheck] = useState('')
  const [validateCount, setValidateCount] = useState(0)
  const [allRadiologists, setAllRadiologists] = useState<radiologists[]>([])
  const [checkedApproveTests, setCheckedApproveTests] = useState<
    RadiologyResult[]
  >([])
  const enteredTestLength = TestEnteredFilter(order)
  const activeTab = useAppSelector(selectedRadiologyActiveTab)
  const [allReasons, setAllReasons] = useState<Reason[]>([])
  const filteredResults = filteredTabResults(results, activeTab)
  const testValidateLength = TestSubmittedFilter(order)
  const dispatch = useAppDispatch()
  const getRadiologyResultsbyId = async () => {
    const state = {
      radiologyOrderId: id,
    }
    await startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getRadiologyResultsByOrderId,
      state
    )
      .then((data) => {
        const radiologyResults: RadiologyResult[] =
          data?.data?.radiology_test_result ?? []
        if (radiologyResults?.length > 0) {
          setResults(radiologyResults)
        } else {
          setResults([])
        }
      })
      .catch((err) => {
        console.error(err, 'err')
      })
  }
  const labelOptions = (): CLabel => ({
    name: makeName(patient?.name),
    gender: patient?.gender,
    age: patient?.birthDate ? getAgeInYears(patient?.birthDate) : '-',
    patientId: patient?.identifier?.[2]?.value,
    code: order?.lr_number ?? patient?.identifier?.[2]?.value,
  })
  const labelPageStyle = `
  @page {
    size: 50mm 25mm;
  }
  @media print {
    @page {  
      size: 50mm 25mm landscape;
      margin: 0mm !important;
    }
  }
`

  const handleLabelPrint = useReactToPrint({
    content: () => labelRef.current,
    pageStyle: labelPageStyle,
  })
  const updateSet = (i: number) => {
    setUpdated((pr) => new Set(pr).add(i))
  }
  const handleUpdate = (
    type: string,
    index: number,
    value?: any,
    isEdit?: boolean,
    testId?: number
  ) => {
    switch (type) {
      case handleRadiologyUpdate?.cancelEdit: {
        if (isEdit) {
          setReload((r) => r + 1)
          setUpdated((pr) => {
            const newSet = new Set(pr)
            if (testId) newSet.delete(testId)
            return newSet
          })
        }
        break
      }

      case handleRadiologyUpdate?.docUrlChange: {
        setResults((r) =>
          r?.map((rs) => {
            if (
              index === rs?.test_id &&
              rs?.test_status !== radiologyTestStatus?.rejected &&
              value
            ) {
              rs.document_url = value
              updateSet(rs.id)
            }
            return rs
          })
        )
        break
      }

      case handleRadiologyUpdate?.referredOutChange: {
        setResults((r) =>
          r?.map((rs) => {
            if (
              index === rs?.test_id &&
              rs?.test_status !== radiologyTestStatus?.rejected
            ) {
              rs.referred_out = !value
              updateSet(rs.test_id)
            }
            return rs
          })
        )
        break
      }

      case handleRadiologyUpdate?.referredToChange: {
        setResults((r) =>
          r?.map((rs) => {
            if (
              index === rs?.test_id &&
              rs?.test_status !== radiologyTestStatus?.rejected
            ) {
              rs.referred_to = value
              updateSet(rs?.test_id)
            }
            return rs
          })
        )
        break
      }

      case handleRadiologyUpdate?.valuesChange: {
        setResults((r) =>
          r?.map((rs) => {
            if (rs?.test_id === index) {
              rs.values = value
            }
            return rs
          })
        )
        updateSet(index)
        break
      }

      case handleRadiologyUpdate?.enter: {
        const found = results.findIndex((i) => i?.test_id === testId)
        if (found !== -1) {
          setActive(found + 1)
        } else {
          setActive((id) => id + 1)
        }
        break
      }

      case handleRadiologyUpdate?.valueChange: {
        setResults((r) =>
          r?.map((rs) => {
            if (rs?.test_id === index) {
              rs.value = value
            }
            return rs
          })
        )
        updateSet(index)
        break
      }

      default:
        console.warn(dialogMessages?.unknownUpdateType, type)
    }
  }

  const handleCancelEdit = (isEdit: boolean, testId: number) => {
    handleUpdate(
      handleRadiologyUpdate?.cancelEdit,
      0,
      undefined,
      isEdit,
      testId
    )
  }
  const handleDocUrlChange = (index: number, val?: string) => {
    handleUpdate(handleRadiologyUpdate?.docUrlChange, index, val)
  }
  const handleReferredOutChange = (val: boolean, index: number) => {
    handleUpdate(handleRadiologyUpdate?.referredOutChange, index, val)
  }

  const handleReferredToChange = (val: number, index: number) => {
    handleUpdate(handleRadiologyUpdate?.referredToChange, index, val)
  }
  const handleValuesChange = (values: radiologyValues, index: number) => {
    handleUpdate(handleRadiologyUpdate?.valuesChange, index, values)
  }

  const handleEnter = (testId: number) => {
    handleUpdate(handleRadiologyUpdate?.enter, 0, undefined, undefined, testId)
  }
  const handleValueChange = (val: string, index: number) => {
    handleUpdate(handleRadiologyUpdate?.valueChange, index, val)
  }
  const getRadiologists = async () => {
    await startSxpProxy(ADMIN_PROJECT_ID, radiologyIntents?.getRadiologists, {})
      .then((data: any) => {
        const departments = data?.data?.map((e: any) => {
          const id = e?.id
          const name = e?.firstName.concat(' ', e?.lastName)
          const siganture = e?.signature
          const codingArray =
            e?.specialities?.find((speciality: any) => speciality?.coding)
              ?.coding || []
          const specialty = codingArray?.map((coding: any) => coding?.display)
          return {
            id,
            name,
            siganture,
            specialty,
          }
        })
        setRadiologyDepartments(departments)
      })
      .catch((err) => {
        console.error(err, 'err')
      })
  }
  const handleAllCheckedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e?.target?.checked
    setAllChecked(isChecked)
    setAllCheck(isChecked)
    setTestingCount((prev) => prev + 1)
  }
  const handleCheckboxChange = (
    testId: number,
    isChecked: boolean,
    test: RadiologyResult
  ) => {
    setCheckedTestIds((prevCheckedTestIds: number[]) => {
      const updatedCheckedTestIds = isChecked
        ? [...prevCheckedTestIds, testId]
        : prevCheckedTestIds?.filter((id: number) => id !== testId)
      return updatedCheckedTestIds
    })
    setCheckedTests((prev: RadiologyResult[]) => {
      const updatedCheckedTestIds = isChecked
        ? [...prev, test]
        : prev?.filter((test: RadiologyResult) => test?.test_id !== testId)
      return updatedCheckedTestIds.flat()
    })
    const checking = isChecked
      ? radiologyConditions?.checked
      : radiologyConditions?.unChecked
    setCheck(checking)
  }
  const handleAllApproveChecked = (e: any) => {
    const isChecked = e.target.checked
    setAllApproveCheck(isChecked)
    setAllApproveChecked(isChecked)
    setValidateCount((prev) => prev + 1)
  }
  const handleCheckboxApproveChange = (
    testId: number,
    isChecked: boolean,
    tests: RadiologyResult
  ) => {
    setCheckedApproveIds((prevCheckedTestIds: number[]) => {
      const updatedCheckedTestIds = isChecked
        ? [...prevCheckedTestIds, testId]
        : prevCheckedTestIds?.filter((id: number) => id !== testId)
      return updatedCheckedTestIds
    })
    setCheckedApproveTests((prev) => {
      const updatedCheckedTestIds = isChecked
        ? [...prev, tests]
        : prev?.filter((test: RadiologyResult) => test?.test_id !== testId)
      return updatedCheckedTestIds.flat()
    })
    const checking = isChecked
      ? radiologyConditions?.approved
      : radiologyConditions?.unApproved
    setApproveCheck(checking)
  }
  const handleSave = async () => {
    if (!KeycloakService.hasRole([RADIOLOGY_TESTING_WRITE])) {
      return
    }
    for (const result of results) {
      if (result?.referred_out && !result?.referred_to) {
        toast(<ToastMessage message={dialogMessages?.referMandatory} />, {
          ...toastOptions,
          type: error,
        })
        return
      }
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const updatedTests = results?.filter((rs) => updated.has(rs?.test_id))
    const mapped = updatedTests?.map((rs) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { radiology_order, organization, ...rest } = rs
      const resultUpdated = updated.has(rs?.test_id)
      const updatedTestStatus =
        activeTab === tabs?.validation
          ? rs?.test_status
          : resultUpdated
          ? radiologyTestStatus?.entered
          : rs?.test_status
      const updatedResult: Partial<RadiologyResult> = {
        ...rest,
        value: encodeNewLineText(rs?.value),
        values: rs?.values,
        observation: encodeNewLineText(rs?.observation),
        document_url: rs?.document_url || undefined,
        referred_out: rs?.referred_out,
        referred_to: rs?.referred_out ? rs?.referred_to : null,
        test_status: updatedTestStatus,
        results_entered_on: resultUpdated ? now : rs?.results_entered_on,
        results_entered_by: resultUpdated
          ? KeycloakService.getFullname()
          : rs?.results_entered_by,
        results_entered_by_id: resultUpdated
          ? KeycloakService.getSub()
          : rs?.results_entered_by_id,
      }
      return { where: { id: { _eq: rs?.id } }, _set: updatedResult }
    })

    try {
      const { updateRadiologyResults: intent } = radiologyIntents || {}
      const state = { results: mapped }

      await startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)

      setApiLoading(false)
      setReload((r) => r + 1)
      setUpdated(new Set())
      toast(<ToastMessage message={dialogMessages?.radiologyResultsSaved} />, {
        ...toastOptions,
        type: success,
      })
    } catch (err) {
      console.error('Error updating radiology results:', err)
    }
  }
  const handleSubmit = async (testResults: RadiologyResult[]) => {
    if (!KeycloakService.hasRole([RADIOLOGY_TESTING_WRITE])) {
      return
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const intent = radiologyIntents?.updateShortRadiologyOrderId
    const updated = {
      status: radiologyTestStatus?.submitted,
    }
    const state = {
      order: updated,
      radiologyOrderId: id,
    }
    await startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
      .then(() => {
        const mapped = testResults?.map((rs) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { radiology_order, organization, ...rest } = rs
          const updatedResult: Partial<RadiologyResult> = {
            ...rest,
            test_status: radiologyTestStatus?.submitted,
            results_entered_on: now,
            results_entered_by: KeycloakService.getFullname(),
            results_entered_by_id: KeycloakService.getSub(),
          }
          return { where: { id: { _eq: rs?.id } }, _set: updatedResult }
        })
        startSxpProxy(
          RADIOLOGY_PROJECT_ID,
          radiologyIntents?.updateRadiologyResults,
          {
            results: mapped,
          }
        )
          .then(() => {
            setCheckedTestIds([])
            setCheckedTests([])
            setAllChecked(false)
            setApiLoading(false)
            setReload((r) => r + 1)
            setRowEditing((r) => r + 1)
            toast(
              <ToastMessage
                message={dialogMessages?.radiologyResultsSubmitted}
              />,
              {
                position: 'bottom-center',
                hideProgressBar: true,
                closeButton: false,
                theme: 'dark',
                autoClose: 2000,
              }
            )
            dispatch(setRadiologyActiveTab(tabs?.validation))
          })
          .catch((err) => {
            throw err
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const handleApprovecheck = (testResults: RadiologyResult[]) => {
    if (!KeycloakService.hasRole([RADIOLOGY_VALIDATION_WRITE])) {
      return
    }
    const filter = testResults?.filter((e) => e?.referred_out !== true)
    const filterReferred = testResults?.filter((e) => e?.referred_out)
    const formattedPathologists = allRadiologists
      ?.filter((e: radiologists) => e?.radiologist !== emptyString)
      ?.map((e: radiologists) => e?.testId)
    const uniqueRadiologistsArray = formattedPathologists?.filter(
      (value: any, index: number, self: any) => {
        return self?.indexOf(value) === index
      }
    )
    if (
      uniqueRadiologistsArray?.length === 0 &&
      filterReferred?.length !== testResults?.length
    ) {
      toast.warning(dialogMessages?.selectRadiologists)
      return
    }
    const filteredTest = filter?.every((e: any) =>
      uniqueRadiologistsArray?.includes(e?.test_id)
    )
    if (
      filteredTest === false &&
      filterReferred?.length !== testResults?.length
    ) {
      toast.warning(dialogMessages?.selectRadiologistForNotReferred)
      return
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const mapped = testResults?.map((rs) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { radiology_order, organization, ...rest } = rs
      const getRadiologistObject = allRadiologists?.filter(
        (e: any) => e?.testId === rs?.test_id
      )
      const updatedResult: Partial<RadiologyResult> = {
        ...rest,
        test_status: radiologyTestStatus?.approved,
        validated_on: now,
        validated_by: KeycloakService.getFullname(),
        validated_by_id: KeycloakService.getSub(),
        authorised_by_id: getRadiologistObject?.[0]?.radiologist ?? '',
        authorised_by_name:
          radiologyDepartments?.find(
            (e) => e?.id === getRadiologistObject?.[0]?.radiologist
          )?.name ?? emptyString,
      }
      return { where: { id: { _eq: rs?.id } }, _set: updatedResult }
    })
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.updateRadiologyResults,
      {
        results: mapped,
      }
    )
      .then((data) => {
        const items =
          data?.data?.data?.insert_radiology_test_status_audit?.returning?.[0]
            ?.radiology_order?.radiology_order_tests
        const nonDeletedItems = items?.filter(
          (e: any) => e?.test_status !== radiologyTestStatus?.deleted
        )
        const checkCompleted = nonDeletedItems?.every(
          (e: any) => e?.test_status === radiologyTestStatus?.approved
        )
        const updated = {
          status: checkCompleted
            ? radiologyTestStatus?.completed
            : radiologyTestStatus?.approved,
          validation_completed_on: new Date().toISOString(),
          validated_by_id: KeycloakService.getSub(),
          validated_by_name: KeycloakService.getFullname(),
        }
        const state = {
          order: updated,
          radiologyOrderId: id,
        }
        startSxpProxy(
          RADIOLOGY_PROJECT_ID,
          radiologyIntents?.updateShortRadiologyOrderId,
          state
        )
          .then(() => {
            setApiLoading(false)
            toast(
              <ToastMessage
                message={dialogMessages?.radiologyOrderValidated}
              />,
              {
                position: 'bottom-center',
                hideProgressBar: true,
                closeButton: false,
                theme: 'dark',
                autoClose: 2000,
              }
            )
            setCheckedApproveTests([])
            setAllRadiologists([])
            setAllApproveCheck(false)
            dispatch(setRadiologyActiveTab('dispatch'))
            setTimeout(() => {
              navigate(radiologyEndPoint?.radiologyDashboard)
            }, 1000)
          })
          .catch((err) => console.log(err, 'err'))
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const handleRejectCheck = (testResults: RadiologyResult[]) => {
    const formattedReasons = allReasons?.map((e: any) => e?.testId)
    const uniqueReasonsArray = formattedReasons?.filter(
      (value: any, index: number, self: any) => {
        return self?.indexOf(value) === index
      }
    )
    if (uniqueReasonsArray?.length === 0) {
      toast.warning(dialogMessages?.reasonToSelect)
      return
    }
    const filteredTest = testResults?.every((e: any) =>
      uniqueReasonsArray.includes(e?.test_id)
    )
    if (!filteredTest) {
      toast.warning(dialogMessages?.reasonForNotReferred)
      return
    }
    setApiLoading(true)
    const now = new Date().toISOString()
    const valId = KeycloakService.getSub()
    const valName = KeycloakService.getFullname()
    const rejectResults = testResults?.map((rs) => {
      const updatedResult: Partial<RadiologyResult> = {
        test_status: radiologyTestStatus?.rejected,
        rejection_reason: allReasons?.find(
          (e: any) => e?.testId === rs?.test_id
        )?.reason,
        rejected_on: now,
        rejected_by_id: valId,
        rejected_by: valName,
      }
      return { where: { id: { _eq: rs?.id } }, _set: updatedResult }
    })
    const intent = radiologyIntents?.updateRadiologyResults
    const state = {
      results: rejectResults,
    }
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
      .then(() => {
        const updated = {
          status: radiologyTestStatus?.rejected,
        }
        const newState = {
          order: updated,
          radiologyOrderId: id,
        }
        startSxpProxy(
          RADIOLOGY_PROJECT_ID,
          radiologyIntents?.updateShortRadiologyOrderId,
          newState
        )
          .then(() => {
            setApiLoading(false)
            setAllReasons([])
            setCheckedApproveTests([])
            setReload((r) => r + 1)
            toast(
              <ToastMessage message={dialogMessages?.radiologyRejected} />,
              {
                position: 'bottom-center',
                hideProgressBar: true,
                closeButton: false,
                theme: 'dark',
                autoClose: 2000,
              }
            )
            setTimeout(() => {
              dispatch(setRadiologyActiveTab(tabs?.testing))
              navigate(radiologyEndPoint?.radiologyDashboard)
            }, 1000)
          })
          .catch((err) => {
            console.error(err)
          })
      })
      .catch((err) => {
        console.error(err)
      })
  }
  const handleRadiologists = (radiologist: string, id: number) => {
    const updatedList = allRadiologists?.filter((e: any) => e?.testId !== id)
    setAllRadiologists([...updatedList, { testId: id, radiologist }])
  }
  const handleReasons = (reason: string, id: number) => {
    if (!reason) {
      setAllReasons((prev: Reason[]) =>
        prev?.filter((e: Reason) => e?.testId !== id)
      )
      return
    }
    const reasons = allReasons?.find((e: Reason) => e?.testId === id)
    if (reasons) {
      const dd = allReasons?.filter((e: Reason) => e?.testId !== id)
      setAllReasons([...dd, { testId: id, reason }])
    } else {
      setAllReasons((prev: Reason[]) => [...prev, { testId: id, reason }])
    }
  }
  const checkEntered = results?.some(
    (e) =>
      e?.test_status === radiologyTestStatus?.entered ||
      e?.test_status === radiologyTestStatus?.referredEntered
  )
  const checkSubmitted = results?.some(
    (e) => e?.test_status === radiologyTestStatus?.submitted
  )
  useEffect(() => {
    if (id) {
      getRadiologyResultsbyId()
      getRadiologists()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload])
  useEffect(() => {
    setAllCheck(false)
    setCheckedTestIds([])
    setAllApproveCheck(false)
    setAllApproveChecked(false)
    setAllChecked(false)
    setCheckedTests([])
    setCheckedApproveIds([])
    setCheckedApproveTests([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])
  useEffect(() => {
    if (
      (check === radiologyConditions?.unChecked ||
        check === radiologyConditions?.checked) &&
      enteredTestLength?.length !== 0
    ) {
      setAllCheck(enteredTestLength?.length === checkedTestIds?.length)
      setCheck('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [check])
  useEffect(() => {
    if (checkedTestIds?.length === 0) {
      setAllCheck(false)
      setAllChecked(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedTestIds])
  useEffect(() => {
    if (allCheck) {
      setAllChecked(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCheck])
  useEffect(() => {
    if (
      approveCheck === radiologyConditions?.unApproved ||
      approveCheck === radiologyConditions?.approved
    ) {
      setAllApproveChecked(
        testValidateLength?.length === checkedApproveIds?.length
      )
      setApproveCheck('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [approveCheck])
  useEffect(() => {
    if (checkedApproveIds?.length === 0) {
      setAllApproveChecked(false)
      setAllApproveCheck(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedApproveIds])
  useEffect(() => {
    if (allApproveChecked) {
      setAllApproveCheck(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allApproveChecked])

  return (
    <>
      <div>
        <table className={classes?.dataTable}>
          <thead>
            <tr>
              <th className={classes?.serialNoContent}>Sl No</th>
              <th className={classes?.lrNumberContent}>LR.Number</th>
              <th>UHID</th>
              <th>Name</th>
              <th>Mobile No.</th>
              <th>Operating Unit</th>
              <th className={classes?.serialNoContent}>Service</th>
              <th className={classes?.lrNumberContent}>Ordered On</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>{order?.lr_number}</td>
              <td>{patient?.identifier?.[2]?.value}</td>
              <td>{makeName(patient?.name)}</td>
              <td>{patient?.telecom?.[0]?.value}</td>
              <td>
                <LocationByPrefix
                  id={
                    patient?.identifier?.[2]?.value ??
                    patient?.identifier?.[0]?.value ??
                    noData
                  }
                />
              </td>
              <td>{order?.origin}</td>
              <td>
                {order?.ordered_on
                  ? readableDateFormat(
                      new Date(order?.ordered_on ?? emptyString)
                    )
                  : noData}
              </td>
              <td>
                {
                  <>
                    <div className='result-actions'>
                      <div className='display-none'>
                        <PrintLabel data={labelOptions()} ref={labelRef} />
                      </div>
                      <button title='Print Label' onClick={handleLabelPrint}>
                        <img src={LabelLogo} alt='Print Label' />
                      </button>
                    </div>
                  </>
                }
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        {order?.id ? (
          results?.length > 0 ? (
            <div>
              <div className={classes?.radiologyTestContainer}>
                <div className={classes?.radiologyTestGroupContainer}>
                  <p className={classes?.radiologyAdminHeader}>
                    {enterResults}
                  </p>
                  <div className={classes?.radiologySubmittedContainer}>
                    {order?.status !== radiologyTestStatus?.completed &&
                    updated.size !== 0 &&
                    KeycloakService.hasRole([RADIOLOGY_TESTING_WRITE]) ? (
                      <RadiologyLoadingButton
                        title='Save'
                        loading={apiLoading}
                        compact
                        onClick={handleSave}
                      />
                    ) : checkEntered && activeTab === tabs?.testing ? (
                      <div className={classes?.saveCtn}>
                        <div className={classes?.submitCtn}>
                          <input
                            type='checkbox'
                            checked={allCheck}
                            onChange={handleAllCheckedChange}
                          />
                        </div>
                        <RadiologyLoadingButton
                          title='Submit'
                          loading={apiLoading}
                          compact
                          disable={checkedTestIds?.length === 0}
                          onClick={() => handleSubmit(checkedTests)}
                        />
                      </div>
                    ) : checkSubmitted && activeTab == tabs?.validation ? (
                      <div>
                        <div className={classes?.radiologyApprove}>
                          <input
                            type='checkbox'
                            checked={allApproveChecked}
                            onChange={handleAllApproveChecked}
                          />
                          <RadiologyLoadingButton
                            title='Approve'
                            loading={apiLoading}
                            compact
                            disable={checkedApproveTests?.length === 0}
                            onClick={() =>
                              handleApprovecheck(checkedApproveTests)
                            }
                          />
                          <RadiologyLoadingButton
                            title='Reject'
                            loading={apiLoading}
                            compact
                            disable={checkedApproveTests?.length === 0}
                            onClick={() =>
                              handleRejectCheck(checkedApproveTests)
                            }
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div>
                  <TableContainer
                    component={Paper}
                    className={classes?.tableContainer}
                  >
                    <Table aria-label='collapsible table' size='small'>
                      <TableHead className={classes?.tableHead}>
                        <TableRow>
                          <TableCell
                            className={`${classes?.tableHeadCell} ${classes?.testNameCell}`}
                            align='left'
                          >
                            Test Name
                          </TableCell>
                          <TableCell
                            className={`${classes?.tableHeadCell} ${classes?.referredOutCell}`}
                          >
                            Referred Out
                          </TableCell>
                          <TableCell
                            className={`${classes?.tableHeadCell} ${classes?.actionsCell}`}
                            align='left'
                          >
                            Actions
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes?.tableBody}>
                        {filteredResults?.map(
                          (k: RadiologyResult, index: number) => (
                            <RadiologyTestGroup
                              key={index}
                              result={k}
                              results={results}
                              active={active}
                              mode={'notReferred'}
                              isReferred={false}
                              patient={patient}
                              rowEditing={rowEditing}
                              onDocUrlChange={handleDocUrlChange}
                              onEditCancel={handleCancelEdit}
                              disabled={
                                order?.status === radiologyTestStatus?.completed
                              }
                              onReferredOutChange={handleReferredOutChange}
                              onReferredToChange={handleReferredToChange}
                              organizations={organizations}
                              onValuesChange={handleValuesChange}
                              onEnter={handleEnter}
                              onValueChange={handleValueChange}
                              onCheckboxChange={handleCheckboxChange}
                              allChecked={allChecked}
                              testingCount={testingCount}
                              valuesUpdated={updated.size > 0}
                              validateCount={validateCount}
                              allApproveCheck={allApproveCheck}
                              onRadiologists={handleRadiologists}
                              onReasons={handleReasons}
                              onCheckboxApprove={handleCheckboxApproveChange}
                              apiLoading={apiLoading}
                              radiologyDepartments={radiologyDepartments}
                              reload={reload}
                            />
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          ) : (
            <p className='all-collected'>{noResultsfound}</p>
          )
        ) : (
          <span className='all-collected'>{loadingResults}</span>
        )}
      </div>
    </>
  )
}
export default EnterRadiologyOrder

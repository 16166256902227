import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { LmsObservation } from '../../lms/models'
import { v4 as uuidv4 } from 'uuid'
import { Button, useTheme } from '@mui/material'
import './observations.scss'
import {
  contentRequired,
  radiologyButtons,
  titleRequired,
} from '../Constants/constants'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../Utils/RadiologyColors'

type Props = {
  existing?: LmsObservation
  onSave: (data: LmsObservation) => void
  onUpdate: (data: LmsObservation) => void
}
const styles = () => ({
  radiologyObservations: {
    display: 'flex !important',
    gap: '3%',
    marginBottom: '10px !important',
    alignItems: 'center',
  },
  radiologyObservationContainer: {
    display: 'flex',
    width: '100%',
    gap: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textAreaContainer: {
    width: '32%',
  },
  titleArea: {
    height: 'auto',
    overflow: 'hidden',
    width: '95%',
    borderRadius: '5px',
    backgroundColor: radiologyColor?.paleGrey,
    padding: '5px',
  },
  radiologyObsArea: {
    resize: 'none',
  },
  radiologyInputError: {
    border: `2px solid ${radiologyColor?.red}`,
  },
  errorText: {
    color: radiologyColor?.red,
    fontSize: '12px',
    fontStyle: 'italic',
    margin: '0 0 4px',
  },
  contentTextArea: {
    width: '55%',
  },
  contentArea: {
    height: 'auto',
    overflow: 'hidden',
    width: '95%',
    borderRadius: '5px',
    padding: '5px',
  },
  radiologyObsButton: {
    height: '29px',
    width: '60px',
  },
})
const RadiologyObservation = ({ existing, onSave, onUpdate }: Props) => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [titleError, setTitleError] = useState(false)
  const [contentError, setContentError] = useState(false)
  const titleTextareaRef = useRef<HTMLTextAreaElement | null>(null)
  const contentTextareaRef = useRef<HTMLTextAreaElement | null>(null)
  const adjustHeight = (textarea: HTMLTextAreaElement | null) => {
    if (textarea) {
      textarea.style.height = 'auto'
      textarea.style.height = `${textarea.scrollHeight}px`
    }
  }
  const handleClick = () => {
    if (!title) {
      setTitleError(true)
      return
    }
    if (!content) {
      setContentError(true)
      return
    }
    if (existing?.id) {
      const obs: LmsObservation = {
        id: existing?.id,
        title: title,
        content: content,
      }
      onUpdate(obs)
    } else {
      const obs: LmsObservation = {
        id: uuidv4(),
        title: title,
        content: content,
      }
      onSave(obs)
    }
    setTitle('')
    setContent('')
    adjustHeight(titleTextareaRef.current)
    adjustHeight(contentTextareaRef.current)
  }

  const handleTitleChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    setTitle(target.value)
    setTitleError(false)
    setContentError(false)
  }

  const handleContentChange = ({
    target,
  }: ChangeEvent<HTMLTextAreaElement>) => {
    setContent(target.value)
    setTitleError(false)
    setContentError(false)
  }
  useEffect(() => {
    adjustHeight(titleTextareaRef.current)
    adjustHeight(contentTextareaRef.current)
  }, [title, content, existing])

  useEffect(() => {
    if (existing) {
      setTitle(existing?.title)
      setContent(existing?.content)
    } else {
      setTitle('')
      setContent('')
    }
  }, [existing])

  return (
    <div className={classes?.radiologyObservations}>
      <div className={classes?.radiologyObservationContainer}>
        <div className={classes?.textAreaContainer}>
          <textarea
            placeholder='Enter Title'
            ref={titleTextareaRef}
            value={title}
            cols={40}
            rows={2}
            onChange={handleTitleChange}
            className={`${classes?.titleArea} ${classes?.radiologyObsArea} ${
              titleError && classes?.radiologyInputError
            }`}
            maxLength={100}
          />
          {titleError ? (
            <p className={classes?.errorText}>{titleRequired}</p>
          ) : null}
        </div>
        <div className={classes?.contentTextArea}>
          <textarea
            placeholder='Enter Content'
            ref={contentTextareaRef}
            className={`${classes?.contentArea} ${classes?.radiologyObsArea} ${
              contentError && classes?.radiologyInputError
            }`}
            cols={40}
            rows={2}
            value={content}
            onChange={handleContentChange}
            maxLength={1000}
          ></textarea>
        </div>
        {contentError ? (
          <p className={classes?.errorText}>{contentRequired}</p>
        ) : null}
        <Button
          onClick={handleClick}
          size='small'
          variant='contained'
          className={classes?.radiologyObsButton}
        >
          {existing?.id ? radiologyButtons?.update : radiologyButtons?.add}
        </Button>
      </div>
    </div>
  )
}

export default RadiologyObservation

import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import { panelSummary } from '../../models'
import { isoDateFormat } from '../../../../utils/dateUtils'
import add from 'date-fns/add'
import '../../lms.scss'
import { lmsdashboard } from '../../../Radiology/Constants/constants'

const LmsView = () => {
  const [startDate, setStartDate] = useState(getDefaultStartDate())
  const [endDate, setEndDate] = useState(getDefaultEndDate())
  const [monthSelector, setMonthSelector] = useState(
    getDefaultMonthSelector(startDate)
  )
  const [locationData, setLocationData] = useState<any>([])
  function getDefaultStartDate() {
    const today = new Date()
    const defaultStartDate = new Date(
      today.getTime() - 30 * 24 * 60 * 60 * 1000
    )
    const year = defaultStartDate.getFullYear()
    const month = defaultStartDate.getMonth() + 1
    const day = defaultStartDate.getDate()
    return `${year}-${month.toString().padStart(2, '0')}-${day
      .toString()
      .padStart(2, '0')}`
  }
  function getDefaultEndDate() {
    const today = new Date()
    return today.toISOString().split('T')[0]
  }
  function getDefaultMonthSelector(startDate: any) {
    const date = startDate ? new Date(startDate) : new Date()
    const year = date.getFullYear()
    const month = date.getMonth() + 1
    return `${year}-${month.toString().padStart(2, '0')}`
  }

  const handleDateChange = (event: any) => {
    const { name, value } = event.target
    if (name === 'startDate') {
      setStartDate(value)
      if (new Date(value) > new Date(endDate)) {
        setEndDate(value)
      } else {
        const maxEndDate = new Date(value)
        maxEndDate.setDate(maxEndDate.getDate() + 30)
        if (new Date(endDate) > maxEndDate) {
          setEndDate(maxEndDate.toISOString().split('T')[0])
        }
      }
      const [year, month] = value.split('-')
      const newMonthSelector = `${year}-${month.padStart(2, '0')}`
      setMonthSelector(newMonthSelector)
    } else if (name === 'endDate') {
      setEndDate(value)
      if (new Date(value) < new Date(startDate)) {
        setStartDate(value)
      }
    }
  }

  const handleMonthChange = (event: any) => {
    const { value } = event.target
    const [year, month] = value.split('-')
    const startOfMonth = `${year}-${month}-01`
    const data = `${year}-${month.toString().padStart(2, '0')}`
    setMonthSelector(data)
    const endOfMonth = new Date(year, month, 0)
    const currentDate = new Date(endOfMonth)
    const nextDates = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
    setStartDate(startOfMonth)
    setEndDate(nextDates.toISOString().split('T')[0])
  }
  useEffect(() => {
    startSxpProxy(LABS_PROJECT_ID, panelSummary, {
      start: startDate,
      end: isoDateFormat(add(new Date(endDate), { days: 1 })),
    }).then((data) => {
      const transformedArray = Object.keys(data?.data).map((location: any) => ({
        ...data?.data[location],
        location: location,
      }))
      setLocationData(transformedArray)
    })
  }, [monthSelector, startDate, endDate])

  return (
    <>
      <div className='membershipText'>{lmsdashboard}</div>
      <div className='summary-date'>
        <label htmlFor='startDate' className='start-date'>
          Start Date:
        </label>
        <input
          className='datePickerUI'
          type='date'
          id='startDate'
          name='startDate'
          value={startDate}
          max={endDate}
          onChange={handleDateChange}
        />
        <br />
        <label htmlFor='endDate' className='start-date'>
          End Date:
        </label>
        <input
          className='datePickerUI'
          type='date'
          id='endDate'
          name='endDate'
          value={endDate}
          min={startDate}
          onChange={handleDateChange}
        />
        <br />
        <label htmlFor='monthSelector' className='start-date'>
          Select Month:
        </label>
        <input
          className='datePickerUI'
          type='month'
          id='monthSelector'
          name='monthSelector'
          value={monthSelector}
          onChange={handleMonthChange}
        />
      </div>
      <div className='data-table table-fixed admin-table-table-container w84 m10'>
        <table className='data-table table-fixed admin-table relative'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th rowSpan={1} className='text-center w2'></th>
              <th colSpan={4} className='text-center w7'>
                Total Tests
              </th>
              <th colSpan={3} className='text-center w5'>
                Processed At Medunited
              </th>
              <th colSpan={3} className='text-center w5'>
                Referred Out
              </th>
            </tr>
            <tr>
              <th className='text-center'>Operating Unit</th>
              <th className='text-center'>Total</th>
              <th className='text-center'>Completed</th>
              <th className='text-center'>Pending</th>
              <th className='text-center'>Sample Collection Pending</th>
              <th className='text-center'>Total</th>
              <th className='text-center'>Completed</th>
              <th className='text-center'>Pending</th>
              <th className='text-center'>Total</th>
              <th className='text-center'>Completed</th>
              <th className='text-center'>Pending</th>
            </tr>
          </thead>
          <tbody className='admin-table-body'>
            {locationData?.map((d: any, i: number) => (
              <tr key={i}>
                <td className='text-center'>{d.location}</td>
                <td className='text-center'>{d?.totalOrdersPlaced}</td>
                <td className='text-center'>
                  {d.medUnitedCompleted + d.referredOutCompleted}
                </td>
                <td className='text-center'>
                  {d.medUnitedPending + d.referredOutPending}
                </td>
                <td className='text-center'>{d.testsYetToBeDone}</td>
                <td className='text-center'>
                  {d.medUnitedTotalOrdersCollected}
                </td>
                <td className='text-center'>{d.medUnitedCompleted}</td>
                <td className='text-center'>{d.medUnitedPending}</td>
                <td className='text-center'>{d?.referredOutTotal}</td>
                <td className='text-center'>{d?.referredOutCompleted}</td>
                <td className='text-center'>{d.referredOutPending}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}
export default LmsView

export const radiologyEndPoint = {
  radiologyResults: '/radiology/search/results',
  radiologyDashboard: '/radiology/dashboard',
  create: 'create',
  radiologySearch: '/radiology/search',
  radiologyCreate: '/radiology/search/create',
  bedAssignmentList: '/bedIpd/bedAssignmentList',
  opd: '/opd',
  results: 'results',
  radiologist: '/administration/radiologist',
  editRadiologist: '/administration/radiologist/edit',
  referredOut: '/administration/referredOut',
  createRadiologist: '/administration/radiologist/create',
  createRefferedOut: '/administration/referredOut/create',
  edit: 'edit',
}

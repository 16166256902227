import { useEffect, useState } from 'react'
import { startSxpProxy } from '../../../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../../../utils/constants'
import { radiologyIntents } from '../../Constants/constants'
import { RadiologyOrders } from '../../models'

export const useRadiologyOrders = (id: string, source: string) => {
  const [orders, setOrders] = useState<RadiologyOrders[]>([])
  const getRadiologyOrders = () => {
    const state = {
      patientId: id,
      source: source,
    }
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents.getRadiologyOrdersByPatientIdAndStatus,
      state
    )
      .then((data: any) => {
        setOrders(data?.data?.radiology_orders ?? [])
      })
      .catch(() => {
        setOrders([])
      })
  }

  useEffect(() => {
    getRadiologyOrders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, source])

  return orders
}

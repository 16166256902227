import { Patient } from 'fhir/r4'
import { RadiologyResult, radiologyValues } from '../../models'
import { LmsObservation } from '../../../lms/models'
import RadiologyObservationList from '../../observations/RadiologyObservationList'
import { radiologyTestStatus } from '../../Constants/constants'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'

type props = {
  id: number
  result: RadiologyResult
  patient: Patient | null
  isReferred: boolean
  onValuesChange: (
    values: radiologyValues,
    index: number,
    patientId?: string
  ) => void
  onEnter?: (id: number) => void
  onValueChange?: (val: string, index: number) => void
  disabled: boolean
  isEdit: boolean
  onCommentChange?: (comments: string) => void
  valuesUpdated?: boolean
}
const styles = () => ({
  testRow: {
    display: 'flex',
    border: `1px solid ${radiologyColor?.softGrey}`,
    padding: '6px',
    marginBottom: '2px',
    borderRadius: '2px',
    flexDirection: 'column',
    gap: '10px',
  },
  rejectedTest: {
    color: radiologyColor?.red,
    cursor: 'pointer',
  },
})
const RadiologyTestRow = ({
  id,
  result,
  patient,
  isReferred,
  onValuesChange,
  isEdit,
  disabled,
  onCommentChange,
  valuesUpdated,
}: props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const isDisabled = isEdit
    ? false
    : disabled ||
      result?.test_status === radiologyTestStatus?.rejected ||
      result?.test_status === radiologyTestStatus?.submitted ||
      (!!result.referred_out && !isReferred)
  const handleSummaryChange = (str: string) => {
    const values = result?.values
    if (values) {
      onValuesChange({ ...values, summary: str }, id, patient?.id)
    } else {
      onValuesChange({ summary: str, observations: [] }, id, patient?.id)
    }
  }

  const handleObservationChange = (obs: LmsObservation[]) => {
    const values = result?.values
    if (values) {
      onValuesChange({ ...values, observations: obs }, id, patient?.id)
    } else {
      onValuesChange({ observations: obs }, id, patient?.id)
    }
  }
  const handleCommentChange = (comments: string) => {
    onCommentChange?.(comments)
  }
  return (
    <>
      <div
        className={`${classes?.testRow} ${
          result?.test_status === radiologyTestStatus?.rejected &&
          classes?.rejectedTest
        }`}
      >
        <RadiologyObservationList
          disabled={isDisabled}
          values={result?.values}
          onSummaryChange={handleSummaryChange}
          onObservationChange={handleObservationChange}
          onCommentsChange={handleCommentChange}
          result={result}
          isEdit={isEdit}
          valuesUpdated={valuesUpdated}
          isReferred={isReferred}
        />
      </div>
    </>
  )
}
export default RadiologyTestRow

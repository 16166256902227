import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import EditButton from '../../components/EditButton'
import { startSxpProxy } from '../../utils/api'
import { ADMIN_PROJECT_ID } from '../../utils/constants'
import { OrganizationIntent } from '../administration/constants'
import ProvenanceView from '../../components/ProvenanceView'
import { useTheme } from '@emotion/react'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { Box, Button, Tooltip } from '@mui/material'
import { ColDef, ITextFilterParams } from 'ag-grid-community'
import MedAgDataGrid from '../../components/MedAgDataGrid'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { radiologyEndPoint } from '../Radiology/RadiologyEndPoints'
import { radiologyColor } from '../Radiology/Utils/RadiologyColors'
import {
  provenanceCreate,
  provenanceUpdate,
} from '../Radiology/Constants/constants'
const styles = () => ({
  partnerDasboardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    width: '100%',
    height: '95%',
    padding: '2%',
    gap: 20,
  },
  partnerDasboardTableContainer: {
    width: '100%',
    height: '100%',
  },
  partnerDasboardCreateBtn: {
    textTransform: 'capitalize',
    backgroundColor: radiologyColor?.steelBlue,
  },
  centerHeader: {
    color: radiologyColor?.white,
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
  redCircleOutIcon: {
    color: radiologyColor?.red,
  },
  greenCircleOutIcon: {
    color: radiologyColor?.green,
  },
})

const Organization = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [loading, setLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [organizations, setOrganizations] = useState([])

  const getOrganizations = () => {
    setLoading(true)
    startSxpProxy(
      ADMIN_PROJECT_ID,
      OrganizationIntent?.getAllOrganizationForAdmin,
      {}
    )
      .then(async (data) => {
        if (data?.data?.organization?.length > 0) {
          setOrganizations(data?.data?.organization)
          setLoading(false)
        } else {
          setOrganizations([])
          setLoading(false)
        }
      })
      .catch(() => {
        setOrganizations([])
        setLoading(false)
      })
  }

  const handleEdit = (id: any) => {
    navigate(`${radiologyEndPoint?.edit}/${id}`)
  }
  const RenderCreatedBy = (params: any) => {
    return (
      <ProvenanceView
        date={params?.data?.date_created}
        name={params?.data?.created_by}
        mode={provenanceCreate}
      />
    )
  }

  const RenderUpdatedBy = (params: any) => {
    return (
      <ProvenanceView
        date={params?.data?.date_changed}
        name={params?.data?.updated_by}
        mode={provenanceUpdate}
      />
    )
  }
  const renderEditActions = (params: any) => {
    return (
      <EditButton
        title='Edit Doctor'
        onEdit={() => handleEdit(params?.data?.id)}
      />
    )
  }
  const labRenderer = (params: any) => {
    const checkLab = params?.data?.isLab
    return checkLab ? (
      <Tooltip title='Available' arrow>
        <CheckCircleOutlineIcon className={classes?.greenCircleOutIcon} />
      </Tooltip>
    ) : (
      <Tooltip title='UnAvailable' arrow>
        <HighlightOffIcon className={classes?.redCircleOutIcon} />
      </Tooltip>
    )
  }

  const radiologyRenderer = (params: any) => {
    const checkLab = params?.data?.isRadiology
    return checkLab ? (
      <Tooltip title='Available' arrow>
        <CheckCircleOutlineIcon className={classes?.greenCircleOutIcon} />
      </Tooltip>
    ) : (
      <Tooltip title='UnAvailable' arrow>
        <HighlightOffIcon className={classes?.redCircleOutIcon} />
      </Tooltip>
    )
  }
  const isCustomerRenderer = (params: any) => {
    const checkLab = params?.data?.isCustomerRelate
    return checkLab ? (
      <Tooltip title='Available' arrow>
        <CheckCircleOutlineIcon className={classes?.greenCircleOutIcon} />
      </Tooltip>
    ) : (
      <Tooltip title='UnAvailable' arrow>
        <HighlightOffIcon className={classes?.redCircleOutIcon} />
      </Tooltip>
    )
  }

  const isVendorRenderer = (params: any) => {
    const checkLab = params?.data?.isVendorRelate
    return checkLab ? (
      <Tooltip title='Available' arrow>
        <CheckCircleOutlineIcon className={classes?.greenCircleOutIcon} />
      </Tooltip>
    ) : (
      <Tooltip title='UnAvailable' arrow>
        <HighlightOffIcon className={classes?.redCircleOutIcon} />
      </Tooltip>
    )
  }
  const FilterActions = () => {
    const navigateCreate = () => {
      navigate(radiologyEndPoint?.createRefferedOut)
    }
    return (
      <Button
        variant='contained'
        size='small'
        className={classes?.partnerDasboardCreateBtn}
        onClick={navigateCreate}
      >
        Create
      </Button>
    )
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'Sl No',
      field: 'id',
      width: 90,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Partner Name',
      field: 'name',
      flex: 1,
      sortable: true,
      filter: true,
      filterParams: { buttons: ['clear'] } as ITextFilterParams,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 165,
    },
    {
      headerName: 'Mobile',
      field: 'mobile_number',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 110,
    },
    {
      headerName: 'status',
      field: 'status',
      flex: 1,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 120,
    },
    {
      headerName: 'Created By',
      field: 'date_created',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: RenderCreatedBy,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 170,
    },
    {
      headerName: 'Updated By',
      field: 'date_changed',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: RenderUpdatedBy,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 165,
    },
    {
      headerName: 'LabType',
      field: 'isLab',
      width: 100,
      sortable: true,
      filter: true,
      cellRenderer: labRenderer,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      minWidth: 130,
    },

    {
      headerName: 'Radiology Type',
      field: 'isRadiology',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: radiologyRenderer,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      minWidth: 130,
    },
    {
      headerName: 'Vendor Type',
      field: 'isVendorType',
      width: 100,
      sortable: true,
      filter: true,
      cellRenderer: isVendorRenderer,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      minWidth: 120,
    },
    {
      headerName: 'Customer Type',
      field: 'isCustomerType',
      width: 100,
      sortable: true,
      filter: true,
      cellRenderer: isCustomerRenderer,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      minWidth: 120,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 100,
      sortable: false,
      filter: false,
      cellRenderer: renderEditActions,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      minWidth: 120,
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
  ]
  useEffect(() => {
    getOrganizations()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <>
      <Box className={classes?.partnerDasboardContainer}>
        <Box className={classes?.partnerDasboardTableContainer}>
          <MedAgDataGrid
            tableHeading='Partner'
            tableHeight={'100%'}
            rowData={organizations}
            columnDefs={columnDefs}
            pagination={false}
            headerHeight={30}
            searchFieldWidth={200}
            loading={loading}
            filterActions={<FilterActions />}
          />
        </Box>
      </Box>
    </>
  )
}
export default Organization

export const radiologyColor = {
  steelBlue: '#4682b4',
  deepBlue: '#090968',
  white: '#ffffff',
  blue: 'blue',
  red: 'red',
  green: 'green',
  black: 'black',
  gray: 'gray',
  yellow: 'yellow',
  brown: 'brown',
  orange: 'orange',
  whiteSmoke: '#f2f2f2',
  lightGrey: '#ccc',
  darkBlue: '#183f7c',
  corporateBlue: '#004c91',
  softGrey: '#e6e6e6',
  whisperGray: '#f1f1f1',
  transparent: 'transparent',
  azureBlue: '#0088ff',
  pearlGray: '#ebebeb',
  softGrayTransparent: 'rgba(70, 70, 70, 0.2)',
  prussianBlue: '#0d3f6d',
  ashGray: '#b8b6b6',
  pureWhite: '#fff',
  warmGray: '#999494',
  metallicGray: '#aeafb5',
  charcoal: '#212121',
  neutralGray: '#9E9E9E',
  paleGrey: '#d5d2d2',
  semiTransparentWhite: 'rgba(255, 255, 255, 0.6)',
}

import React from 'react'
import SelectableSearch from '../../../components/SelectableSearch'
import { useAppDispatch } from '../../../app/hooks'
import {
  fetchPatientsAsync,
  setPatientSearchQueryValue,
} from '../../patients/patientsSlice'
import { useLocation } from 'react-router-dom'
import { BED_IPD_DASHBOARD_URL } from '../endpoints/EndPoints'

const IpdSearch = ({
  onSearch,
  onCreateVisit,
}: {
  onSearch: (query: string, param: string) => void
  onCreateVisit: () => void
}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()

  const isSpecificRoute = location.pathname === BED_IPD_DASHBOARD_URL

  const handleSearch = (query: string, param: string) => {
    let q = ''
    switch (query) {
      case 'ID':
        q = 'identifier'
        break
    }
    dispatch(setPatientSearchQueryValue({ query: q, value: param }))
    dispatch(fetchPatientsAsync())
    onSearch(q, param)
  }

  return (
    <div
      className='patient-filter'
      style={{ display: 'flex', justifyContent: 'flex-end' }}
    >
      <div className='patient-search'>
        <SelectableSearch items={['ID']} onSearch={handleSearch} />
      </div>
      {isSpecificRoute && (
        <div style={{ paddingBottom: '8px' }}>
          <button className='btn btn-primaryBtn' onClick={onCreateVisit}>
            Create Visit
          </button>
        </div>
      )}
    </div>
  )
}

export default IpdSearch

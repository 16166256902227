import { useState, useEffect } from 'react'
import { startSxpProxy } from '../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../utils/constants'
import { radiologyOrganization } from './models'
import { radiologyIntents } from './Constants/constants'

export const useRadiologyOrganizations = () => {
  const [organizations, setOrganizations] = useState<radiologyOrganization[]>(
    []
  )
  const getOrganizations = () => {
    const intent = radiologyIntents?.getRadiologyOrganizations
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent, {})
      .then((data) => {
        setOrganizations(data?.data?.organization)
      })
      .catch((err) => {
        console.log(err)
        setOrganizations([])
      })
  }
  useEffect(() => {
    getOrganizations()
  }, [])

  return organizations
}

import React from 'react'
interface DialogMessages {
  replaceTestWithPackage: string
  proceedTestWithPackage: string
}

interface TestReplacementDialogContentProps {
  dialogMessages: DialogMessages
  filteredTests: any[]
}

const TestReplacementDialogContent: React.FC<
  TestReplacementDialogContentProps
> = ({ dialogMessages, filteredTests }) => {
  return (
    <>
      {dialogMessages?.replaceTestWithPackage}
      <div>
        {filteredTests?.map((item) => (
          <li key={item?.testName?.value}>{item?.testName?.label}</li>
        ))}
      </div>
      {dialogMessages?.proceedTestWithPackage}
    </>
  )
}

export default TestReplacementDialogContent

import { ChangeEvent, useEffect, useRef, useState } from 'react'
import {
  RadiologyOrganization,
  activeTabs,
  chooseOption,
  dialogMessages,
  emptyString,
  radiologyConditions,
  radiologyTestStatus,
  referred,
  tabs,
  visibleDownArrow,
  visibleUpArrow,
} from '../../Constants/constants'
import { RadiologyResult, radiologyValues } from '../../models'
import { labDepartments } from '../../../lms/models'
import { makeName } from '../../../lms/utils'
import { Patient } from 'fhir/r4'
import { FILE_SERVER_URL } from '../../../../utils/constants'
import { uploadImage } from '../../../../utils/api'
import '../../radiology.scss'
import RadiologyTestRow from './RadiologyTestRow'
import KeycloakService from '../../../../utils/keycloakService'
import { TESTING_WRITE } from '../../../../utils/roles'
import { useAppSelector } from '../../../../app/hooks'
import { selectedRadiologyActiveTab } from '../../RadiologySlice'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Tooltip from '@mui/material/Tooltip'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Collapse from '@mui/material/Collapse'
import { radiologyColor } from '../../Utils/RadiologyColors'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
type props = {
  result: RadiologyResult
  results?: RadiologyResult[]
  mode: string
  active: number
  isReferred: boolean
  patient: Patient | null
  labDepartments?: labDepartments[]
  onReferredOutChange?: (referred: boolean, panelId: number) => void
  onReferredToChange?: (referred: number, panelId: number) => void
  onDocUrlChange: (panelId: number, referred?: string) => void
  onEditCancel?: (edit: boolean, id: number) => void
  disabled: boolean
  organizations: RadiologyOrganization[]
  onValuesChange: (values: radiologyValues, index: number) => void
  onEnter: (id: number) => void
  onNotesChange?: (val: string, index: number) => void
  onExtraValueChange?: (val: string, index: number, pId?: string) => void
  onValueChange: (val: string, index: number) => void
  onCheckboxChange?: (
    testId: number,
    isChecked: boolean,
    test: RadiologyResult
  ) => void
  valuesUpdated?: boolean
  rowEditing?: number
  onCheckboxApprove?: (
    testId: number,
    isCheckedApprove: boolean,
    tests: RadiologyResult,
    radiologist: string,
    reason: string
  ) => void
  allChecked?: boolean
  allApproveCheck?: boolean
  onRadiologists?: (radiologist: string, testId: number) => void
  onReasons?: (reason: string, testId: number) => void
  testingCount?: number
  validateCount?: number
  apiLoading: boolean
  radiologyDepartments: any[]
  reload: number
}
const styles = () => ({
  tableRow: {
    backgroundColor: `${radiologyColor?.warmGray} !important`,
    borderRight: `1px solid ${radiologyColor?.white}`,
  },
  tableCell: {
    borderRight: `1px solid ${radiologyColor?.white}`,
  },
  testNameExpansion: {
    display: 'flex',
  },
  testNameAction: {
    cursor: 'pointer',
  },
  testNameContent: {
    alignItems: 'center',
    color: radiologyColor?.black,
    display: 'flex',
    fontFamily: 'sans-serif',
    fontSize: '12px',
    fontWeight: 600,
    margin: '0 30px 0 0',
    cursor: 'pointer',
  },
  referredContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
  },
  referredCheckContainer: {
    display: 'flex',
    width: '35%',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  radiologyUploadImageContainer: {
    display: 'flex',
    width: '10%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  referredImageContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  noDocUrl: {
    color: radiologyColor?.darkBlue,
  },
  referredImageContent: {
    width: '16%',
    marginLeft: '50px',
  },
  notReferredContent: {
    width: '54%',
  },
  noneDisplay: {
    display: 'none',
  },
  testDocument: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  referredText: {
    whiteSpace: 'nowrap',
    marginRight: '22px',
  },
  testIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    alignItems: 'center',
  },
  radiologyTestAction: {
    display: 'flex',
    justifyContent: 'end',
    gap: '20px',
  },
  radiologySubmitted: {
    display: 'flex',
    justifyContent: 'end',
    gap: '20px',
  },
  referredSavedTest: {
    color: radiologyColor?.yellow,
    cursor: 'pointer',
  },
  noreferredTest: {
    color: radiologyColor?.brown,
    cursor: 'pointer',
  },
  savedTest: {
    color: radiologyColor?.green,
    cursor: 'pointer',
  },
  noSubmitAction: {
    color: radiologyColor?.orange,
    cursor: 'pointer',
  },
  rejectedTest: {
    color: radiologyColor?.red,
    cursor: 'pointer',
  },
  approveTestAction: {
    color: radiologyColor?.darkBlue,
    cursor: 'pointer',
  },
  radiologistInput: {
    padding: '5px 8px',
    border: 'none',
  },
  radiologySubmittedContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '10px',
    alignItems: 'center',
  },
  referredGroupResult: {
    color: radiologyColor?.black,
  },
  radiologyApproveCheckbox: {
    display: 'flex',
    gap: '8px',
  },
  testRowTableCell: {
    padding: '1px !important',
    backgroundColor: radiologyColor?.white,
  },
})
const RadiologyTestGroup = ({
  result,
  mode,
  isReferred,
  patient,
  onEditCancel,
  onDocUrlChange,
  onReferredOutChange,
  onReferredToChange,
  onValuesChange,
  onEnter,
  disabled,
  organizations,
  onValueChange,
  valuesUpdated,
  onCheckboxChange,
  allChecked,
  testingCount,
  rowEditing,
  validateCount,
  onCheckboxApprove,
  allApproveCheck,
  radiologyDepartments,
  onReasons,
  onRadiologists,
  reload,
}: props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const filteredRadiologists = radiologyDepartments?.filter((e: any) =>
    e?.specialty?.includes(result?.sample_name)
  )
  const docsRef = useRef<HTMLInputElement | null>(null)
  const [radiologist, setRadiologist] = useState<string>('')
  const [isExpanded, setIsExpanded] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [reason, setReason] = useState<string>('')
  const [isCheckApprove, setIsCheckApprove] = useState<boolean>(false)
  const activeTab: string = useAppSelector(selectedRadiologyActiveTab)

  const handleFileButton = () => {
    docsRef.current?.click()
  }

  const handleEdit = () => {
    setIsEdit(!isEdit)
    onEditCancel?.(isEdit, result?.test_id)
    if (isEdit) {
      setIsExpanded(false)
    } else {
      setIsExpanded(true)
    }
  }
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e?.target?.files?.[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file, 'report.pdf')
      uploadImage(formData)
        .then((data) => {
          const uploaded = data?.data?.[0]
          onDocUrlChange(result?.test_id, `${FILE_SERVER_URL}file/${uploaded}`)
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      onDocUrlChange(result?.test_id)
    }
  }
  const handleReferChange = () => {
    const newReferredOutState = !result?.referred_out
    onReferredOutChange?.(result?.referred_out ?? false, result?.test_id)
    if (newReferredOutState) {
      onDocUrlChange(result?.test_id)
    }
  }
  const isCheckTrue =
    !valuesUpdated &&
    !isReferred &&
    KeycloakService?.hasRole([TESTING_WRITE]) &&
    result?.test_status === radiologyTestStatus?.entered &&
    (result?.value || result?.values?.summary) &&
    (!result?.referred_out || result?.document_url)
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newChecked = event.target.checked
    setIsChecked(newChecked)
    onCheckboxChange?.(result?.test_id, newChecked, result)
  }
  const handleCheckboxApproveChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newChecked = event.target.checked
    setIsCheckApprove(newChecked)
    onCheckboxApprove?.(
      result?.test_id,
      newChecked,
      result,
      radiologist,
      reason
    )
  }
  const handleReason = (event: string) => {
    setReason(event)
    onReasons?.(event, result?.test_id)
  }
  const handlePathName = (event: ChangeEvent<HTMLSelectElement>) => {
    const path = event.target.value
    setRadiologist(path)
    onRadiologists?.(path, result?.test_id)
  }

  useEffect(() => {
    if (allChecked) {
      if (
        result?.test_status === radiologyTestStatus?.entered &&
        (result?.value || result?.values?.summary) &&
        (!result?.referred_out || result?.document_url)
      ) {
        setIsChecked(allChecked)
        onCheckboxChange?.(result?.test_id, false, result)
        onCheckboxChange?.(result?.test_id, true, result)
      }
    } else {
      setIsChecked(false)
      onCheckboxChange?.(result?.test_id, false, result)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testingCount])
  useEffect(() => {
    if (allApproveCheck) {
      if (
        result?.test_status === radiologyTestStatus?.submitted &&
        (result?.value || result?.values?.summary)
      ) {
        setIsCheckApprove(allApproveCheck)
        onCheckboxApprove?.(result?.test_id, false, result, radiologist, reason)
        onCheckboxApprove?.(result?.test_id, true, result, radiologist, reason)
      }
    } else {
      setIsCheckApprove(false)
      onCheckboxApprove?.(result?.test_id, false, result, radiologist, reason)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validateCount])
  useEffect(() => {
    setIsEdit(false)
  }, [rowEditing])
  useEffect(() => {
    setIsEdit(false)
    setIsExpanded(false)
  }, [reload])

  return (
    <>
      <TableRow className={classes?.tableRow}>
        <TableCell className={classes?.tableCell}>
          <div className={classes?.testNameExpansion}>
            <div
              className={classes?.testNameAction}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <span>{isExpanded ? visibleDownArrow : visibleUpArrow}</span>
            </div>

            <h3
              className={classes?.testNameContent}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {result?.test_name}
              {isReferred && (
                <span>
                  &nbsp;[{makeName(patient?.name)} -{' '}
                  {patient?.identifier?.[2]?.value ??
                    patient?.identifier?.[0]?.value ??
                    emptyString}
                  ]
                </span>
              )}
            </h3>
          </div>
        </TableCell>
        <TableCell className={classes?.tableCell}>
          <div
            className={`${
              mode !== referred
                ? classes?.referredContainer
                : classes?.referredCheckContainer
            }`}
          >
            <div
              className={`${
                mode !== referred
                  ? classes?.radiologyUploadImageContainer
                  : classes?.referredImageContainer
              }`}
            >
              <DriveFolderUploadIcon
                titleAccess='Upload Documents'
                onClick={handleFileButton}
                className={`${classes?.noDocUrl} ${
                  mode !== referred
                    ? classes?.notReferredContent
                    : classes?.referredImageContent
                }`}
              />

              <input
                id='docUrl'
                ref={docsRef}
                type='file'
                accept='application/pdf'
                className={classes?.noneDisplay}
                onChange={handleFileChange}
                disabled={
                  result?.referred_out === true &&
                  mode !== radiologyConditions?.referred
                }
              />

              {result?.document_url && (
                <a
                  href={result?.document_url}
                  target='_blank'
                  rel='noreferrer'
                  download
                  className={classes?.testDocument}
                >
                  <VisibilityOutlinedIcon fontSize='small' />
                </a>
              )}
            </div>
            <div>
              {isReferred ? (
                <span className={classes?.referredText}>
                  Referred To:{' '}
                  {organizations?.find((o) => o?.id === result?.referred_to)
                    ?.name ?? emptyString}
                </span>
              ) : (
                <>
                  <div className={classes?.testNameExpansion}>
                    <input
                      type='checkbox'
                      checked={result?.referred_out ?? false}
                      disabled={
                        disabled ||
                        result?.test_status === radiologyTestStatus?.approved ||
                        result?.test_status ===
                          radiologyTestStatus?.submitted ||
                        result?.test_status === radiologyTestStatus?.rejected
                      }
                      onChange={handleReferChange}
                    />
                    <select
                      name='Referred'
                      value={result?.referred_to ?? 0}
                      disabled={
                        disabled ||
                        result?.test_status === radiologyTestStatus?.approved ||
                        result?.test_status ===
                          radiologyTestStatus?.submitted ||
                        result?.test_status === radiologyTestStatus?.rejected
                      }
                      onChange={(ev) =>
                        onReferredToChange?.(
                          parseInt(ev.target.value),
                          result?.test_id
                        )
                      }
                    >
                      <option value={0}>{chooseOption}</option>
                      {organizations?.map((org) => (
                        <option key={org?.id} value={org?.id}>
                          {org?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>
          </div>
        </TableCell>
        <TableCell>
          <div
            className={`${classes?.testIcons} ${
              mode !== referred
                ? `${
                    result?.referred_out
                      ? classes?.radiologyTestAction
                      : classes?.radiologySubmitted
                  }`
                : emptyString
            }`}
          >
            <div>
              {result?.referred_out &&
              result?.test_status === radiologyTestStatus?.entered &&
              (result?.value || result?.values?.summary) &&
              result?.document_url ? (
                <Tooltip title={dialogMessages?.referredTestSaved}>
                  <CheckCircleIcon className={classes?.referredSavedTest} />
                </Tooltip>
              ) : result?.referred_out &&
                result?.test_status === radiologyTestStatus?.entered &&
                (!result?.value ||
                  !result?.values?.summary ||
                  !result?.document_url) ? (
                <Tooltip title={dialogMessages?.noReferredValues}>
                  <CheckCircleIcon className={classes?.noreferredTest} />
                </Tooltip>
              ) : result?.test_status === radiologyTestStatus?.entered &&
                (result?.value || result?.values?.summary) ? (
                <Tooltip title={dialogMessages?.savedTest}>
                  <CheckCircleIcon className={classes?.savedTest} />
                </Tooltip>
              ) : result?.test_status === radiologyTestStatus?.entered &&
                (!result?.value || !result?.values?.summary) ? (
                <Tooltip title={dialogMessages?.notReadyForSubmit}>
                  <CheckCircleIcon className={classes?.noSubmitAction} />
                </Tooltip>
              ) : null}
              {result?.test_status === radiologyTestStatus?.rejected && (
                <Tooltip title={dialogMessages?.rejectedTest}>
                  <CheckCircleIcon className={classes?.rejectedTest} />
                </Tooltip>
              )}
              {result?.test_status === radiologyTestStatus?.submitted && (
                <Tooltip title={dialogMessages?.readyToApprove}>
                  <CheckCircleIcon className={classes?.approveTestAction} />
                </Tooltip>
              )}
            </div>
            {isCheckTrue && (
              <div>
                <input
                  type='checkbox'
                  onChange={handleCheckboxChange}
                  checked={isChecked}
                  disabled={activeTab !== activeTabs?.testing}
                />
              </div>
            )}

            {!result?.referred_out &&
              result?.test_status === radiologyTestStatus?.submitted &&
              activeTab === tabs?.validation && (
                <>
                  <div>
                    <select
                      className={classes?.radiologistInput}
                      value={radiologist}
                      onChange={handlePathName}
                    >
                      <option value={emptyString}>Select Radiologist</option>
                      {filteredRadiologists?.map((org: any) => (
                        <option key={org?.id} value={org?.id}>
                          {org?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            {result?.test_status === radiologyTestStatus?.submitted && (
              <>
                <div className={classes?.radiologySubmittedContainer}>
                  <div>
                    <span onClick={handleEdit}>
                      {isEdit ? (
                        <CancelOutlinedIcon
                          titleAccess='Cancel Edit'
                          className={classes?.referredGroupResult}
                        />
                      ) : (
                        <EditOutlinedIcon titleAccess='Edit' />
                      )}
                    </span>
                  </div>
                  <div className={classes?.radiologyApproveCheckbox}>
                    <>
                      <input
                        type='checkbox'
                        onChange={handleCheckboxApproveChange}
                        checked={isCheckApprove}
                      />
                    </>
                  </div>
                </div>
              </>
            )}
          </div>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <>
          <TableRow>
            <TableCell colSpan={3} className={classes?.testRowTableCell}>
              <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                <RadiologyTestRow
                  id={result?.test_id}
                  result={result}
                  patient={patient}
                  isReferred={isReferred}
                  isEdit={isEdit}
                  disabled={disabled}
                  onValuesChange={onValuesChange}
                  onEnter={onEnter}
                  onValueChange={onValueChange}
                  onCommentChange={handleReason}
                  valuesUpdated={valuesUpdated}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  )
}
export default RadiologyTestGroup

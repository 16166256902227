import { useEffect, useState } from 'react'
import { Patient } from 'fhir/r4'
import { startSxpProxy } from '../../utils/api'
import { OPD_PROJECT_ID, RADIOLOGY_PROJECT_ID } from '../../utils/constants'
import { RadiologyOrders } from './models'
import { radiologyIntents } from './Constants/constants'

export const useSingleRadiologyOrder = (id: string, reload: number) => {
  const [order, setOrder] = useState<RadiologyOrders | null>(null)
  const [patient, setPatient] = useState<Patient | null>(null)
  const getRadiologyOrderById = () => {
    const intent = radiologyIntents?.getRadiologyOrderById
    const state = {
      radiologyOrderId: id,
    }
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
      .then((data) => {
        const ord: RadiologyOrders = data?.data?.radiology_orders_by_pk ?? {}
        if (ord) {
          setOrder(ord)
          const patId = ord?.patient_id
          if (patId) {
            startSxpProxy(OPD_PROJECT_ID, radiologyIntents?.getPatientApi, {
              id: patId,
            })
              .then((data) => {
                setPatient(data?.data ?? {})
              })
              .catch((err) => {
                console.log(err)
              })
          }
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    if (id) {
      getRadiologyOrderById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reload])

  return { order, patient }
}

import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  useTheme,
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { useFormik } from 'formik'
import Asterisk from '../../../components/Asterisk'
import {
  GENDER_OPTIONS,
  fieldRequriedError,
  nameSpecialCharFieldError,
  phoneFieldError,
  phoneRegExp,
  specialCharRegExp,
} from '../../ipRegistration/Constants'
import { startSxpProxy } from '../../../utils/api'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import { useState, useEffect } from 'react'
import ProfilePicture from '../../patientRegstration/ProfilePicture'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  radiologyIntents,
  SALUTATION_OPTIONS,
} from '../../Radiology/Constants/constants'
import { radiologyEndPoint } from '../../Radiology/RadiologyEndPoints'
import { radiologyColor } from '../../Radiology/Utils/RadiologyColors'

const styles = () => ({
  radiologistRegisterFormContainer: {
    width: '100%',
    height: '100%',
    padding: '0.6% 3% 3% 3%',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      fontWeight: 500,
    },
    '& .css-1hpqosy-MuiFormControlLabel-root': {
      marginLeft: '5px !important',
      marginRight: '5px !important',
    },
  },
  radiologistRegisterFormRow: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    rowGap: 20,
  },
  radiologistRegisterFormField: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
  },
  radiologistRegisterHeading: {
    fontSize: '14px',
    fontWeight: '600',
    color: radiologyColor?.deepBlue,
    textDecoration: 'underline',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  radiologistRegisterFormActions: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  radiologistRegisterFormColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 20,
    marginBottom: 30,
  },
  radiologistSubmitAction: {
    width: '10%',
  },
})
const RadiologistForm = () => {
  const { id } = useParams()
  const [speciality, setSpeciality] = useState<any[]>([])
  const theme = useTheme()
  const navigate = useNavigate()

  const classes = useCustomStyles(styles, theme)
  const [initialState, setInitialState] = useState({
    active: true,
    id: '',
    dob: '',
    email: '',
    firstName: '',
    gender: '',
    lastName: '',
    middleName: '',
    mobileNumber: '',
    photo: '',
    qualificationValue: '',
    regNumber: '',
    salutation: '',
    signature: '',
    specialities: [],
  })
  const getRadiologist = () => {
    startSxpProxy(ADMIN_PROJECT_ID, radiologyIntents?.getRadiologistById, {
      id,
    }).then((data: any) => {
      setInitialState({
        ...initialState,
        id: data?.data?.id,
        salutation: data?.data?.salutation || '',
        firstName: data?.data?.firstName || '',
        middleName: data?.data?.middleName || '',
        lastName: data?.data?.lastName || '',
        dob: data?.data?.dob || '',
        qualificationValue: data?.data?.qualification || '',
        gender: data?.data?.gender || '',
        regNumber: data?.data?.regNumber || '',
        mobileNumber: data?.data?.phone || '',
        email: data?.data?.email || '',
        specialities: data?.data?.specialities?.[0]?.coding || [],
        photo: data?.data?.photo || '',
        signature: data?.data?.signature || '',
        active: data?.data?.status,
      })
    })
  }
  const getRadiologyDepartments = () => {
    startSxpProxy(
      ADMIN_PROJECT_ID,
      radiologyIntents?.getRadiologyDepartments,
      {}
    )
      .then((data) => {
        const concepts =
          data?.data?.entry?.[0]?.resource?.compose?.include?.[0]?.concept
        setSpeciality(
          concepts.map((concept: any) => ({
            code: concept?.code,
            display: concept?.display,
          }))
        )
      })
      .catch((err) => console.error(err))
  }
  const handlePhotoChange = (photo: string) => {
    setFieldValue('photo', photo)
  }
  const handleSignatureChange = (photo: string) => {
    setFieldValue('signature', photo)
  }
  const handleSpecialityChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event
    const newSpecialities = value.map((val: any) =>
      typeof val === 'string'
        ? speciality?.find((s: any) => s.display === val) || {
            display: '',
            code: '',
          }
        : val
    )

    setFieldValue('specialities', newSpecialities)
  }
  const validationSchema = Yup.object({
    salutation: Yup.string().required(fieldRequriedError),
    firstName: Yup.string()
      .matches(specialCharRegExp, nameSpecialCharFieldError)
      .required(fieldRequriedError),
    lastName: Yup.string()
      .matches(specialCharRegExp, nameSpecialCharFieldError)
      .required(fieldRequriedError),
    gender: Yup.string().required(fieldRequriedError),
    mobileNumber: Yup.string()
      .matches(phoneRegExp, phoneFieldError)
      .required(fieldRequriedError),
    email: Yup.string().email('Invalid email').nullable(),
  })

  const {
    values,
    errors,
    setFieldValue,
    touched,
    handleBlur,
    handleChange,
    submitForm,
  } = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleRegistrationSubmit(values)
    },
    enableReinitialize: true,
  })
  const handleRegistrationSubmit = async (data: Record<string, any>) => {
    const {
      active,
      dob,
      email,
      firstName,
      gender,
      id,
      lastName,
      middleName,
      mobileNumber,
      photo,
      qualificationValue,
      regNumber,
      salutation,
      signature,
      specialities,
    } = data
    const formatted = {
      active,
      dob,
      email,
      firstName,
      gender,
      id,
      lastName,
      middleName,
      mobileNumber,
      photo,
      qualificationValue,
      regNumber,
      salutation,
      signature,
      specialities,
    }
    const intent = id
      ? radiologyIntents?.editRadiologist
      : radiologyIntents?.createRadiologist
    try {
      await startSxpProxy(ADMIN_PROJECT_ID, intent, formatted)
      navigate(radiologyEndPoint?.radiologist)
    } catch (error) {
      console.error('Error during registration:', error)
    }
  }
  const handleBack = () => {
    navigate(radiologyEndPoint?.radiologist)
  }
  useEffect(() => {
    getRadiologyDepartments()
  }, [])
  useEffect(() => {
    if (id) {
      getRadiologist()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <>
      <div className={classes?.radiologistRegisterFormContainer}>
        <div className={classes?.radiologistRegisterFormColumn}>
          <div className={classes?.radiologistRegisterHeading}>
            <span
              onClick={handleBack}
              className='back-button-cursor-dashboard radiologist-back-btn'
            >
              <KeyboardBackspaceIcon />
            </span>
            Radiologist Form
          </div>
          <Grid
            container
            spacing={1}
            className={classes?.radiologistRegisterFormRow}
          >
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>
                Status
                <Asterisk />
              </label>
              <FormControlLabel
                control={
                  <Checkbox
                    name='active'
                    checked={values?.active}
                    onChange={(e) => setFieldValue('active', e.target.checked)}
                  />
                }
                label='Active'
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>
                Salutation
                <Asterisk />
              </label>
              <TextField
                size='small'
                select
                name='salutation'
                placeholder='Salutation'
                value={values?.salutation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.salutation && Boolean(errors.salutation)}
                helperText={touched.salutation && errors.salutation}
              >
                {SALUTATION_OPTIONS?.map(
                  (item: { label: string; value: string }, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    )
                  }
                )}
              </TextField>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>
                First Name
                <Asterisk />
              </label>
              <TextField
                size='small'
                name='firstName'
                placeholder='Enter First Name'
                value={values?.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched?.firstName && errors.firstName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>Middle Name</label>
              <TextField
                size='small'
                name='middleName'
                placeholder='Enter Middle Name'
                value={values?.middleName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.middleName && Boolean(errors.middleName)}
                helperText={touched?.middleName && errors.middleName}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>
                Last Name
                <Asterisk />
              </label>
              <TextField
                size='small'
                name='lastName'
                placeholder='Enter Last Name'
                value={values?.lastName}
                InputLabelProps={{ shrink: true }}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched?.lastName && errors.lastName}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>
                Gender
                <Asterisk />
              </label>
              <TextField
                size='small'
                select
                name='gender'
                placeholder='Gender'
                value={values?.gender}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.gender && Boolean(errors.gender)}
                helperText={touched.gender && errors.gender}
              >
                {GENDER_OPTIONS?.map(
                  (item: { label: string; value: string }, index) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item?.label}
                      </MenuItem>
                    )
                  }
                )}
              </TextField>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>DOB</label>
              <TextField
                type='date'
                size='small'
                name='dob'
                placeholder='Enter DOB'
                value={values?.dob}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.dob && Boolean(errors.dob)}
                helperText={touched?.dob && errors.dob}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>email</label>
              <TextField
                size='small'
                name='email'
                placeholder='Enter Email'
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && Boolean(errors.email)}
                helperText={touched?.email && errors.email}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>Mobile Number</label>
              <TextField
                size='small'
                name='mobileNumber'
                placeholder='Mobile Number'
                value={values?.mobileNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                helperText={touched?.mobileNumber && errors.mobileNumber}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>Qualification</label>
              <TextField
                size='small'
                name='qualificationValue'
                placeholder='Qualification'
                value={values?.qualificationValue}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.qualificationValue &&
                  Boolean(errors.qualificationValue)
                }
                helperText={
                  touched?.qualificationValue && errors.qualificationValue
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.radiologistRegisterFormField}
            >
              <label>Reg No.</label>
              <TextField
                size='small'
                name='regNumber'
                placeholder='Registration Number'
                value={values?.regNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.regNumber && Boolean(errors.regNumber)}
                helperText={touched?.regNumber && errors.regNumber}
              />
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              lg={3}
              xl={3}
              className={classes?.partnerRegisterFormField}
            >
              <label>Specialities</label>
              <FormControl fullWidth>
                <Select
                  multiple
                  value={values?.specialities?.map((s: any) => s?.display)}
                  onChange={handleSpecialityChange}
                  renderValue={(selected) => selected?.join(', ')}
                >
                  {speciality?.map((s: any) => (
                    <MenuItem key={s?.code} value={s?.display}>
                      <Checkbox
                        checked={values?.specialities?.some(
                          (sp: any) => sp?.display === s?.display
                        )}
                      />
                      <ListItemText primary={s?.display} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </div>
        <div className='picture-container'>
          <ProfilePicture
            photo={values?.photo as string}
            onPhotoChange={handlePhotoChange}
            item='admin'
          />
          <ProfilePicture
            photo={values?.signature as string}
            onPhotoChange={handleSignatureChange}
            item='signature'
          />
        </div>

        <div className={classes?.radiologistRegisterFormActions}>
          <Button
            variant='contained'
            color='primary'
            size='small'
            className='partner-submit-btn'
            onClick={submitForm}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  )
}
export default RadiologistForm

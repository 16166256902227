import React, { useEffect, useState } from 'react'
import EditButton from '../../../components/EditButton'
import { useNavigate } from 'react-router-dom'
import '../admin.scss'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  fetchLocationsAsync,
  selectLocations,
} from '../../location/locationSlice'
import { startSxpProxy } from '../../../utils/api'
import {
  ADMIN_PROJECT_ID,
  REGISTRATION_PROJECT_ID,
} from '../../../utils/constants'
import { toast } from 'react-toastify'
import { locationtext } from '../../patientRegstration/constants'
import { create, createprovenance } from '../../Radiology/Constants/constants'
import { Provenance, Practitioner } from 'fhir/r4'
import { getProvenanceData } from '../../patients/utils'
import {
  locationtableHeadings,
  update,
  UserManagementIntent,
} from '../constants'
import { ProvenanceData } from '../../patients/patientsSlice'
import ProvenanceView from '../../../components/ProvenanceView'
type FacilityType = {
  id: number
  name: string
}
const Locations = () => {
  const [reload, setReload] = useState(0)
  const [statusFilter, setStatusFilter] = useState('active')
  const [searchText, setSearchText] = useState<string>('')
  const locations = useAppSelector(selectLocations)
  // const [cluster, setcluster] = useState([])
  const [type, setType] = useState<FacilityType[]>([])
  const [checkedItems, setCheckedItems] = useState<boolean[]>([])
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})

  useEffect(() => {
    dispatch(fetchLocationsAsync())
  }, [dispatch, reload])

  const handleCreate = () => {
    navigate('create')
  }

  const handleEdit = (id?: string) => {
    if (id) {
      navigate(`edit/${id}`)
    }
  }

  const handleStatusChange = (index: number) => {
    const location = filteredLocations[index]
    if (location?.resource?.id) {
      const state = {
        id: location?.resource.id,
        locationName: location?.resource.name,
        locationPrefix: location?.resource.identifier?.[1]?.value,
        address: location?.resource?.address?.line?.[0],
        email: location?.resource?.telecom?.[1]?.value,
        mobile: location?.resource?.telecom?.[0]?.value,
        status: location?.resource?.status === 'active' ? 'inactive' : 'active',
        dlNumber: location?.resource?.type?.[0].coding?.[0]?.display,
        hasInventory: location?.resource?.type?.[0].coding?.[3]?.display,
        type: location?.resource?.type?.[0].coding?.[2]?.display,
        gst: location?.resource?.type?.[0].coding?.[1]?.display,
      }

      startSxpProxy(ADMIN_PROJECT_ID, 'editLocation', state)
        ?.then(() => {
          setReload((r) => r + 1)
          setCheckedItems([])
        })
        .catch(() => {
          toast.error('Failed to Update Location status...')
        })
    }
  }

  const handleStatusFilterChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStatusFilter(event.target.value)
    setCheckedItems([])
  }

  const handleCheckboxChange = (index: number) => {
    const newCheckedItems = [...checkedItems]
    newCheckedItems[index] = !newCheckedItems[index]
    setCheckedItems(newCheckedItems)
  }
  useEffect(() => {
    const GetClusterAndType = async () => {
      try {
        const intent = 'getClusterandType'
        const resp = await startSxpProxy(ADMIN_PROJECT_ID, intent)
        if (resp?.data) {
          // setcluster(resp?.data?.data?.clusters)
          setType(resp?.data?.data?.facility_types)
        } else {
          // setcluster([])
        }
      } catch (e) {
        console.error(e)
      }
    }
    GetClusterAndType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filteredLocations = locations?.filter((l: any) => {
    const statusFilterCondition =
      statusFilter === 'all' || l?.resource?.status === statusFilter
    const nameFilterCondition =
      !searchText ||
      l?.resource?.name?.toLowerCase()?.startsWith(searchText?.toLowerCase())
    return statusFilterCondition && nameFilterCondition
  })
  filteredLocations?.sort?.((a: any, b: any) =>
    a?.resource?.name?.toLowerCase() < b.resource?.name?.toLowerCase() ? -1 : 1
  )
  const locationIds = filteredLocations?.map((d: any) => d?.resource?.id)
  useEffect(() => {
    if (locationIds?.length > 0) {
      const locationIdsString = locationIds?.join(',')

      startSxpProxy(
        REGISTRATION_PROJECT_ID,
        UserManagementIntent?.fetchProvenance,
        {
          patientIds: locationIdsString,
        }
      )
        ?.then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((error) => {
          toast.success(error)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div
        className='button-enclosure mtminus8'
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          border: 'none',
        }}
      >
        <div className='filterandtext'>
          <div className='membershipText'>{locationtext}</div>
        </div>
        <div className='filter-container'>
          <div className='mr10'>
            <select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              className='h30 doctor-status'
            >
              <option value='active'>Active</option>
              <option value='inactive'>Inactive</option>
            </select>
          </div>
          <input
            type='search'
            className='admin-search'
            value={searchText}
            onChange={({ target: { value } }) => setSearchText(value)}
            placeholder='Search by Name'
          />
          <button className='btn btn-primaryBtn ml10 ' onClick={handleCreate}>
            {create}
          </button>
        </div>
      </div>
      <div
        className='data-table table-fixed admin-table-table-container'
        style={{ overflowY: 'auto', maxHeight: '500px' }}
      >
        <table className='data-table table-fixed admin-table relative'>
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr>
              <th className='table-w-5'>Sl&nbsp;No</th>
              <th className='table-w-3'></th>
              <th>Prefix</th>
              <th className='table-w-'>Operating Unit</th>
              <th>Type</th>
              <th className='table-w-25'>{locationtableHeadings?.Address}</th>
              <th className='table-w-12'>GSTIN No.</th>
              <th className='table-w-12'>DL No.</th>
              <th>{locationtableHeadings?.Createdon}</th>
              <th>{locationtableHeadings?.UpdatedOn}</th>
              <th>Status</th>
              <th className='table-w-5'>Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredLocations?.length ? (
              filteredLocations?.map((l, idx) => (
                <tr key={l.resource.id}>
                  <td>{idx + 1}</td>
                  <td>
                    <input
                      type='checkbox'
                      checked={checkedItems[idx] || false}
                      onChange={() => handleCheckboxChange(idx)}
                    />
                  </td>
                  <td>{l?.resource?.identifier?.[1]?.value}</td>
                  <td>{l?.resource?.name}</td>
                  <td>
                    {type?.find(
                      (t: any) =>
                        t?.id == l?.resource?.type?.[0]?.coding?.[2]?.display
                    )?.name || l?.resource?.type?.[0]?.coding?.[2]?.display}
                  </td>

                  <td>{l?.resource?.address?.line?.join(',') ?? '-'}</td>
                  <td className='text-center'>
                    {l?.resource?.type?.[0]?.coding?.[1]?.display}
                  </td>
                  <td className='text-center'>
                    {l?.resource?.type?.[0]?.coding?.[0]?.display}
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={
                        provenances?.[l?.resource?.id ?? '']?.createdOn ?? ''
                      }
                      name={
                        provenances?.[l?.resource?.id ?? '']?.createdBy ?? 'NA'
                      }
                      mode={createprovenance}
                    />
                  </td>
                  <td className='text-center'>
                    <ProvenanceView
                      date={
                        provenances?.[l?.resource?.id ?? '']?.updatedOn ??
                        provenances?.[l?.resource?.id ?? '']?.createdOn ??
                        ''
                      }
                      name={
                        provenances?.[l?.resource?.id ?? '']?.updatedBy ??
                        provenances?.[l?.resource?.id ?? '']?.createdBy ??
                        'NA'
                      }
                      mode={update}
                    />
                  </td>
                  <td>
                    {!checkedItems[idx] ? (
                      <div>{l?.resource?.status}</div>
                    ) : (
                      <div>
                        <select
                          value={l?.resource?.status}
                          onChange={() => handleStatusChange(idx)}
                        >
                          <option value='active'>active</option>
                          <option value='inactive'>inactive</option>
                        </select>
                      </div>
                    )}
                  </td>
                  <td>
                    <EditButton
                      title='Edit Location'
                      onEdit={() => handleEdit(l?.resource?.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} className='text-center'>
                  No Locations Found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Locations

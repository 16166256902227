import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../app/hooks'
import { selectSelectedLocationId } from '../../location/locationSlice'
import { toast } from 'react-toastify'
import { startSxpProxy } from '../../../utils/api'
import { toastOptions } from '../../lms/models'
import { getAgeInYears } from '../../../utils/dateUtils'
import { MembershipType, PackagePatient } from '../../membership/models'
import { useNavigate } from 'react-router-dom'
import {
  ADMIN_PROJECT_ID,
  CHAT_PROJECT_ID,
  PACKAGE_PROJECT_ID,
} from '../../../utils/constants'
import {
  createPackages,
  noMembersFound,
  remove,
} from '../../Radiology/Constants/constants'
import ToastMessage from '../../lms/components/ToastMessage'
import Loading from '../../../form/fields/Loading'
import { odooPharmacyLink } from '../../appointments/models'

type packagesProps = {
  packages: PackagePatient[]
  onMemberUnSelect: (val: string) => void
}

const ListOfPackages = ({ packages, onMemberUnSelect }: packagesProps) => {
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>('')
  const [packageTypes, setPackageTypes] = useState<MembershipType[]>([])
  const [primaryIndex, setPrimaryIndex] = useState(-1)
  const location = useAppSelector(selectSelectedLocationId)
  const navigate = useNavigate()

  useEffect(() => {
    startSxpProxy(PACKAGE_PROJECT_ID, 'getAllPackages', { location })
      .then((data: any) => {
        setPackageTypes(data?.data)
      })
      .catch((err) => {
        console.log(err, 'err')
      })
  }, [location])

  const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(e.target.value)
  }

  const handleUnselect = (id: string, idx: number) => {
    if (idx === primaryIndex) {
      setPrimaryIndex(-1)
    }
    onMemberUnSelect(id)
  }
  const handleCheckboxChange = (id: number) => {
    if (id !== primaryIndex) {
      startSxpProxy(CHAT_PROJECT_ID, 'checkRegAndRestrict', {
        patientIdentifier: packages[id].uhid,
      })
        .then(() => {
          setPrimaryIndex(id)
        })
        .catch((err) => {
          console.log(err, 'err')
          toast(
            <ToastMessage
              message={
                err?.response?.data?.error ??
                'change the registration type to full registration'
              }
            />,
            {
              ...toastOptions,
              type: 'error',
            }
          )
        })
    } else {
      setPrimaryIndex(-1)
    }
  }
  const createMember = () => {
    if (
      selectedOption &&
      packages.length > 0 &&
      primaryIndex >= 0 &&
      !loading
    ) {
      setLoading(true)
      const associatedUhids = packages[0]?.uhid
      const selectedType = packageTypes.find(
        (mt) => mt.id === parseInt(selectedOption)
      )
      let productName = ''
      if (selectedType?.description_sale === false) {
        productName = selectedType?.name || ''
      } else if (typeof selectedType?.description_sale === 'string') {
        if (selectedType?.name) {
          productName = `${selectedType.name}\n${selectedType.description_sale}`
        } else {
          productName = selectedType.description_sale
        }
      } else {
        productName = selectedType?.name || ''
      }
      const state = {
        selectedLocationId: location,
        identifier: associatedUhids,
        odooProductId: selectedType?.id,
        productName: productName,
      }
      startSxpProxy(PACKAGE_PROJECT_ID, 'buyPackage', state)
        .then(async (data: any) => {
          toast(<ToastMessage message={'Package Details Saved'} />, {
            ...toastOptions,
            type: 'success',
          })
          navigate('/package')
          setTimeout(() => {
            const id = data?.data?.result
            startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, { invoiceId: id })
              .then((data) => {
                const url = data?.data?.auth_link
                window.open(url, '_blank', 'noopener,noreferrer')
              })
              .catch((err) => console.error(err, 'err'))
          }, 2000)
        })
        .catch((error) => {
          console.log(error, 'error')
          const errorMessage =
            error?.response?.data?.message ?? 'Failed To Create Package'
          toast(<ToastMessage message={errorMessage} />, {
            ...toastOptions,
            type: 'error',
          })
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <>
      <div className='patient' style={{ marginBottom: 20 }}>
        {loading && <Loading />}
        <p className='admin-header'>Package List:</p>
        <table className='data-table table-fixed admin-table'>
          <thead style={{ position: 'sticky', top: '0px', zIndex: 9 }}>
            <tr>
              <th className='table-w-4' style={{ padding: '6px' }}>
                Sl&nbsp;No
              </th>
              <th className='table-w-3'></th>
              <th className='text-left table-w-15'>UHID</th>
              <th className='text-left table-w-30'>Name</th>
              <th className='text-center table-w-6'>Gender</th>
              <th className='text-center table-w-8'>Age</th>
              <th className='text-center table-w-6'>Actions</th>
            </tr>
          </thead>
          <tbody>
            {packages.length > 0 && (
              <tr className='memberId' key={packages[0].id}>
                <td className='text-center'>1</td>
                <td>
                  <input
                    type='checkbox'
                    checked={primaryIndex === 0}
                    onChange={() => handleCheckboxChange(0)}
                  />
                </td>
                <td>{packages[0].uhid || packages[0].thopId}</td>
                <td>
                  <button className='lms-btn'>{packages[0].name}</button>
                </td>
                <td className='text-center'>{packages[0].gender}</td>
                <td className='text-center'>
                  {getAgeInYears(packages[0].dob) ?? '-'}
                </td>
                <td className='text-center'>
                  <button
                    className='typeButton'
                    onClick={() => handleUnselect(packages[0].id, 0)}
                  >
                    {remove}
                  </button>
                </td>
              </tr>
            )}
            {packages.length === 0 && (
              <tr>
                <td colSpan={6} className='text-center'>
                  {noMembersFound}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='flexDivs'>
        <select
          className='doctorSelected'
          value={selectedOption}
          onChange={handleSelect}
        >
          <option value=''>Select an option</option>
          {packageTypes.map((option) => (
            <option key={option.id} value={option.id}>
              {option.name}
            </option>
          ))}
        </select>
        <button
          className={
            !selectedOption || packages.length === 0 || primaryIndex < 0
              ? 'disabled-button'
              : 'createbutton'
          }
          disabled={
            !selectedOption || packages.length === 0 || primaryIndex < 0
          }
          onClick={createMember}
        >
          {loading ? 'Creating...' : createPackages}
        </button>
      </div>
    </>
  )
}

export default ListOfPackages

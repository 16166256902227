import React, { useEffect, useRef, useState } from 'react'
import { CHAT_PROJECT_ID, IPD_PROJECT_ID } from '../../../utils/constants'
import { useBeds } from '../layout/useBeds'
import { allocateApi, allocation, tableHeadText } from '../modals'
import '../bms.scss'
import { startSxpProxy } from '../../../utils/api'
import { getTotalAge } from '../../../utils/dateUtils'
import { ipVisitApi } from '../models'
import { toast } from 'react-toastify'

const IpdVisitForm = () => {
  const [locationName, setLocationName] = useState('')
  const locations = useBeds()
  const [patientUhid, setPatientUhid] = useState('')
  const [patientBed, setPatientBed] = useState<any>({
    patientUhid: '',
    patientName: '',
    patientAge: '',
    status: '',
    patientMobile: '',
    bedNo: '',
    location: '',
    admissionDate: '',
    doctor: '',
    department: '',
    dateOfVisit: '',
    bedPatientId: '',
    bedId: '',
    patientId: '',
  })
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])
  const handlePatientSearch = (uhid: string, location: string) => {
    startSxpProxy(IPD_PROJECT_ID, ipVisitApi.getPatientByLocationAndStatus, {
      location: location,
      patientUhid: uhid,
      status: 'ASSIGNED',
    }).then((data: any) => {
      const node = data?.data?.bed_patient_assignment_map
      if (node.length > 0) {
        startSxpProxy(
          CHAT_PROJECT_ID,
          allocateApi?.getPatientDetailsByIdentifier,
          { patientIdentifier: node[0]?.patient_id }
        ).then((data: any) => {
          const patientRegisteredId = data?.data?.entry?.[0]?.resource?.id
          const age = getTotalAge(
            data?.data?.entry?.[0]?.resource?.birthDate ?? ''
          )
          const mobile = data?.data?.entry?.[0]?.resource?.telecom?.find(
            (prt: any) => prt?.use === 'mobile'
          )?.value
          setPatientBed({
            patientUhid: node[0]?.patient_id ?? '',
            patientName: node[0]?.patient_name ?? '',
            patientId: patientRegisteredId ?? '',
            patientAge: age ?? '',
            patientMobile: mobile ?? '',
            status: 'Admitted',
            bedPatientId: node?.[0]?.id ?? '',
            bedId: node?.[0]?.bed_id ?? '',
            location: node?.[0]?.bed?.location_id ?? '',
            bedNo: node?.[0]?.bed?.bed_number ?? '',
            admissionDate: node?.[0]?.bed?.admission_date ?? '',
          })
        })
      } else {
        setPatientBed({
          patientUhid: '',
          patientName: '',
          patientAge: '',
          status: '',
          patientMobile: '',
          bedNo: '',
          location: '',
          admissionDate: '',
          doctor: '',
          department: '',
          dateOfVisit: '',
          bedId: '',
          bedPatientId: '',
          patientId: '',
        })
        toast.error(
          'check the patient is in current location or check the patient allocated in any bed'
        )
      }
    })
  }

  return (
    <>
      <div className='bed-visit'>
        <div>
          <select
            className='location-visit'
            name='location'
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
          >
            <option value=''>Location</option>
            {locations.map((op: any, i: number) => (
              <option key={i} value={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
        <input
          type='input'
          name='patientUhid'
          value={patientUhid}
          onChange={(e) => setPatientUhid(e.target.value)}
          placeholder={allocation?.patientHolder}
          className='patient-input'
        />
        <button
          disabled={locationName.length === 0 || patientUhid.length < 9}
          onClick={() => handlePatientSearch(patientUhid, locationName)}
        >
          Search
        </button>
      </div>
      <div className='bottom20'>
        <div className='bedTableHead' ref={patientTableContainerRef}>
          <table className='data-table table-fixed admin-table relative'>
            <thead className='sticky'>
              <tr>
                <th className='text-center'>{tableHeadText.uhid}</th>
                <th className='text-left'>{tableHeadText.name}</th>
                <th className='text-center'>{tableHeadText.mobileNo}</th>
                <th className='text-center'>{tableHeadText.bedNo}</th>
                <th className='text-center'>{allocation.locationOne}</th>
                <th className='text-center'>{tableHeadText.status}</th>
                <th className='text-center'>{tableHeadText.admission}</th>
              </tr>
            </thead>
            <tbody>
              <tr key={patientBed?.patientUhid}>
                <td>{patientBed?.patientUhid}</td>
                <td>{patientBed?.patientName}</td>
                <td>{patientBed?.patientMobile}</td>
                <td>{patientBed?.bedNo}</td>
                <td>{patientBed?.location}</td>
                <td>{patientBed?.status}</td>
                <td>{patientBed?.admissionDate}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export default IpdVisitForm

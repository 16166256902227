import SxpForm from '../../../../form/SxpForm'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsItem, selectedLmsItemId } from '../../lmsSlice'
import RenderOnRole from '../../../../components/RenderOnRole'
import { LMS_ADMIN_WRITE } from '../../../../utils/roles'
import {
  selectLocationById,
  selectSelectedLocationId,
} from '../../../location/locationSlice'
import CreateUpdatePanelForm from './CreateUpdatePanelForm'
import RadiologyPackagesForm from './RadiologyPackagesForm'
import RadiologyTestForm from './RadiologyTestForm'
import { adminText } from '../../models'

type Props = {
  onJourneyEnd: () => void
  projectId: string | undefined
}

const AdminForm = ({ onJourneyEnd, projectId }: Props) => {
  const locations = useAppSelector(selectSelectedLocationId)
  const location = useAppSelector((state) =>
    selectLocationById(locations, state)
  )
  const locationId = location?.resource.name ?? ''
  const item = useAppSelector(selectedLmsItem)
  const itemId = useAppSelector(selectedLmsItemId)
  let intent: string
  const state: any = {}
  switch (item) {
    case 'sample':
      intent = 'createUpdateSample'
      state.sampleId = itemId
      break
    case 'panel':
      intent = 'createUpdatePanel'
      state.panelId = itemId
      break
    case 'labTest':
      intent = 'createUpdateLabTests'
      state.labTestId = itemId
      break
    case 'department':
      intent = 'createUpdateDepartment'
      state.departmentId = itemId
      break
    case 'unitType':
      intent = 'createUpdateTestUnitType'
      state.testUnitTypeId = itemId
      break
    case 'resultType':
      intent = 'createUpdateTestResultType'
      state.resultTypeId = itemId
      break
    case 'method':
      intent = 'createUpdateTestMethod'
      state.testMethodId = itemId
      break
    case 'testRange':
      intent = 'createOrUpdateTestResultLimit'
      state.testResultLimitId = itemId
      break
    case 'radiologyCategory':
      intent = 'createRadiologySample'
      state.radiologySampleId = itemId
      break
    case 'radiologyTest':
      intent = 'createRadiologyLabTests'
      state.radiologyTestId = itemId
      break
    case 'radiologyPackages':
      intent = 'createRadiologyPackages'
      state.radiologyPackageId = itemId
      break
    case 'ward':
      intent = 'createUpdateWardDetails'
      state.wardId = itemId
      state.locationId = locationId
      break
    case 'rooms':
      intent = 'createUpdateRoomDetails'
      state.roomId = itemId
      break
    case 'beds':
      intent = 'createUpdateBedDetails'
      state.bedId = itemId
      break
    default:
      intent = ''
  }
  const renderFormContent = () => {
    switch (item) {
      case adminText?.panel:
        return <CreateUpdatePanelForm />
      case adminText?.radiologyPackages:
        return <RadiologyPackagesForm />
      case adminText?.radiologyTest:
        return <RadiologyTestForm />

      default:
        return (
          <RenderOnRole
            roles={[LMS_ADMIN_WRITE]}
            element={
              <SxpForm
                project={projectId}
                intent={intent}
                journeyState={state}
                isDynamic
                onJourneyEnd={onJourneyEnd}
              />
            }
          />
        )
    }
  }

  return <>{renderFormContent()}</>
}

export default AdminForm

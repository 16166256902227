import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../app/store'
import { FormMode } from '../lms/models'

export type RadiologySliceData = {
  item: string
  itemId: string
  formMode: FormMode
  activeTab: string
  searchParam: string
  dateFilter: string
  adminSearch: string
  categoryFilter: string
  showBack: string
  radiologyPatientCheck: boolean
}

const initialState: RadiologySliceData = {
  item: '',
  itemId: '',
  formMode: 'none',
  activeTab: 'testing',
  searchParam: '',
  dateFilter: new Date().toISOString().slice(0, 10),
  adminSearch: '',
  categoryFilter: '',
  showBack: '',
  radiologyPatientCheck: false,
}

const radiologySlice = createSlice({
  name: 'radiology',
  initialState,
  reducers: {
    setRadiologyItem: (state, action: PayloadAction<string>) => {
      state.item = action.payload
    },
    setRadiologyItemId: (state, action: PayloadAction<string>) => {
      state.itemId = action.payload
    },
    setRadiologyFormMode: (state, action: PayloadAction<FormMode>) => {
      state.formMode = action.payload
    },
    setRadiologyCategoryMode: (state, action: PayloadAction<string>) => {
      state.categoryFilter = action.payload
    },

    setRadiologyActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload
    },
    setRadiologySearchParam: (state, action: PayloadAction<string>) => {
      state.searchParam = action.payload
    },
    setRadiologyDateFilter: (state, action: PayloadAction<string>) => {
      state.dateFilter = action.payload
    },
    setRadiologyAdminSearch: (state, action: PayloadAction<string>) => {
      state.adminSearch = action.payload
    },
    setRadiologyShowBack: (state, action: PayloadAction<string>) => {
      state.showBack = action.payload
    },
    setRadiologyPatientCheck: (state, action: PayloadAction<boolean>) => {
      state.radiologyPatientCheck = action.payload
    },
  },
})

export const {
  setRadiologyItem,
  setRadiologyItemId,
  setRadiologyFormMode,
  setRadiologyActiveTab,
  setRadiologySearchParam,
  setRadiologyDateFilter,
  setRadiologyAdminSearch,
  setRadiologyCategoryMode,
  setRadiologyShowBack,
  setRadiologyPatientCheck,
} = radiologySlice.actions

export const selectedRadiologyItem = (state: RootState) => state.radiology.item
export const selectedRadiologyItemId = (state: RootState) =>
  state.radiology.itemId
export const selectedRadiologyMode = (state: RootState) =>
  state.radiology.formMode
export const selectedRadiologyActiveTab = (state: RootState) =>
  state.radiology.activeTab
export const selectedRadiologySearchParam = (state: RootState) =>
  state.radiology.searchParam
export const selectedRadiologyDateFilter = (state: RootState) =>
  state.radiology.dateFilter
export const selectedRadiologyAdminSearch = (state: RootState) =>
  state.radiology.adminSearch
export const selectedRadiologyCategoryMode = (state: RootState) =>
  state.radiology.categoryFilter
export const selectedRadiologyShowBack = (state: RootState) =>
  state.radiology.showBack
export const selectedRadiologyPatientCheck = (state: RootState) =>
  state.radiology.radiologyPatientCheck

export default radiologySlice.reducer

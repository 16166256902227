import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import { dialogMessages, radiologyButtons } from '../Constants/constants'

type ReplaceDocumentDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const ReplaceDocumentDialog = ({
  open,
  onClose,
  onConfirm,
}: ReplaceDocumentDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby='replace-confirmation-dialog'
    >
      <DialogTitle id='replace-confirmation-dialog'>
        {dialogMessages?.replaceDocument}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {dialogMessages?.documentUploaded}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>
          {radiologyButtons?.no}
        </Button>
        <Button onClick={onConfirm} color='primary' autoFocus>
          {radiologyButtons?.yes}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ReplaceDocumentDialog

import { Outlet } from 'react-router-dom'
import RenderOnRole from '../../components/RenderOnRole'
import { RADIOLOGY_WRITE } from '../../utils/roles'
import { Box, useTheme } from '@mui/material'
import './radiology.scss'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
const styles = () => ({
  radiologyDashboard: {
    height: '87%',
    width: '100%',
    paddingLeft: '1%',
    paddingRight: '1%',
    paddingTop: '0.5%',
  },
})
const Radiology = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  return (
    <RenderOnRole
      roles={[RADIOLOGY_WRITE]}
      element={
        <Box className={classes?.radiologyDashboard}>
          <Outlet />
        </Box>
      }
    />
  )
}

export default Radiology

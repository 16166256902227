import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { startSxpProxy } from '../../utils/api'
import { ADMIN_PROJECT_ID } from '../../utils/constants'
import KeycloakService from '../../utils/keycloakService'
import Asterisk from '../../components/Asterisk'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { formValues, OrganizationIntent } from '../administration/constants'
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  useTheme,
} from '@mui/material'
import {
  fieldRequriedError,
  phoneRegExp,
  phoneFieldError,
  emailFieldError,
} from '../ipRegistration/Constants'
import { partnerForm } from '../Radiology/Constants/constants'
import { radiologyEndPoint } from '../Radiology/RadiologyEndPoints'
import { radiologyColor } from '../Radiology/Utils/RadiologyColors'
type PartnerProps = {
  type: string
  name: string
  phoneNumber: string
  email: string
  status: string
  fhirId: string
  location: string
  address: string
  govId: string
  department: string
  designation: string
  isLab: boolean
  isRadiology: boolean
  isVendorRelate: boolean
  isCustomerRelate: boolean
  odoo_partner_id: string
}
const styles = () => ({
  partnerRegisterFormContainer: {
    width: '100%',
    height: '100%',
    padding: '0.6% 3% 3% 3%',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      fontWeight: 500,
    },
    '& .css-1hpqosy-MuiFormControlLabel-root': {
      marginLeft: '5px !important',
      marginRight: '5px !important',
    },
  },
  partnerRegisterFormRow: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    rowGap: 20,
  },
  partnerRegisterFormFiield: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
  },
  partnerRegisterHeading: {
    fontSize: '14px',
    fontWeight: '600',
    color: radiologyColor?.deepBlue,
    textDecoration: 'underline',
    textAlign: 'center',
  },
  partnerRegisterFormActions: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  partnerRegisterFormColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 20,
    marginBottom: 30,
  },
})

const PartnerForm = ({ partner }: { partner?: any }) => {
  const navigate = useNavigate()
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const initialFormState = {
    type: 'b2bPartner',
    name: '',
    phoneNumber: '',
    email: '',
    govId: '',
    department: '',
    designation: '',
    isLab: false,
    isRadiology: false,
    isVendorRelate: false,
    isCustomerRelate: false,
    odoo_partner_id: '',
  }

  const [partnerData, setPartnerData] = useState<PartnerProps>(
    partner ?? initialFormState
  )

  const { values, errors, touched, handleBlur, handleChange, submitForm } =
    useFormik<PartnerProps>({
      initialValues: partnerData,
      validationSchema: Yup.object({
        name: Yup.string().required(fieldRequriedError),
        phoneNumber: Yup.string()
          .matches(phoneRegExp, phoneFieldError)
          .required(fieldRequriedError),
        email: Yup.string().email(emailFieldError),
      }),
      onSubmit: (values) => {
        handleRegistrationSubmit(values)
      },
      enableReinitialize: true,
    })
  const handleRegistrationSubmit = async (data: Record<string, any>) => {
    const {
      name,
      phoneNumber,
      email,
      fhirId,
      id,
      isLab,
      isRadiology,
      isVendorRelate,
      isCustomerRelate,
    } = data

    const formatted = {
      type: 'b2bPartner',
      name: name,
      id: id,
      mobile_number: phoneNumber,
      email: email,
      fhir_id: fhirId,
      created_by: KeycloakService.getUsername(),
      ...(id ? {} : { created_by: KeycloakService?.getUsername() }),
      updated_by: KeycloakService?.getUsername(),
      ...(id && { id: id }),
      status: id ? data.status : formValues?.active,
      ...(id && { odoo_partnerId: values?.odoo_partner_id }),
      ...(id && { date_changed: new Date()?.toISOString() }),
      isLab: isLab,
      isRadiology: isRadiology,
      isCustomerRelate: isCustomerRelate,
      isVendorRelate: isVendorRelate,
    }

    const state = {
      organization: formatted,
    }
    const operation = id
      ? OrganizationIntent?.editOrganization
      : OrganizationIntent?.createOrganization
    const statebody = id ? formatted : state
    startSxpProxy(ADMIN_PROJECT_ID, operation, statebody)
      ?.then((data) => {
        toast?.success(data?.data?.message)
        navigate(radiologyEndPoint?.referredOut)
      })
      .catch(() => {
        toast.error(data?.data?.message)
      })
  }
  useEffect(() => {
    if (partner) {
      setPartnerData(partner)
    }
  }, [partner])

  return (
    <>
      <div className={classes?.partnerRegisterFormContainer}>
        <form>
          <div className={classes?.partnerRegisterFormColumn}>
            <div className={classes?.partnerRegisterHeading}>{partnerForm}</div>
            <Grid
              container
              spacing={1}
              className={classes?.partnerRegisterFormRow}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>
                  Name
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='name'
                  placeholder='Enter Name'
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched?.name && Boolean(errors?.name)}
                  helperText={touched?.name && errors?.name}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>
                  Phone Number
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='phoneNumber'
                  placeholder='Enter Phone Number'
                  value={values.phoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.phoneNumber && Boolean(errors.phoneNumber)}
                  helperText={touched.phoneNumber && errors.phoneNumber}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>Email Id</label>
                <TextField
                  size='small'
                  name='email'
                  placeholder='Enter Email'
                  value={values?.email}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>Department</label>
                <TextField
                  size='small'
                  name='department'
                  placeholder='Department'
                  value={values?.department}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>Designation</label>
                <TextField
                  size='small'
                  name='designation'
                  placeholder='Designation'
                  value={values?.designation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>Location</label>
                <TextField
                  size='small'
                  name='location'
                  placeholder='Location'
                  value={values?.location}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
              {partner?.id && (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={3}
                  lg={3}
                  xl={3}
                  className={classes?.partnerRegisterFormFiield}
                >
                  <label>Status</label>
                  <TextField
                    size='small'
                    select
                    name='status'
                    value={values?.status}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem key='active' value='active'>
                      Active
                    </MenuItem>
                    <MenuItem key='inactive' value='inactive'>
                      InActive
                    </MenuItem>
                  </TextField>
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>Lab/Radiology Type</label>
                <div className='partner-container'>
                  <FormControlLabel
                    labelPlacement='top'
                    label='Lab'
                    control={
                      <Checkbox
                        size='small'
                        checked={values.isLab}
                        onChange={handleChange}
                        name='isLab'
                      />
                    }
                  />
                  <FormControlLabel
                    labelPlacement='top'
                    label='Radiology'
                    control={
                      <Checkbox
                        size='small'
                        checked={values.isRadiology}
                        onChange={handleChange}
                        name='isRadiology'
                      />
                    }
                  />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.partnerRegisterFormFiield}
              >
                <label>Customer/Vendor Relate</label>
                <div className='partner-container'>
                  <FormControlLabel
                    labelPlacement='top'
                    label='isVendor'
                    control={
                      <Checkbox
                        size='small'
                        checked={values?.isVendorRelate}
                        onChange={handleChange}
                        name='isVendorRelate'
                      />
                    }
                  />
                  <FormControlLabel
                    labelPlacement='top'
                    label='isCustomer'
                    control={
                      <Checkbox
                        size='small'
                        checked={values?.isCustomerRelate}
                        onChange={handleChange}
                        name='isCustomerRelate'
                      />
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes?.partnerRegisterFormActions}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              className='partner-submit-btn'
              onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}
export default PartnerForm

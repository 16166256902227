import { useCallback, useState, useEffect } from 'react'
import { sampleRow } from '../../../labTests/models'
import { Button, IconButton, TextField } from '@mui/material'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import { useCategories } from '../../../Radiology/components/RadiologyModel/useCategories'
import { startSxpProxy } from '../../../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../../../utils/constants'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { toast } from 'react-toastify'
import KeycloakService from '../../../../utils/keycloakService'
import { PACKAGES_WRITE } from '../../../../utils/roles'
import { selectedLmsItemId, setLmsFormMode } from '../../lmsSlice'
import { toastOptions } from '../../models'
import ToastMessage from '../ToastMessage'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { PackageRow } from '../../../Radiology/models'
import {
  alertMessages,
  error,
  formNameValues,
  radiologyIntents,
  success,
} from '../../../Radiology/Constants/constants'
type RadiologyPackageItem = {
  name?: string
  price?: string
  from?: string
  till?: string
}
const initialNewRow = {
  mode: { value: 'test', label: 'Test' },
  requestedBy: null,
  sampleName: null,
  testName: null,
  origin: null,
}

const RadiologyPackagesForm = () => {
  const [newRow, setNewRow] = useState<PackageRow>({
    mode: { value: 'test', label: 'Test' },
    requestedBy: null,
    sampleName: null,
    testName: null,
    origin: null,
  })
  const [item, setItem] = useState<RadiologyPackageItem>({})
  const [rows, setRows] = useState<PackageRow[]>([])
  const [testNameOptions, setTestNameOptions] = useState<
    { label: string; value: number; panels: any }[]
  >([])
  const [testAdded, setTestAdded] = useState(false)
  const packageId = useAppSelector(selectedLmsItemId)
  const dispatch = useAppDispatch()
  const getPackageById = () => {
    const intent = radiologyIntents?.getRadiologyPackageById
    const state = { id: packageId }
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
      ?.then((data) => {
        const details = data?.data?.radiology_packages_by_pk
        const pkg: RadiologyPackageItem = {
          name: details?.name,
          price: details?.price,
          from: details?.active_from?.slice(0, 10),
          till: details?.active_till?.slice(0, 10),
        }
        setItem(pkg)
        const tests = details?.radiology_lab_tests?.map((test: any) => {
          return {
            sampleName: { label: test?.sampleName, value: test?.sampleId },
            testName: { label: test?.testName, value: test?.testId },
          }
        })
        setRows(tests)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const handleInputChange = (e: any) => {
    const { name, value } = e.target
    setItem({
      ...item,
      [name]: value,
    })
  }
  const handleDateChange = (name: string, date: any) => {
    if (!date) return

    const isFrom = name === formNameValues?.from
    const isTill = name === formNameValues?.till

    if (
      (isFrom && item?.till && date >= new Date(item?.till)) ||
      (isTill && item?.from && date <= new Date(item?.from))
    ) {
      const message = isFrom
        ? alertMessages?.packageFrom
        : alertMessages?.packageTill
      toast.warning(message)
      return
    }

    setItem({
      ...item,
      [isFrom ? formNameValues?.from : formNameValues?.till]: date,
    })
  }
  const samples = useCategories()
  const sampleOptions: sampleRow[] = samples?.map((sample) => ({
    label: sample?.name,
    value: sample?.id,
  }))
  const handleSampleNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      sampleName: selectedOption,
      testName: null,
    }))
    if (!selectedOption?.value) return
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getAllRadiologyTestsBySampleId,
      {
        radiologySampleId: parseInt(selectedOption?.value),
      }
    )
      .then((response) => {
        const testNames =
          response?.data?.radiology_sample?.[0]?.radiology_lab_tests?.map(
            (test: any) => ({
              label: test?.name,
              value: String(test?.id),
              sampleId: test?.sample_id,
              sampleName: test?.radiology_sample?.name,
            })
          ) || []
        const selectedTestLabels = rows?.map((row) => row?.testName?.label)
        const filteredTestNames = testNames?.filter(
          (test: any) => !selectedTestLabels?.includes(test?.label)
        )
        setTestNameOptions(filteredTestNames)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }
  const executeFunction = () => {
    const state = {}
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getRadiologyTestsAPI,
      state
    )
      .then((data) => {
        const panelData: any = data?.data?.radiology_lab_test ?? []
        const tests = panelData?.map((test: any) => ({
          label: test?.name,
          value: String(test?.id),
          sampleId: test?.sample_id,
          sampleName: test?.radiology_sample?.name,
        }))
        const selectedTestLabels = rows?.map((row) => row?.testName?.label)
        const filteredTests = tests?.filter(
          (test: any) => !selectedTestLabels?.includes(test?.label)
        )
        setTestNameOptions(filteredTests)
      })
      .catch((error) => {
        console.error('Error fetching test names:', error)
        setTestNameOptions([])
      })
  }
  const handleTestNameChange = (selectedOption: any) => {
    setNewRow((prev) => ({
      ...prev,
      testName: { label: selectedOption?.label, value: selectedOption?.value },
      sampleName: {
        label: selectedOption?.sampleName,
        value: selectedOption?.sampleId,
      },
    }))
  }
  const handleSaveRow = useCallback(() => {
    setRows((prevRows) => [...prevRows, newRow])
    setNewRow(initialNewRow)
    setTestAdded((prev) => !prev)
  }, [newRow])
  const handleDeleteRow = (index: any) => {
    setRows((prevRows) => prevRows?.filter((_, i) => i !== index))
    setNewRow(initialNewRow)
    setTestAdded((prev) => !prev)
  }

  const handleSave = () => {
    if (!KeycloakService.hasRole([PACKAGES_WRITE])) {
      return
    }
    if (!item?.name) {
      toast(<ToastMessage message={alertMessages?.validName} />, {
        ...toastOptions,
        type: error,
      })
      return
    }
    if (!item?.price) {
      toast(<ToastMessage message={alertMessages?.validPrice} />, {
        ...toastOptions,
        type: error,
      })
      return
    }
    if (!item?.from) {
      toast(<ToastMessage message={alertMessages?.validDate} />, {
        ...toastOptions,
        type: error,
      })
      return
    }
    if (!item?.till) {
      toast(<ToastMessage message={alertMessages?.validDate} />, {
        ...toastOptions,
        type: error,
      })
      return
    }
    if (rows?.length === 0) {
      toast(<ToastMessage message={alertMessages?.addTest} />, {
        ...toastOptions,
        type: error,
      })
      return
    }
    const labTests = rows?.map((row: any) => {
      const sampleName = row?.sampleName?.label
      const sampleId = row?.sampleName?.value
      const testName = row?.testName?.label
      const testId = row?.testName?.value
      return { sampleName, sampleId, testName, testId }
    })
    const testName = labTests
      ?.map((panel: any) => panel?.testName || '')
      ?.filter((description) => description)
      .join(',')
    const data: any = {
      name: item?.name,
      price: parseInt(item?.price),
      active_from: item?.from,
      active_till: item?.till,
      radiology_lab_tests: labTests,
      updated_by: KeycloakService.getFullname(),
      updated_at: new Date().toISOString(),
    }
    if (!packageId) {
      data.created_by = KeycloakService.getFullname()
    }
    const intent = packageId
      ? radiologyIntents?.updateRadiologyPackage
      : radiologyIntents?.createRadiologyPackage
    const state: any = {
      packageName: item?.name,
      test_name: testName,
      list_price: parseInt(item?.price),
      radiologyPackage: data,
    }
    if (packageId) {
      state.id = packageId
    }
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
      ?.then(() => {
        dispatch(setLmsFormMode('none'))
        toast(<ToastMessage message={alertMessages?.savePackage} />, {
          ...toastOptions,
          type: success,
        })
      })
      .catch((err) => {
        console.log(err)
        toast(<ToastMessage message={alertMessages?.errorPackage} />, {
          ...toastOptions,
          type: error,
        })
      })
  }
  useEffect(() => {
    if (packageId) {
      getPackageById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [packageId])
  useEffect(() => {
    executeFunction()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testAdded])
  return (
    <div className='radiology-package-form-container'>
      <div className='createlaborder-table'>
        <div className='laborder-sub-btn'>
          <Button onClick={handleSave} size='small' variant='contained'>
            Submit
          </Button>
        </div>
        <table className='data-table admin-table'>
          <thead className='package-head-content'>
            <tr>
              <th className='th-sample'>Package Name</th>
              <th className='th-sample'>Price</th>
              <th className='th-sample'>Active From</th>
              <th className='th-sample'>Active Till</th>
              <th className='th-sample'>Sample Name</th>
              <th className='th-test'>Test Name</th>
              <th className='th-action'>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <TextField
                  type='text'
                  placeholder='Enter Name'
                  name='name'
                  value={item?.name || ''}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <TextField
                  type='number'
                  placeholder='Enter Price'
                  name='price'
                  value={item?.price || ''}
                  onChange={handleInputChange}
                />
              </td>
              <td>
                <DatePicker
                  className='datePickerUI'
                  placeholderText='DD-MM-YYYY'
                  selected={item?.from ? new Date(item?.from) : null}
                  onChange={(date) => handleDateChange('from', date)}
                  dateFormat='dd-MM-yyyy'
                />
              </td>
              <td>
                <DatePicker
                  className='datePickerUI'
                  placeholderText='DD-MM-YYYY'
                  selected={item?.till ? new Date(item?.till) : null}
                  onChange={(date) => handleDateChange('till', date)}
                  dateFormat='dd-MM-yyyy'
                />
              </td>
              <td>
                <Select
                  options={sampleOptions?.map((option) => ({
                    ...option,
                  }))}
                  value={newRow?.sampleName}
                  onChange={handleSampleNameChange}
                  placeholder='Select Sample Name'
                />
              </td>
              <td>
                <Select
                  options={testNameOptions?.map((option) => ({
                    ...option,
                    isDisabled: rows?.some(
                      (row) =>
                        row?.testName && row?.testName?.value === option?.value
                    ),
                  }))}
                  value={newRow?.testName}
                  onChange={handleTestNameChange}
                  placeholder='Select Test Name'
                />
              </td>
              <td>
                <button
                  disabled={
                    !newRow?.testName?.value || !newRow?.sampleName?.value
                  }
                  onClick={handleSaveRow}
                >
                  Add
                </button>
              </td>
            </tr>
            {rows?.map((row, index) => (
              <tr key={`new-row-${index}`}>
                <td colSpan={4}></td>
                <td>{row?.sampleName?.label}</td>
                <td>{row?.testName?.label}</td>
                <td>
                  <IconButton
                    size='small'
                    onClick={() => handleDeleteRow(index)}
                  >
                    <DeleteOutlinedIcon fontSize='small' />
                  </IconButton>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
export default RadiologyPackagesForm

import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import {
  selectedLmsFormMode,
  selectedLmsItem,
  setLmsFormMode,
} from '../../lmsSlice'
import AdminConfig from './AdminConfig'
import AdminForm from './AdminForm'
import AdminTable from './AdminTable'
import Packages from './Packages'
import {
  LABS_PROJECT_ID,
  RADIOLOGY_PROJECT_ID,
  IPD_PROJECT_ID,
} from '../../../../utils/constants'
import { Box, useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { adminText } from '../../models'
const styles = () => ({
  adminContainer: {
    width: '100%',
    height: '100%',
  },
  splitContent: {
    height: '5%',
  },
  adminTableContainer: {
    height: '85%',
  },
})

const LmsAdmin = () => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const currentPath = window.location.pathname
  const splitRadiology = currentPath.split('/')[2]
  const item = useAppSelector(selectedLmsItem)
  const mode = useAppSelector(selectedLmsFormMode)
  const dispatch = useAppDispatch()

  const handleJourneyEnd = () => {
    dispatch(setLmsFormMode('none'))
  }
  const projectId =
    splitRadiology === adminText?.radiology
      ? RADIOLOGY_PROJECT_ID
      : splitRadiology === adminText?.bedManagementAdmin
      ? IPD_PROJECT_ID
      : LABS_PROJECT_ID
  return (
    <Box className={classes?.adminContainer}>
      <Box className={classes?.splitContent}>
        <AdminConfig splitRadiology={splitRadiology} />
      </Box>
      <Box className={classes?.adminTableContainer}>
        {mode === adminText?.none ? (
          <AdminTable projectId={projectId} />
        ) : item === adminText?.packages ? (
          <Packages />
        ) : (
          <AdminForm projectId={projectId} onJourneyEnd={handleJourneyEnd} />
        )}
      </Box>
    </Box>
  )
}

export default LmsAdmin

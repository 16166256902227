import { CRITICAL_BED_ID, NON_CRITICAL_BED_ID } from '../../utils/constants'

export const GENDER_OPTIONS = [
  { label: 'Male', value: 'male' },
  { label: 'Female', value: 'female' },
  { label: 'Other', value: 'other' },
]

export const GOVERNMENT_ID_OPTIONS = [
  { label: 'Voter Id', value: 'voterid' },
  { label: 'Pancard', value: 'pancard' },
  { label: 'Passport', value: 'passport' },
  { label: 'Driving License', value: 'drivingLicense' },
  { label: 'Others', value: 'others' },
]

export const RELIGION_OPTIONS = [
  { label: 'Hindu', value: 'hindu' },
  { label: 'Muslim', value: 'muslim' },
  { label: 'Christian', value: 'christian' },
  { label: 'Sikh', value: 'sikh' },
  { label: 'Jain', value: 'jain' },
  { label: 'Buddhist', value: 'buddhist' },
  { label: 'Other (Specify)', value: 'others' },
]

export const SALUTATION_OPTIONS = [
  { value: 'mr', label: 'Mr' },
  { value: 'mrs', label: 'Mrs' },
  { value: 'miss', label: 'Miss' },
  { value: 'dr', label: 'Dr' },
  { value: 'master', label: 'Master' },
  { value: 'baby', label: 'Baby' },
  { value: 'babyof', label: 'Baby of' },
]

export const BED_TYPE_OPTIONS = [
  { value: CRITICAL_BED_ID, label: 'Critical' },
  { value: NON_CRITICAL_BED_ID, label: 'Non-Critical' },
]

export const OCCUPATION_OPTIONS = [
  { label: 'Govt Employee', value: 'ge' },
  { label: 'Private Employee', value: 'pe' },
  { label: 'Self Employed', value: 'se' },
  { label: 'Business', value: 'bu' },
  { label: 'Agriculture', value: 'ag' },
  { label: 'Student', value: 'st' },
  { label: 'Retired', value: 're' },
  { label: 'Others (Specify)', value: 'others' },
]

export const MARITAL_STATUS_OPTIONS = [
  { label: 'Married', value: 'married' },
  { label: 'Single', value: 'single' },
  { label: 'Widowed', value: 'widowed' },
  { label: 'Separated', value: 'separated' },
]

export const SOCIO_ECONOMIC_STATUS_OPTIONS = [
  { label: 'Upper Class', value: 'uc' },
  { label: 'Middle Class', value: 'mc' },
  { label: 'Lower Class', value: 'lc' },
]

export const CONTACT_RELATION_OPTIONS = [
  {
    label: 'Father',
    value: 'father',
  },
  {
    label: 'Mother',
    value: 'mother',
  },
  {
    label: 'Husband',
    value: 'husband',
  },
  {
    label: 'Wife',
    value: 'wife',
  },
  {
    label: 'Son',
    value: 'son',
  },
  {
    label: 'Daughter',
    value: 'daughter',
  },
  {
    label: 'Brother',
    value: 'brother',
  },
  {
    label: 'Sister',
    value: 'sister',
  },
  {
    label: 'Others(Specify)',
    value: 'others',
  },
]

export const PATIENT_SOURCE_OPTIONS = [
  { label: 'News', value: 'news' },
  { label: 'Campaign', value: 'campaign' },
  { label: 'Advertisement', value: 'ads' },
  { label: 'Friends/Family', value: 'ff' },
  { label: 'Walkin', value: 'walkin' },
]

export const INTENT_IP_REGISTRATION = 'ipRegistration'
export const INTENT_WARD_DETAILS = 'getWardRoomBedDetails'
export const INTENT_CREATE_UPDATE_BED = 'createAndUpdateBed'
export const INTENT_STATES = 'states'
export const INTENT_DISTRICTS = 'districts'

export const aadharRegExp = /^(?:\d{12}|\d{4} \d{4} \d{4}|\d{4}-\d{4}-\d{4})$/
export const phoneRegExp = /^[6789]\d{9}$/
export const specialCharRegExp = /^[a-zA-Z\s]*$/
export const pincodeRegExp = /^[1-9][0-9]{5}$/

export const fieldRequriedError = 'Required'
export const phoneFieldError = 'Phone number is not valid'
export const emailFieldError = 'Invalid email'
export const aadharFieldError = 'Invalid Aadhaar ID'
export const nameSpecialCharFieldError = 'Please enter valid name'
export const pinCodeFieldError = 'Please enter valid code'

import React, { useRef, useEffect } from 'react'
import { AdminTableProps } from '../lms/models'
import { useAppSelector } from '../../app/hooks'
import { selectedLmsAdminSearch } from '../lms/lmsSlice'
import { getSearchResults } from '../lms/utils'
import { noRadiologySamplesFound, tableHeadText } from './Constants/constants'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import './radiology.scss'
import { radiologyColor } from './Utils/RadiologyColors'
const styles = () => ({
  dataTableContainer: {
    overflowY: 'auto',
    borderRadius: '8px',
    width: '100%',
  },
  dataTable: {
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${radiologyColor?.softGrey}`,
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: '6px',
      fontSize: '12px',
      border: `1px solid ${radiologyColor?.softGrey}`,
    },
    '& th': {
      fontWeight: 500,
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: radiologyColor?.whisperGray,
    },
  },
  tableFixed: {
    tableLayout: 'fixed',
  },
  textCenter: {
    textAlign: 'center',
  },
  headAction: {
    backgroundColor: radiologyColor?.steelBlue,
    position: 'sticky',
    top: '0px',
    color: radiologyColor?.white,
  },
  tableHeadId: {
    width: '5%',
  },
  tableHeadName: {
    width: '8%',
  },
  tableBodyName: {
    color: radiologyColor?.blue,
    cursor: 'pointer',
  },
})

const RadiologyCategory = ({ data, onEdit }: AdminTableProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)

  filtered?.sort?.((a: { name: string }, b: { name: string }) =>
    a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
  )
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div className={classes?.dataTableContainer} ref={sampleTableContainerRef}>
      <table className={`${classes?.dataTable} ${classes?.tableFixed}`}>
        <thead className={classes?.headAction}>
          <tr>
            <th className={classes?.tableHeadId}>{tableHeadText.id}</th>
            <th className={classes?.tableHeadName}>{tableHeadText.name}</th>
            <th className={classes?.tableHeadName}>
              {tableHeadText.department}
            </th>
            <th>{tableHeadText.description}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any) => (
              <tr key={d?.id}>
                <td>{d?.id}</td>
                <td>
                  <span
                    onClick={() => onEdit(d?.id)}
                    className={classes?.tableBodyName}
                  >
                    {d?.name}
                  </span>
                </td>
                <td>{d?.department}</td>
                <td>{d.description}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className={classes?.textCenter}>
                {noRadiologySamplesFound}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default RadiologyCategory

import { useTheme } from '@mui/material'
import { useEffect, useRef } from 'react'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../Utils/RadiologyColors'

type Props = {
  summary: string
  disabled: boolean
  onChange: (str: string) => void
}
const styles = () => ({
  radiologyObservationInput: {
    overflow: 'hidden',
    width: '50%',
  },
  radiologyObsArea: {
    width: '100%' /* Ensures the textarea takes full width */,
    resize: 'none' /* Disable manual resizing */,
    border: `1px solid ${radiologyColor?.lightGrey}` /* Keep the border consistent */,
    height: 'auto',
    overflow: 'hidden',
    borderRadius: '5px',
    padding: '5px',
  },
})

const RadiologySummary = ({ summary, disabled, onChange }: Props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }
  useEffect(() => {
    adjustHeight()
    const textarea = textareaRef.current
    if (textarea) {
      textarea.addEventListener('input', adjustHeight)
      return () => {
        textarea.removeEventListener('input', adjustHeight)
      }
    }
  }, [])
  return (
    <div className={classes?.radiologyObservationInput}>
      <textarea
        name='summary'
        id='summary'
        cols={40}
        rows={2}
        ref={textareaRef}
        disabled={disabled}
        maxLength={1000}
        value={summary}
        onChange={({ target }) => onChange(target?.value)}
        placeholder='Enter Summary'
        className={classes?.radiologyObsArea}
      ></textarea>
    </div>
  )
}

export default RadiologySummary

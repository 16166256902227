import { useEffect, useState } from 'react'
import { Sample } from '../../../labTests/models'
import { startSxpProxy } from '../../../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../../../utils/constants'
import { radiologyIntents } from '../../Constants/constants'

export const useCategories = () => {
  const [samples, setSamples] = useState<Sample[]>([])
  const getSamples = () => {
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getRadiologySamplesAPI,
      {}
    )
      .then((data: any) => {
        const sampleData = data.data.radiology_sample
        setSamples(sampleData ?? [])
      })
      .catch(() => {
        setSamples([])
      })
  }

  useEffect(() => {
    getSamples()
  }, [])

  return samples
}

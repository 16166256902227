import JsBarcode from 'jsbarcode'
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  View,
  Image,
} from '@react-pdf/renderer'
import { invoiceDateTimeFormat } from '../../../utils/dateUtils'
import { summaryReason } from '../../lms/utils'
import medunitedLogo from '../../../assets/images/medunited.png'
import locationIcon from '../../../assets/icons/location.png'
import emailIcon from '../../../assets/icons/email.png'
import mobileIcon from '../../../assets/icons/mobile.png'
import { emptyString } from '../Constants/constants'
import React from 'react'

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-italic-webfont.ttf',
      fontStyle: 'italic',
    },
  ],
})
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    paddingBottom: 30,
    fontSize: 9,
  },
  withoutHeaderPage: {
    backgroundColor: '#FFFFFF',
    fontFamily: 'Roboto',
    paddingBottom: 30,
    fontSize: 9,
  },
  technicianContent: {
    marginLeft: 20,
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  technicianText: {
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  header: {
    marginLeft: 20,
    marginRight: 30,
    marginTop: 25,
    borderBottom: '1px solid #CDCDCD',
    padding: 4,
    paddingBottom: 8,
    marginBottom: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerRow: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 4,
    marginRight: 20,
  },
  headerValue: {
    fontSize: 9,
    marginTop: 1,
    color: '#343434',
    width: '35%',
  },
  headerKeyView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '30%',
  },
  departmentView: {
    display: 'flex',
    flexDirection: 'column',
  },
  departmentHeader: {
    fontSize: 10,
    marginBottom: 6,
    fontFamily: 'Roboto',
    textAlign: 'center',
    fontWeight: 'bold',
  },
  tableHead: {
    marginLeft: 20,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'row',
    marginTop: 5,
    backgroundColor: '#F4F4F4',
  },
  panelHead: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#F4F4F4',
    padding: 6,
    border: '1px solid #CDCDCD',
    borderTop: 0,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  textView: {
    padding: 4,
  },
  textViewHeader: {
    padding: 4,
    border: '1px solid #CDCDCD',
    height: 22,
  },
  headText: {
    textAlign: 'center',
  },
  headLeftText: {
    textAlign: 'left',
  },
  basis10: {
    flexBasis: '10%',
  },
  basis15: {
    flexBasis: '15%',
  },
  basis20: {
    flexBasis: '20%',
  },
  basis25: {
    flexBasis: '25%',
  },
  basis30: {
    flexBasis: '30%',
  },
  basis35: {
    flexBasis: '35%',
  },
  basis40: {
    flexBasis: '40%',
  },
  noRightBorder: {
    borderRight: 0,
  },
  noTopBorder: {
    borderTop: 0,
  },
  smallText: {
    fontSize: 9,
  },
  leftAlign: {
    textAlign: 'left',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  centerAlign: {
    textAlign: 'center',
  },
  observationRow: {
    display: 'flex',
    flexDirection: 'row',
    margin: '20 32 16 24',
    alignItems: 'flex-start',
  },
  impressionRow: {
    marginLeft: 20,
    marginRight: 30,
    display: 'flex',
    flexDirection: 'column',
    marginTop: '4px',
    padding: '3px',
    alignItems: 'flex-start',
  },
  observationTitle: {
    flexBasis: 120,
  },
  observationContent: {
    flexBasis: 400,
    flexGrow: 0,
  },
  impressionTitle: {
    flexBasis: 10,
  },
  impressionContent: {
    marginTop: 1,
  },
  signatureContainer: {
    marginTop: 20,
  },
  margin25: {
    marginTop: 25,
  },
  signatureImage: {
    width: 80,
    height: 25,
  },
  signatureName: {
    marginTop: 7,
    fontSize: 8,
  },
  boldContent: {
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  normalContent: {
    fontSize: 9,
    marginTop: 1,
  },
  referredNote: {
    marginLeft: 20,
    marginRight: 30,
    marginTop: 8,
    fontSize: 9,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  lastPage: {
    marginBottom: 150,
  },
  endOfReport: {
    position: 'absolute',
    width: '100%',
    bottom: 35,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  signature: {
    position: 'absolute',
    bottom: 37,
    right: 50,
  },
  summary: {
    marginLeft: 20,
    marginRight: 30,
    marginTop: 12,
  },
  footer: {
    position: 'absolute',
    width: '100%',
    bottom: 10,
    left: 12,
    color: '#343434',
    borderTop: '1px solid #CDCDCD',
    fontSize: 8,
    marginTop: 10,
  },
  withoutFooter: {
    position: 'absolute',
    width: '100%',
    left: 12,
    color: '#343434',
    fontSize: 8,
  },
  footerNotes: {
    position: 'absolute',
    width: '100%',
    bottom: 30,
    left: 12,
    color: '#343434',
  },
  boldText: {
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 9,
  },
  interpretation: {
    marginTop: 5,
    marginLeft: 20,
    marginRight: 20,
    paddingBottom: 10,
  },
  interpretationText: {
    marginBottom: 5,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    fontSize: 10,
  },
  interpretationValue: {
    fontSize: 9,
    textAlign: 'justify',
  },
  panelText: {
    textAlign: 'left',
    fontSize: 9,
    fontWeight: 800,
  },
  container: {
    top: 0,
    width: '100%',
    height: 8,
    position: 'relative',
  },
  headerTopLine: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 6,
    width: '30vw',
    backgroundColor: '#F47421',
  },
  headerTopLineAfter: {
    content: '',
    position: 'absolute',
    top: 0,
    right: 0,
    height: 6,
    width: '70vw',
    backgroundColor: '#004D91',
  },
  italicText: {
    color: '#404040',
    fontStyle: 'italic',
    fontSize: 8,
  },
  technician: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  medLogo: {
    position: 'absolute',
    marginLeft: '16',
    height: '17%',
    width: '18%',
    top: '3%',
  },
  logo: {
    width: '8rem',
    height: '8rem',
  },
  pageCount: {
    position: 'absolute',
    bottom: 20,
    right: 12,
    fontSize: 8,
  },
  headerData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    marginRight: 12,
    marginTop: 12,
    fontSize: 8,
  },
  panelContainer: {
    marginBottom: 20,
  },
  pathologyContainer: {
    paddingRight: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  pathologyName: {
    marginRight: 10,
    fontWeight: 'bold',
  },
  pathologySignature: {
    width: 90,
    height: 30,
  },
  marginLeft: {
    marginLeft: 20,
  },
  margin60: {
    marginTop: 60,
  },
  margin75: {
    marginTop: 75,
  },
  interpretationImage: {
    height: 550,
  },
  table: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginHorizontal: 30,
    marginVertical: 10,
  },
  tableCol: {
    width: '50%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  generateBarCode: {
    width: 180,
    height: 30,
    marginLeft: -11,
  },
  headerCol: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  addressCol: {
    marginRight: 3,
  },
  headerSubCol: {
    display: 'flex',
    flexDirection: 'row',
  },
  emailIcon: {
    marginRight: 2,
    marginLeft: 3,
  },
  observationTitleSpace: {
    width: '30%',
  },
  observationContentSpace: {
    width: '70%',
  },
  headerTextSize: {
    fontSize: 10,
  },
  invoiceWithHeader: {
    right: 20,
    bottom: 20,
  },
  invoiceWithoutHeader: {
    left: 20,
    bottom: 20,
  },
  reportTextSize: {
    fontSize: 15,
  },
  panelValue: {
    borderBottom: 1,
    marginBottom: 5,
  },
  panelLabel: {
    borderTop: 1,
  },
  pageWithoutHeader: {
    height: 53,
  },
  reportWithHeader: {
    marginBottom: 1,
  },
  reportWitoutHeader: {
    marginBottom: 30,
  },
  text: {
    marginBottom: 10,
  },
  containers: {
    padding: 10,
  },
})

type Props = {
  data: any
}

const barcodeOptions = {
  format: 'CODE128',
  width: 1,
  height: 100,
  displayValue: false,
}

const generateBarcodeImage = (data: any) => {
  const canvas = document.createElement('canvas')
  JsBarcode(canvas, data, barcodeOptions)
  return canvas.toDataURL('image/png')
}
const HeaderRow = ({
  label,
  value,
}: {
  label: string
  value: string | number
}) => (
  <View style={styles?.headerRow}>
    <View style={styles?.headerKeyView}>
      <Text style={styles?.boldText}>{label}</Text>
      <Text style={styles?.headerTextSize}>{': '}</Text>
    </View>
    <Text style={styles?.headerValue}>{value}</Text>
  </View>
)

const InvoiceHeader = ({ data }: { data: any }) => (
  <>
    <View fixed style={styles?.header}>
      <View>
        <HeaderRow label='Name' value={data?.name} />
        <HeaderRow label='Id' value={data?.id} />
        <HeaderRow label='Age' value={data?.age} />
        <HeaderRow label='Gender' value={data?.gender} />
        <HeaderRow label='Mobile No' value={data?.mobile} />
        <HeaderRow label='Order Type' value={data?.orderType} />
      </View>
      <View>
        {data?.requestedBy?.indexOf('[') > 0 ? (
          <>
            <HeaderRow
              label='Requested By'
              value={data?.requestedBy?.split('[')?.[0]}
            />
            <HeaderRow
              label='Specialty'
              value={data?.requestedBy?.split('[')?.[1]?.slice(0, -1)}
            />
          </>
        ) : (
          <HeaderRow label='Requested By' value={data?.requestedBy} />
        )}
        <HeaderRow label='LR Number' value={data?.lrNumber} />
        <HeaderRow
          label='Ordered On'
          value={
            data?.orderedOn
              ? invoiceDateTimeFormat(new Date(data?.orderedOn))
              : emptyString
          }
        />
        <HeaderRow
          label='Completed On'
          value={
            data?.validatedOn
              ? invoiceDateTimeFormat(new Date(data?.validatedOn))
              : emptyString
          }
        />
        <View style={styles?.generateBarCode}>
          <Image src={generateBarcodeImage(data?.lrNumber ?? data?.id)} />
        </View>
      </View>
    </View>
  </>
)
const PageCount = ({
  invoiceWithHeaderValue,
}: {
  invoiceWithHeaderValue?: boolean
}) => (
  <View
    style={[
      styles?.pageCount,
      invoiceWithHeaderValue
        ? styles?.invoiceWithHeader
        : styles?.invoiceWithoutHeader,
    ]}
    fixed
  >
    <Text
      render={({ pageNumber, totalPages }) =>
        `Page ${pageNumber} of ${totalPages}`
      }
    />
  </View>
)
const EndOfReport = ({
  invoiceWithHeaderValue,
}: {
  invoiceWithHeaderValue?: boolean
}) => (
  <>
    <View style={styles?.endOfReport}>
      <Text
        style={[
          styles?.reportTextSize,
          invoiceWithHeaderValue
            ? styles?.reportWithHeader
            : styles?.reportWitoutHeader,
        ]}
      >
        ~~~ End of Report ~~~
      </Text>
    </View>
  </>
)

const InvoiceFooter = () => (
  <View fixed style={styles?.footer}>
    <View>
      <Text
        render={() => '1. This Is An Electronically Authenticated Report.'}
        fixed
      />
      <Text>
        2. Please Correlate With Clinical Findings, Consult a Doctor For
        Discussion & Further Medication
      </Text>
    </View>
  </View>
)
const ObservationRow = ({ title, content }: any) => {
  return (
    <View style={styles?.observationRow}>
      <Text style={[styles?.observationTitle, styles?.boldContent]}>
        {title}
      </Text>
      <Text style={styles?.headerTextSize}>: </Text>
      <Text style={[styles?.observationContent, styles?.normalContent]}>
        {content}
      </Text>
    </View>
  )
}
const RenderText = ({ content }: any) => {
  const formattedContent = content?.replace(/\\+n/g, '\n')
  const splitContent = formattedContent?.split('\n')
  return (
    <View style={styles.containers}>
      {splitContent?.map((line: any, index: number) => (
        <Text key={index} style={styles?.text}>
          <Text>
            {line}
            {'\n'}{' '}
          </Text>
        </Text>
      ))}
    </View>
  )
}
const ObservationView = ({ panel }: { panel: any }) => {
  const sample = panel?.values?.summary?.replace(/\\+/g, '\\')
  return (
    <View>
      <ObservationRow
        title='Nature of Specimen'
        content={panel?.test_name}
        boldContent={true}
      />
      {panel?.values?.observations &&
        panel?.values?.observations?.length > 0 && (
          <View style={[styles?.table, styles?.panelValue]}>
            <View fixed style={[styles?.tableRow, styles?.panelLabel]}>
              <Text
                style={[
                  styles?.tableCol,
                  styles?.boldText,
                  styles?.observationTitleSpace,
                ]}
              >
                Observation Title
              </Text>
              <Text
                style={[
                  styles?.tableCol,
                  styles?.boldText,
                  styles?.observationContentSpace,
                ]}
              >
                Observation Content
              </Text>
            </View>
            {panel?.values?.observations?.map((tvo: any) => (
              <View key={tvo?.id} wrap={true} style={[styles?.tableRow]}>
                <Text style={[styles?.tableCol, styles?.observationTitleSpace]}>
                  <RenderText content={tvo?.title} />
                </Text>
                <Text
                  style={[styles?.tableCol, styles?.observationContentSpace]}
                >
                  <RenderText content={tvo?.content} />
                </Text>
              </View>
            ))}
          </View>
        )}

      <ObservationRow
        title='Summary'
        content={summaryReason(sample)}
        boldContent={true}
      />
    </View>
  )
}
const TestView = ({
  test,
  index,
  isLast,
}: {
  test: any
  index: number
  isLast: boolean
  summary: string
  invoiceWithHeaderValue?: boolean
}) => {
  return (
    <View break={index !== 0} style={isLast ? styles?.lastPage : {}}>
      <Text style={styles?.departmentHeader}>
        {test?.radiology_lab_test?.radiology_sample?.department?.toUpperCase()}
      </Text>
      <ObservationView panel={test} />

      {test?.radiology_lab_test?.Interpretation && (
        <div style={styles?.interpretation}>
          <Text style={styles?.interpretationText}>Interpretation:</Text>
          <Text style={styles?.interpretationValue}>
            {test?.radiology_lab_test?.Interpretation}
          </Text>
        </div>
      )}
      {!test?.referred_out && <SignatureView panel={test} />}
    </View>
  )
}
const HeaderData = ({ data }: { data: any }) => (
  <View style={styles?.headerData} fixed>
    <View style={styles?.headerCol}>
      <View style={styles?.addressCol}>
        <Image source={locationIcon} style={styles?.logo} />
      </View>
      <Text>Reg. Address: {data?.patientAddress?.address?.line?.[0]}</Text>
    </View>
    <View style={styles?.headerSubCol}>
      <View style={styles?.headerCol}>
        <View style={styles?.addressCol}>
          <Image source={mobileIcon} style={styles?.logo} />
        </View>
        <Text>{data?.patientAddress?.telecom?.[0]?.value}</Text>
      </View>
      <View style={styles?.headerCol}>
        <View style={styles?.emailIcon}>
          <Image source={emailIcon} style={styles?.logo} />
        </View>
        <Text>{data?.patientAddress?.telecom?.[1]?.value}</Text>
      </View>
    </View>
  </View>
)
const TopLine = () => (
  <View fixed style={styles?.container}>
    <View style={styles?.headerTopLine} />
    <View style={styles?.headerTopLineAfter} />
  </View>
)

const Logo = () => (
  <View style={styles?.medLogo} fixed>
    <Image src={medunitedLogo} />
  </View>
)

const SignatureView = ({ panel }: { panel: any }) => {
  return (
    <View style={styles?.technician}>
      {panel?.results_entered_by && (
        <View style={styles?.technicianContent}>
          <Text style={styles?.technicianText}>Technician</Text>
          <Text style={styles?.technicianText}>
            {panel?.results_entered_by}
          </Text>
        </View>
      )}
    </View>
  )
}
const RadiologyInvoicePdf = ({ data }: Props) => {
  return (
    <Document title='Results'>
      <Page
        wrap
        size='A4'
        style={
          data?.invoiceWithHeaderValue ? styles.page : styles?.withoutHeaderPage
        }
      >
        {data?.invoiceWithHeaderValue ? <TopLine /> : null}
        {data?.invoiceWithHeaderValue ? <Logo /> : null}
        {data?.invoiceWithHeaderValue ? (
          <HeaderData data={data} />
        ) : (
          <View fixed style={styles?.pageWithoutHeader}></View>
        )}
        <InvoiceHeader data={data} />
        {data?.radiologyResults?.length > 0
          ? data?.radiologyResults?.map((test: any, i: number, arr: any) => (
              <>
                <TestView
                  key={i}
                  test={test}
                  index={i}
                  isLast={arr?.length === i + 1}
                  summary={data?.summary}
                  invoiceWithHeaderValue={data?.invoiceWithHeaderValue}
                />
              </>
            ))
          : null}

        {<EndOfReport invoiceWithHeaderValue={data?.invoiceWithHeaderValue} />}
        <PageCount invoiceWithHeaderValue={data?.invoiceWithHeaderValue} />
        {data?.invoiceWithHeaderValue ? <InvoiceFooter /> : null}
      </Page>
    </Document>
  )
}
export default RadiologyInvoicePdf

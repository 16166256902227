import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from 'react'
import RadiologySummary from './RadiologySummary'
import { LmsObservation, LmsValues, resultsSummary } from '../../lms/models'
import RadiologyObservation from './RadiologyObservation'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import { IconButton, useTheme } from '@mui/material'
import { RadiologyResult } from '../models'
import KeycloakService from '../../../utils/keycloakService'
import { VALIDATION_WRITE } from '../../../utils/roles'
import {
  emptyString,
  radiologyButtons,
  radiologyComments,
  radiologyTestStatus,
  threeDot,
} from '../Constants/constants'
import './observations.scss'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../Utils/RadiologyColors'

type ViewProps = {
  obs: LmsObservation
  disabled: boolean
  onSelect: (obs: LmsObservation) => void
  onDelete: (obs: LmsObservation) => void
}

const RadiologyObservationView = ({
  obs,
  disabled,
  onSelect,
  onDelete,
}: ViewProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const handleDelete = (
    ev: MouseEvent<HTMLButtonElement>,
    o: LmsObservation
  ) => {
    onDelete(o)
    ev.stopPropagation()
  }
  const [expanded, setExpanded] = useState(false)
  const toggleExpanded = (ev: MouseEvent<HTMLButtonElement>) => {
    setExpanded(!expanded)
    ev.stopPropagation()
  }
  useEffect(() => {
    setExpanded(false)
  }, [obs.content])
  const contentLines = obs.content
  const showFullContent = contentLines?.length > 50
  return (
    <tr
      key={obs?.id}
      className={classes?.observationPointer}
      onClick={() => onSelect(obs)}
    >
      <td className={classes?.radiologyTitleBody}>{obs.title}</td>
      <td className={classes?.radiologyTitleExpand}>
        {expanded ? (
          <>
            <div className={classes?.observationWordBreak}>{obs.content}</div>
            <button
              onClick={toggleExpanded}
              className={classes?.obsTitleExpand}
            >
              {radiologyButtons?.viewLess}
            </button>
          </>
        ) : (
          <>
            <div>
              {contentLines?.slice(0, 50)}
              {showFullContent ? threeDot : emptyString}
            </div>
            {showFullContent && (
              <button
                onClick={toggleExpanded}
                className={classes?.obsTitleExpand}
              >
                {radiologyButtons?.viewMore}
              </button>
            )}
          </>
        )}
      </td>
      <td className={classes?.obsDeleteContent}>
        <button
          disabled={disabled}
          onClick={(ev) => handleDelete(ev, obs)}
          className={classes?.obsDeleteButton}
        >
          <IconButton
            size='small'
            className={classes?.obsDeleteIcon}
            onClick={(ev) => handleDelete(ev, obs)}
            disabled={disabled}
          >
            <DeleteOutlinedIcon fontSize='small' />
          </IconButton>
        </button>
      </td>
    </tr>
  )
}

type ListProps = {
  values?: LmsValues
  disabled: boolean
  onSummaryChange: (summary: string) => void
  onObservationChange: (observations: LmsObservation[]) => void
  onCommentsChange: (commnets: string) => void
  result: RadiologyResult
  isEdit: boolean
  valuesUpdated?: boolean
  isReferred: boolean
}
const styles = () => ({
  obsListContainer: {
    width: '100%',
  },
  observationListContainer: {
    width: '100%',
    borderCollapse: 'collapse',
    borderRadius: '14px',
    tableLayout: 'fixed',
    position: 'relative',
    zIndex: 1,
  },
  radiologySummaryContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    alignItems: 'center',
    gap: 10,
  },
  radiologySpanContainer: {
    fontSize: '14px',
    fontWeight: 500,
    margin: '2px 0px',
  },
  radiologySubmitObsContainer: {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    alignItems: 'center',
    gap: '4%',
  },
  radiologyObservationInput: {
    overflow: 'hidden',
    width: '50%',
  },
  commentsSection: {
    width: '100%' /* Ensures the textarea takes full width */,
    resize: 'none' /* Disable manual resizing */,
    border: `1px solid ${radiologyColor?.lightGrey}` /* Keep the border consistent */,
    height: 'auto',
    overflow: 'hidden',
    borderRadius: '5px',
    padding: '5px',
  },
  observationPointer: {
    cursor: 'pointer',
  },
  radiologyTitleBody: {
    padding: '8px',
    border: `1px solid ${radiologyColor?.lightGrey}`,
    width: '40%',
    fontSize: '15px',
  },
  radiologyTitleExpand: {
    padding: '8px',
    border: `1px solid ${radiologyColor?.lightGrey}`,
    width: '55%',
  },
  observationWordBreak: {
    wordBreak: 'break-all',
  },
  obsTitleExpand: {
    border: 'none',
    color: radiologyColor?.blue,
    cursor: 'pointer',
  },
  obsDeleteContent: {
    padding: '8px',
    border: `1px solid ${radiologyColor?.lightGrey}`,
    textAlign: 'center',
    width: '5%',
  },
  obsDeleteButton: {
    border: 'none',
    cursor: 'pointer',
    background: 'none',
  },
  obsDeleteIcon: {
    color: radiologyColor?.darkBlue,
  },
  titleHeader: {
    width: '35%',
  },
  contentHeader: {
    width: '55%',
  },
  actionHeader: {
    width: '10%',
  },
})
const RadiologyObservationList = ({
  values,
  disabled,
  onSummaryChange,
  onObservationChange,
  onCommentsChange,
  result,
  isEdit,
  valuesUpdated,
  isReferred,
}: ListProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const textareaRef = useRef<HTMLTextAreaElement | null>(null)
  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto'
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`
    }
  }
  const [comments, setComments] = useState('')
  const [selected, setSelected] = useState<LmsObservation | undefined>(
    undefined
  )

  const handleSummaryChange = (str: string) => {
    onSummaryChange(str)
  }

  const handleObservationSelect = (obs: LmsObservation) => {
    if (disabled) {
      return
    }
    if (selected?.id === obs?.id) {
      setSelected(undefined)
    } else {
      setSelected(obs)
    }
  }

  const handleDelete = (obs: LmsObservation) => {
    if (obs?.id === selected?.id) {
      setSelected(undefined)
    }
    const updated = values?.observations?.filter((v) => v?.id !== obs.id) ?? []
    onObservationChange(updated)
  }

  const handleObservationSave = (data: LmsObservation) => {
    onObservationChange([...(values?.observations ?? []), data])
  }

  const handleObservationUpdate = (data: LmsObservation) => {
    const updated =
      values?.observations.map((v) => {
        if (v.id === data.id) {
          return data
        }
        return v
      }) ?? []
    onObservationChange(updated)
    setSelected(undefined)
  }
  const handleComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setComments(event.target.value)
    onCommentsChange(event.target?.value)
  }
  useEffect(() => {
    adjustHeight()
    const textarea = textareaRef.current
    if (textarea) {
      textarea.addEventListener('input', adjustHeight)
      return () => {
        textarea.removeEventListener('input', adjustHeight)
      }
    }
  }, [])
  return (
    <div className={classes?.obsListContainer}>
      {!disabled && (
        <RadiologyObservation
          existing={selected}
          onSave={handleObservationSave}
          onUpdate={handleObservationUpdate}
        />
      )}
      {!!values?.observations?.length && (
        <table className={classes?.observationListContainer}>
          <thead>
            <tr>
              <th className={classes?.titleHeader}>Title</th>
              <th className={classes?.contentHeader}>Content</th>
              <th className={classes?.actionHeader}>Action</th>
            </tr>
          </thead>
          <tbody>
            {values?.observations?.map((oo) => (
              <RadiologyObservationView
                key={oo?.id}
                disabled={disabled}
                obs={oo}
                onSelect={handleObservationSelect}
                onDelete={handleDelete}
              />
            ))}
          </tbody>
        </table>
      )}
      <div className={classes?.radiologySummaryContainer}>
        <span className={classes?.radiologySpanContainer}>
          {resultsSummary}
        </span>
        <RadiologySummary
          disabled={disabled}
          summary={values?.summary || ''}
          onChange={handleSummaryChange}
        />
      </div>
      {result?.test_status === radiologyTestStatus?.submitted &&
        (!valuesUpdated || isEdit) &&
        !isReferred &&
        KeycloakService.hasRole([VALIDATION_WRITE]) && (
          <div className={classes?.radiologySubmitObsContainer}>
            <span className={classes?.radiologySpanContainer}>
              {radiologyComments}
            </span>
            <div className={classes?.radiologyObservationSubmit}>
              <textarea
                name='commnets'
                id='comments'
                ref={textareaRef}
                cols={40}
                rows={2}
                maxLength={1000}
                value={comments}
                onChange={handleComments}
                placeholder='Enter Comments'
                className={classes?.commentsSection}
              ></textarea>
            </div>
          </div>
        )}
    </div>
  )
}

export default RadiologyObservationList

import { readableDateFormat } from '../../../../utils/dateUtils'
import {
  activeTabs,
  emptyString,
  modes,
  noData,
  noOrdersFound,
  printLogo,
  tableHeadText,
} from '../../Constants/constants'
import CollectLogo from '../../../../assets/images/test-tube.png'
import { RadiologyOrders } from '../../models'
import PrintLogo from '../../../../assets/images/print.png'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'
type Props = {
  mode: 'ordered' | 'tested' | 'completed'
  orders: RadiologyOrders[]
  onOrderView: (id: string) => void
  onOrderCreate?: () => void
}
const styles = () => ({
  radiologySearchTableContainer: {
    marginTop: '20px',
  },
  modeContent: {
    margin: '2px 0',
    fontSize: '14px',
    fontWeight: 500,
  },
  adminTable: {
    width: '100%',
    fontSize: '13px',
    borderSpacing: '0',
    tableLayout: 'fixed',
    border: `1px solid ${radiologyColor?.corporateBlue}`,
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    '& th, & td': {
      padding: '6px 12px',
    },
    '& thead': {
      backgroundColor: radiologyColor?.steelBlue,
      color: radiologyColor?.white,
      border: `1px solid ${radiologyColor?.softGrey}`,
      '& tr': {
        textAlign: 'left',
        '& th': {
          fontWeight: 500,
          '&:first-child': {
            borderTopLeftRadius: '4px',
          },
          '&:last-child': {
            borderTopRightRadius: '4px',
          },
        },
      },
    },
    '& tbody': {
      '& .lmsBtn': {
        border: 'none',
        backgroundColor: radiologyColor?.transparent,
        color: radiologyColor?.blue,
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'left',
        padding: '0',
        '&:focus-visible': {
          outline: `${radiologyColor?.azureBlue} auto 1px`,
        },
      },
      '& tr:nth-child(even)': {
        backgroundColor: radiologyColor?.pearlGray,
      },
    },
    '& .selectedPatient': {
      fontWeight: 600,
    },
  },
  printLogo: {
    border: 'none',
    width: '10%',
    backgroundColor: radiologyColor?.transparent,
    color: radiologyColor?.blue,
    textDecoration: 'underline',
    cursor: 'pointer',
    textAlign: 'center',
    padding: '0',
    '&:focus-visible': {
      outline: `${radiologyColor?.azureBlue} auto 1px`,
    },
  },
  collectLogo: {
    width: '100%',
  },
  alignText: {
    textAlign: 'center',
  },
})

const RadiologySearchTable = ({ mode, orders, onOrderView }: Props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const mappedSamplesName = (tests: any) => {
    const samples = tests?.map((e: any) => e?.sample_name)?.join(',')
    return samples
  }
  return (
    <div className={classes?.radiologySearchTableContainer}>
      <p className={classes?.modeContent}>
        {mode === modes?.ordered
          ? modes?.ordersToTest
          : mode === modes?.tested
          ? modes?.ordersToDispatch
          : modes?.completedOrders}
      </p>
      <table className={classes?.adminTable}>
        <thead>
          <tr>
            <th>{tableHeadText.source}</th>
            <th>{tableHeadText.orderedBy}</th>
            <th>{tableHeadText.orderedOn}</th>
            <th>{tableHeadText.tests}</th>
            <th>{tableHeadText.category}</th>
            <th>{tableHeadText.actions}</th>
          </tr>
        </thead>
        <tbody>
          {orders?.length > 0 ? (
            orders?.map((order) => (
              <tr key={order?.id}>
                <td>{order?.source}</td>
                <td>{order?.ordered_by_name}</td>
                <td>
                  {order?.ordered_on
                    ? readableDateFormat(new Date(order?.ordered_on))
                    : noData}
                </td>
                <td>{order?.radiology_order_tests?.length}</td>
                <td>{mappedSamplesName(order?.radiology_order_tests)}</td>
                <td>
                  {mode !== activeTabs?.completed ? (
                    <button
                      onClick={() => onOrderView(order?.id ?? emptyString)}
                      className={classes?.printLogo}
                    >
                      <img
                        src={CollectLogo}
                        alt={printLogo}
                        className={classes?.collectLogo}
                      />
                    </button>
                  ) : (
                    <img src={PrintLogo} alt='Print Report' />
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className={classes?.alignText} colSpan={5}>
                {noOrdersFound}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default RadiologySearchTable

import React, { useEffect, useMemo, useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import {
  AppointmentData,
  fetchAppointmentsAsync,
  resetAppointmentsStatus,
  selectSelectedAppointment,
  selectSelectedAppointmentDate,
  setSelectedAppointment,
  setSelectedDate,
  updateAppointmentAsync,
} from './appointmentsSlice'
import CancelModal from './components/CancelModal'
import AppointmentsFilter from './components/AppointmentsFilter'
import AppointmentsTable from './components/AppointmentsTable'
import KeycloakService from '../../utils/keycloakService'
import { APPOINTMENTS_WRITE } from '../../utils/roles'
import { selectSelectedLocationId } from '../location/locationSlice'
import Slots from '../slots/Slots'
import Scheduler from '../scheduler/Scheduler'
import { appointments } from '../Radiology/Constants/constants'

const Appointments = () => {
  const selectedId = useAppSelector(selectSelectedAppointment)
  const selectedDate = useAppSelector(selectSelectedAppointmentDate)
  const startDate = useMemo(() => {
    return new Date(selectedDate)
  }, [selectedDate])
  const selectedLocation = useAppSelector(selectSelectedLocationId)
  const dispatch = useAppDispatch()

  const [selectedStatus, setSelectedStatus] = useState<string>('')
  const [showModal, setShowModal] = useState(false)
  const [showList] = useState('')

  useEffect(() => {
    dispatch(
      fetchAppointmentsAsync({ date: startDate, locationId: selectedLocation })
    )
    return () => {
      dispatch(resetAppointmentsStatus())
    }
  }, [startDate, selectedLocation, dispatch])

  const handleDateChange = (date: Date) => {
    dispatch(setSelectedDate(date.toISOString()))
  }

  const handleChangeStatus = async (st: string, d: AppointmentData) => {
    if (!KeycloakService.hasRole([APPOINTMENTS_WRITE])) {
      return
    }
    if (st === 'cancelled') {
      setSelectedStatus(st)
      dispatch(setSelectedAppointment(d.id))
      setShowModal(true)
    } else {
      dispatch(
        updateAppointmentAsync({ appId: d.id, status: st, cancelReason: '' })
      )
    }
  }

  const handleModalCancel = (reason: string) => {
    dispatch(
      updateAppointmentAsync({
        appId: selectedId,
        status: selectedStatus,
        cancelReason: reason,
      })
    )
    setShowModal(false)
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  return (
    <div className='layoutcontent'>
      <div className='membershipText'>{appointments}</div>
      {showModal && (
        <CancelModal
          handleCancel={handleModalCancel}
          handleClose={handleModalClose}
        />
      )}
      {showList === 'SlotManagement' ? (
        <Slots />
      ) : showList === 'Calender' ? (
        <Scheduler />
      ) : (
        <>
          <AppointmentsFilter
            startDate={startDate}
            onChange={handleDateChange}
          />
          <AppointmentsTable handleChange={handleChangeStatus} />
        </>
      )}
    </div>
  )
}

export default Appointments

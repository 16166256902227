/* eslint-disable camelcase */
import { ChangeEvent, useRef, useState } from 'react'
import {
  emptyString,
  radiologyIntents,
  tabs,
  toastOptions,
  visibleDownArrow,
  visibleUpArrow,
} from '../../Constants/constants'
import {
  RadiologyResult,
  RefRadiologyResult,
  apiMessages,
  dialogMessages,
  radiologyValues,
  testStatus,
} from '../../models'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { Organization } from '../../../lms/models'
import { encodeNewLineText, makeName } from '../../../lms/utils'
import { Patient } from 'fhir/r4'
import {
  FILE_SERVER_URL,
  RADIOLOGY_PROJECT_ID,
} from '../../../../utils/constants'
import { startSxpProxy, uploadImage } from '../../../../utils/api'
import '../../radiology.scss'
import RadiologyTestRow from './RadiologyTestRow'
import KeycloakService from '../../../../utils/keycloakService'
import { useAppDispatch } from '../../../../app/hooks'
import { setRadiologyActiveTab } from '../../RadiologySlice'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Tooltip from '@mui/material/Tooltip'
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload'
import RadiologyLoadingButton from '../../RadiologyLoadingButton'
import { toast } from 'react-toastify'
import ToastMessage from '../../../lms/components/ToastMessage'
import { useNavigate } from 'react-router-dom'
import { RegistrationType } from '../../Utils/utils'
import ReplaceDocumentDialog from '../../dialogComponent/ReplaceDocumentDialog'
import { radiologyEndPoint } from '../../RadiologyEndPoints'
import { Collapse, TableCell, TableRow, useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'

type props = {
  result: RefRadiologyResult
  organizations: Organization[]
  patient?: Patient
  disabled: boolean
}

const styles = () => ({
  radiologyReferredGroupContainer: {
    display: 'flex',
  },
  iconAction: {
    cursor: 'pointer',
  },
  referredNameAction: {
    color: radiologyColor?.black,
  },
  referredResultContent: {
    color: radiologyColor?.black,
    textAlign: 'center',
  },
  actionContent: {
    width: '100%',
    display: 'flex',
    color: radiologyColor?.black,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: 'none',
  },
  uploadAction: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '10px',
    width: '24%',
    alignItems: 'center',
    marginLeft: '10px',
  },
  docUrl: {
    color: radiologyColor?.black,
  },
  noDocUrl: {
    color: radiologyColor?.darkBlue,
  },
  noneDisplay: {
    display: 'none',
  },
  documentDownload: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  saveAction: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  statusAction: {
    display: 'flex',
    justifyContent: 'end',
  },
  referredSavedTest: {
    color: radiologyColor?.yellow,
    cursor: 'pointer',
  },
  noreferredTest: {
    color: radiologyColor?.brown,
    cursor: 'pointer',
  },
  tableRow: {
    backgroundColor: `${radiologyColor?.ashGray} !important`,
    borderRight: `1px solid ${radiologyColor?.white}`,
  },
})
const RadiologyReferredGroup = ({ result, organizations, disabled }: props) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [openDialog, setOpenDialog] = useState(false)
  const [results, setResults] = useState<RefRadiologyResult>(result)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [apiLoad, setApiLoad] = useState(false)
  const docsRef = useRef<HTMLInputElement | null>(null)
  const handleFileButton = () => {
    if (results?.document_url) {
      setOpenDialog(true)
    } else {
      docsRef.current?.click()
    }
  }
  const handleDialogClose = () => {
    setOpenDialog(false)
  }

  const handleDialogConfirm = () => {
    setOpenDialog(false)
    docsRef.current?.click()
  }
  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const file = ev.target.files?.[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file, 'report.pdf')
      uploadImage(formData)
        .then((data) => {
          const uploaded = data.data[0]
          setResults((prev) => ({
            ...prev,
            document_url: `${FILE_SERVER_URL}file/${uploaded}`,
          }))
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
  const handleValuesChange = (values: radiologyValues) => {
    setResults((prev) => ({ ...prev, values: values }))
  }
  const handleSave = (k: RefRadiologyResult) => {
    const refId = KeycloakService?.getSub()
    const refName = KeycloakService?.getFullname()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { radiology_order, organization, patient, ...rest } = k
    const now = new Date().toISOString()
    const updatedResult: Partial<RadiologyResult> = {
      ...rest,
      value: encodeNewLineText(k?.value),
      values: k?.values,
      extra_value: k?.extra_value,
      observation: encodeNewLineText(k?.observation),
      document_url: k?.document_url,
      results_entered_on: now,
      results_entered_by: refName,
      results_entered_by_id: refId,
    }
    const results = [{ where: { id: { _eq: k?.id } }, _set: updatedResult }]
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.updateRadiologyResults,
      { results }
    )
      .then(() => {
        toast.success(
          <ToastMessage message={apiMessages?.radiologyResultsSaved} />,
          {
            ...toastOptions,
          }
        )
        Promise.all([dispatch(setRadiologyActiveTab(tabs?.testing))])
          .then(() => {
            navigate(
              `${radiologyEndPoint?.radiologyResults}/${k?.radiology_order_id}`
            )
          })
          .catch((err) => {
            console.error('Dispatch error:', err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setApiLoad(false)
      })
  }
  return (
    <>
      <ReplaceDocumentDialog
        open={openDialog}
        onClose={handleDialogClose}
        onConfirm={handleDialogConfirm}
      />
      <TableRow className={classes?.tableRow}>
        <TableCell>
          <div
            className={`${classes?.radiologyReferredGroupContainer} ${classes?.iconAction}`}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            <span>{isExpanded ? visibleDownArrow : visibleUpArrow}</span>
            <div className={classes?.referredNameAction}>
              {result?.test_name}
            </div>
          </div>
          {/* </div> */}
        </TableCell>
        <TableCell className={classes?.referredResultContent}>
          {RegistrationType(
            result?.patient?.identifier?.find(
              (e: any) => e?.system === apiMessages?.registrationType
            )?.value ?? emptyString
          )}
        </TableCell>
        <TableCell className={classes?.referredResultContent}>
          {result?.radiology_order_id}
        </TableCell>
        <TableCell className={classes?.referredResultContent}>
          {result?.patient_uhid}
        </TableCell>
        <TableCell className={classes?.referredResultContent}>
          {makeName(result?.patient?.name)}
        </TableCell>
        <TableCell className={classes?.referredResultContent}>
          <span className={classes?.referredNameAction}>
            {organizations?.find((o) => o?.id === result?.referred_to)?.name ??
              emptyString}
          </span>
        </TableCell>
        <TableCell>
          <div className={classes?.actionContent}>
            <div className={classes?.uploadAction}>
              <DriveFolderUploadIcon
                titleAccess='Upload Documents'
                onClick={handleFileButton}
                className={`${
                  result?.document_url ? classes?.docUrl : classes?.noDocUrl
                }`}
              />

              <input
                id='docUrl'
                ref={docsRef}
                type='file'
                accept='application/pdf'
                className={classes?.noneDisplay}
                onChange={handleFileChange}
              />
              <div>
                {results?.document_url && (
                  <a
                    href={results?.document_url}
                    target='_blank'
                    rel='noreferrer'
                    download
                    className={classes?.documentDownload}
                  >
                    <VisibilityOutlinedIcon fontSize='small' />
                  </a>
                )}
              </div>
            </div>
            <div className={classes?.saveAction}>
              {results?.document_url &&
                (results?.value || results?.values?.summary) && (
                  <RadiologyLoadingButton
                    title='Save'
                    onClick={() => handleSave(results)}
                    loading={apiLoad}
                    compact
                  />
                )}
            </div>
            <div className={classes?.statusAction}>
              {result?.referred_out &&
              result?.test_status === testStatus?.entered &&
              (result?.value || result?.values?.summary) &&
              result?.document_url ? (
                <Tooltip title={dialogMessages?.referredTestSaved}>
                  <CheckCircleIcon className={classes?.referredSavedTest} />
                </Tooltip>
              ) : result?.referred_out &&
                result?.test_status === testStatus?.entered &&
                (!result?.value ||
                  !result?.values?.summary ||
                  !result?.document_url) ? (
                <Tooltip title={dialogMessages?.noReferredValues}>
                  <CheckCircleIcon className={classes?.noreferredTest} />
                </Tooltip>
              ) : null}
            </div>
          </div>
        </TableCell>
      </TableRow>
      {isExpanded && (
        <>
          <TableRow>
            <TableCell colSpan={7} className={classes?.testRowTableCell}>
              <Collapse in={isExpanded} timeout='auto' unmountOnExit>
                <RadiologyTestRow
                  id={results?.test_id}
                  result={results}
                  patient={results?.patient}
                  isReferred={true}
                  isEdit={false}
                  disabled={disabled}
                  onValuesChange={handleValuesChange}
                />
              </Collapse>
            </TableCell>
          </TableRow>
        </>
      )}
    </>
  )
}
export default RadiologyReferredGroup

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Patient, Practitioner, Provenance } from 'fhir/r4'
import { makeName } from '../../../lms/utils'
import { getAgeInYears } from '../../../../utils/dateUtils'
import { getProvenanceData } from '../../../patients/utils'
import { startSxpProxy } from '../../../../utils/api'
import { REGISTRATION_PROJECT_ID } from '../../../../utils/constants'
import ProvenanceView from '../../../../components/ProvenanceView'
import { ProvenanceData } from '../../../patients/patientsSlice'
import LocationByPrefix from '../../../patients/dashboard/LocationByPrefix'
import { UserManagementIntent } from '../../../administration/constants'
import {
  dialogMessages,
  emptyString,
  noData,
  provenanceCreate,
  provenanceUpdate,
  unknown,
} from '../../Constants/constants'
import { useTheme } from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../../Utils/RadiologyColors'

type Props = {
  patient: Patient | null
  loading: boolean
}
const styles = () => ({
  patientTableContainer: {
    overflow: 'auto',
  },
  patientTableHead: {
    width: '100%',
    borderRadius: '8px',
    border: `1px solid ${radiologyColor?.softGrey}`,
    borderCollapse: 'collapse',
    '& th, & td': {
      padding: '6px',
      fontSize: '12px',
      border: `1px solid ${radiologyColor?.softGrey}`,
    },
    '& th': {
      fontWeight: 500,
      backgroundColor: radiologyColor?.steelBlue,
      color: radiologyColor?.white,
    },
    '& tbody tr:nth-of-type(even)': {
      backgroundColor: radiologyColor?.whisperGray,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  genderTextCapitalize: {
    textTransform: 'capitalize',
  },
})

const RadiologyPatientInfo = ({ patient, loading }: Props) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const [provenances, setProvenances] = useState<
    Record<string, ProvenanceData>
  >({})
  const age = patient ? getAgeInYears(patient?.birthDate) : undefined
  const ids = patient ? patient?.id : undefined
  useEffect(() => {
    if (ids) {
      startSxpProxy(
        REGISTRATION_PROJECT_ID,
        UserManagementIntent?.fetchProvenance,
        {
          patientIds: ids,
        }
      )
        .then((data) => {
          const entries =
            data?.data?.entry?.map(
              (dde: { resource: Provenance | Practitioner }) => dde?.resource
            ) ?? []
          const obj = getProvenanceData(entries)
          setProvenances(obj)
        })
        .catch((err) => console.log(err))
    }
  }, [ids])
  if (!patient) return null
  return (
    <div className={classes?.patientTableContainer}>
      <table className={classes?.patientTableHead}>
        <thead>
          <tr>
            <th>UHID</th>
            <th>Name</th>
            <th>Gender</th>
            <th>Age</th>
            <th>Mobile No.</th>
            <th>Operating Unit</th>
            <th>Created</th>
            <th>Updated</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan={6}>{dialogMessages?.fetching}</td>
            </tr>
          ) : (
            <tr>
              <td>
                {patient?.identifier?.[2]?.value ??
                  patient?.identifier?.[0]?.value ??
                  noData}
              </td>
              <td>{makeName(patient?.name)}</td>
              <td className={classes?.genderTextCapitalize}>
                {patient?.gender}
              </td>
              <td>{age ?? noData}</td>
              <td>{patient?.telecom?.[0]?.value}</td>
              <td>
                <LocationByPrefix
                  id={
                    patient?.identifier?.[2]?.value ??
                    patient?.identifier?.[0]?.value ??
                    noData
                  }
                />
              </td>
              <td className={classes?.textCenter}>
                <ProvenanceView
                  date={
                    provenances[ids ?? emptyString]?.createdOn ?? emptyString
                  }
                  name={provenances[ids ?? emptyString]?.createdBy ?? unknown}
                  mode={provenanceCreate}
                />
              </td>
              <td className={classes?.textCenter}>
                <ProvenanceView
                  date={
                    provenances[ids ?? emptyString]?.updatedOn ??
                    provenances[ids ?? emptyString]?.createdOn ??
                    emptyString
                  }
                  name={
                    provenances[ids ?? emptyString]?.updatedBy ??
                    provenances[ids ?? emptyString]?.createdBy ??
                    unknown
                  }
                  mode={provenanceUpdate}
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

RadiologyPatientInfo.propTypes = {
  patient: PropTypes.shape({
    id: PropTypes.string.isRequired,
    birthDate: PropTypes.string,
    identifier: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      })
    ),
    name: PropTypes.array,
    gender: PropTypes.string,
    telecom: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string,
      })
    ),
  }),
}

export default RadiologyPatientInfo

export const modeType = {
  admin: 'admin',
  signature: 'signature',
}

export const forms = {
  filter: 'Filter:',
  active: 'Active',
  inactive: 'Inactive',
  practitioner: 'Practitioner',
  practitionerRole: 'PractitionerRole',
  loading: '...Loading',
  salutation: ' Salutation',
  firstName: 'First Name',
  middleName: 'Middle Name',
  lastName: 'Last Name',
  gender: 'Gender',
  dob: 'DOB',
  speciality: ' Speciality',
  department: 'Department',
  service: 'Service',
  qualification: 'Qualification',
  registrationNumber: ' Registration Number',
  phoneNumber: 'Phone Number',
  submit: 'Submit',
  Submitting: 'Submitting...',
}

export const formValues = {
  active: 'active',
  inActive: 'inactive',
  all: 'all',
  email: 'email',
  na: 'NA',
  phone: 'phone',
  mr: 'Mr',
  mrs: 'Mrs',
  miss: 'Miss',
  dr: 'DR.',
  master: 'Master',
  male: 'male',
  female: 'female',
  other: 'other',
  healthService: 'healthService',
  OperatingUnit: 'Operating Unit',
}

export const placeHolder = {
  doctorName: 'Search by Doctor Name',
  doctors: 'Enter Doctors Name',
  searchName: 'Search by Name',
  email: 'Enter Email',
  phone: 'Enter Phone Number',
  pathologyName: 'Search by Pathologist Name',
  pathologists: 'Enter Pathologists Name',
  firstName: 'Enter First Name',
  lastName: 'Enter Last Name',
}

export const tableText = {
  name: 'Name',
  speciality: 'Speciality',
  superSpecialty: 'Super Specialty',
  qualification: 'Qualification',
  regNo: 'Reg No',
  slNo: 'Sl No',
  email: 'Email-ID',
  phone: 'Mobile No.',
  status: 'Status',
  gender: 'Gender',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  action: 'Action',
  operatingUnit: 'Operating Unit',
  noDoctorsFound: 'No Doctors Found',
  noPathologistFound: 'No Pathologists Found',
}

export const intent = {
  fetchProvByDoctors: 'fetchProvByDoctors',
  getDoctorById: 'getDoctorById',
  getDoctors: 'getDoctors',
  doctorActive: 'doctorActive',
  createDoc: 'createDoc',
  editDoctor: 'editDoctor',
  getSpecilities: 'getSpecilities',
  getHealthCareService: 'getHealthCareService',
  getLabInchargeList: 'getLabInchargeList',
  getLabDepartments: 'getLabDepartments',
  editLabIncharge: 'editLabIncharge',
  createLabIncharge: 'createLabIncharge',
  labInchargeActive: 'labInchargeActive',
  editLabDepartments: 'editLabDepartments',
  createLabDepartments: 'createLabDepartments',
  getSpecilitiesDoctor: 'getSpecialtiesAW',
  getDoctorsAshaWorkers: 'getDoctorsAndAshaWorkers',
  getLabTestsByIds: 'getLabTestsByIds',
  getDocIdForLabOrder: 'getDocIdForLabOrder',
  updateLabOrderWithDocId: 'updateLabOrderWithDocId',
  updateLabOrderRetryById: 'updateLabOrderRetryById',
  getLabOrdersByDate: 'getLabOrdersByDate',
}

export const messages = {
  errorDoctorMessage: 'Failed to Update doctor status...',
  successDoctorMessage: 'Doctor edited successfully!',
  panelDeletedSuccess: 'Panel Deleted successfully!',
  updatedsuccess: 'Updated successfully!',
  ErrorMessage: 'Error updating status:',
  createdSuccessfully: 'Test Created successfully',
  Deletedsuccessfully: 'Deleted successfully',
  createdDoctorSuccess: 'Doctor created successfully!',
  errorPathologistMessage: 'Failed to Update pathologist status...',
  successPathologistMessage: 'Pathologist edited successfully!',
  createdPathologistSuccess: 'Pathologist created successfully!',
  validPhoneMessage: 'Please enter valid phone number',
  ValidEmailMessage: 'Please enter a valid email address.',
  validNameMessage: 'Please enter your name in characters',
  backToPathology: 'Back to Pathology',
  notValidPathologist: 'Not a Valid Pathologist',
  editedlocmsg: 'Location is edited',
  reportGenerated: 'Report generated. Sending notification to patient.',
  whatsappError: 'Failed to send whatsapp message.',
  reportFailed: 'Report Notification failed.',
  labReportFixed: 'Lab report fixed successfully.',
  retriggerReportNotification:
    'Report message sending failed. Re-trigering. Please wait.',
  retryFailure: 'Retry failed. Exiting from lab report operation.',
  whatsappMessageSuccess: 'Whatsapp message sent successfully',
  whatsappMessageFailure: 'Failed to send whatsapp message.',
  smsSuccess: 'SMS sent successfully.',
  smsFailure: 'Failed to send SMS.',
  sendingNotification: 'Sending notification to patient.',
}

export const headers = {
  backToLab: 'Back to LabDepartments',
  submit: ' Submit',
  notValidLabId: 'Lab Department Id is not valid',
}
export const ErrorMessage = {
  CharactersCheck: 'Please enter your name in characters',
  PhoneNumberCheck: 'Please enter a valid phone number (10 digits).',
  AddressCheck: 'Please enter a valid email address.',
  GSTCheck:
    'GST number must be 15 characters long and alphanumeric (uppercase).',
  edittedMessage: 'Location is edited',
  editErrorMessage: 'Failed to Edit Location...',
  createdMessage: 'Location is created',
  createdEroorMessage: 'Failed to create location.',
}

export const locationIntent = {
  editLocation: 'editLocation',
  createLocation: 'createLocation',
}

export const FormFeilds = {
  Prefix: 'Prefix',
  PrefixPlaceHolder: 'Enter Location Prefix',
  OperatingUnit: 'Operating Unit',
  OPPlaceholder: 'Enter Location Name',
  Cluster: 'Cluster',
  Type: 'Type',
}

export const UserManagementIntent = {
  KeyclockUsers: 'KeyclockUsers',
  getOdooUsers: 'getOdooUsers',
  getfhirUsers: 'getfhirUsers',
  fetchProvenance: 'fetchProvenance',
  keyclockUserSearch: 'keyclockUserSearch',
  keyclockCompositeRoles: 'keyclockCompositeRoles',
  UserAssignedLocation: 'UserAssignedLocation',
  passwordReset: 'passwordReset',
  inactiveUser: 'inactiveUser',
  updatedataKeyclock: 'updatedataKeyclock',
}

export const UserManagementMessages = {
  UserCreatedMsg: 'User data updated successfully!',
  UserErrorMsg: 'Error updating user data.',
  KeyclockErrorMsg: 'Error while creating a user in keycloak',
  PasswordIncorrect: 'Passwords do not match',
  Done: 'Done',
}

export const locationtableHeadings = {
  Createdon: 'Created on',
  UpdatedOn: 'Updated On',
  Address: 'Address',
}

export const odooServerError = 'odoo server Error'

export const OrganizationIntent = {
  createOrganization: 'createOrganization',
  editOrganization: 'editOrganization',
  getAllOrganizationForAdmin: 'getAllOrganizationForAdmin',
  getOrganizationById: 'getOrganizationById',
}

export const CreatePartnerForm = {
  Name: 'Name',
  PhoneNumber: 'Phone Number',
  Status: 'Status',
  Submit: 'Submit',
  SelectOption: 'Select Option',
}

export const PartnerTable = {
  SerialNo: 'Sl.No',
  Partner_Type: 'Partner Type',
  Partner_Name: 'Partner Name',
  Status: 'Status',
  Action: 'Action',
  Partner: 'Partner',
  PhoneNumber: 'Phone Number',
  apierrorMsg: 'No Partners Found',
  invalidPartnerID: 'Not a Valid partner Id',
  CreatedBy: 'Created By',
  UpdatedBy: 'Updated By',
}
export const create = 'CREATE'
export const update = 'UPDATE'
export const rejected = 'REJECTED'
export const completed = 'COMPLETED'
export const approved = 'approved'
export const unapproved = 'unApproved'
export const entered = 'ENTERED'
export const resultsEntered = 'RESULTS_ENTERED'
export const validation = 'validation'
export const testing = 'testing'
export const Observation = 'Observation'
export const doctor = 'doctor'
export const prefix = 'prefix'

export const PackageErrorMsg = {
  InvalidName: 'Enter a valid Name',
  InvalidPrice: 'Enter a valid Price',
  InvalidDate: 'Enter a valid From Date',
  InvalidTest: 'Please add Tests',
  PckSuccessMsg: 'Package saved successfully',
  PckErrorMsg: 'Error saving package',
  SelectSample: 'Please select a Sample Name before adding.',
  SelectTest: 'Please select a test Name before adding.',
}

export const LabMsg = {
  LabResultsSaved: 'Lab Results Saved',
  LabResultsSubmitted: 'Lab Results Submitted',
  SelectPathologists: 'Select a pathologists to continue',
  SelectPathogistsNotReferred:
    'Select a pathologists to continue for not referred',
  OrderValidated: 'Lab Order Validated',
  RejectMsg: 'Select a reject to continue---',
  ValidatedRejected: 'Lab Results Validated [Some Rejected]',
}

export const LabIntent = {
  createOrderEvent: 'createOrderEvent',
  updateLabResults: 'updateLabResults',
  updateLabOrdersShort: 'updateLabOrdersShort',
}

import { useRef, useEffect } from 'react'
import { AdminTableProps } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { getRoomResults } from '../../utils'
import { roomType } from '../../constants'
import { emptyString } from '../../../Radiology/Constants/constants'
import {
  selectedLmsAdminSearch,
  selectedLmsLocationSearch,
} from '../../lmsSlice'
import LinkButton from './LinkButton'
import '../../lms.scss'

const Rooms = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const locationSearch = useAppSelector(selectedLmsLocationSearch)
  const filtered = getRoomResults(data, searchParam, locationSearch)
  filtered?.sort?.((a: { name: string }, b: { name: string }) =>
    a.name?.toLowerCase() < b.name?.toLowerCase() ? -1 : 1
  )
  const sampleTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = sampleTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <div
      className='data-table table-fixed admin-table-table-container'
      ref={sampleTableContainerRef}
      style={{ overflowY: 'auto' }}
    >
      <table className='data-table table-fixed admin-table relative'>
        <thead className='sticky'>
          <tr>
            <th className='table-w-5'>{roomType.id}</th>
            <th>{roomType.roomName}</th>
            <th>{roomType.wardName}</th>
            <th>{roomType.floor}</th>
            <th>{roomType.location}</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered.map((d: any) => (
              <tr key={d.id}>
                <td>{d.id}</td>
                <td>
                  <LinkButton id={d.id} name={d.room_name} onClick={onEdit} />
                </td>
                <td>{d?.ward_detail?.ward_name}</td>
                <td>{d.floor_number ?? emptyString}</td>
                <td>{d?.ward_detail?.location_id}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} className='text-center'>
                {roomType.noRooms}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default Rooms

import React, { useEffect, useState } from 'react'
import { useTheme, Button, Box } from '@mui/material'
import Asterisk from '../../../../components/Asterisk'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { useDispatch } from 'react-redux'

import { startSxpProxy } from '../../../../utils/api'
import { FILE_SERVER_URL, LABS_PROJECT_ID } from '../../../../utils/constants'
import InterpretationImage from '../../../../form/InterpretationImage'
import { toast } from 'react-toastify'
import AdminTable from './AdminTable'
import { emptyString } from '../../../Radiology/Constants/constants'
import LmsAdmin from './LmsAdmin'
import { selectedLmsItemId, setLmsFormMode, setLmsItem } from '../../lmsSlice'
import { useAppSelector } from '../../../../app/hooks'
import '../../../administration/admin.scss'
import { CreatePartnerForm, messages } from '../../../administration/constants'

const styles = () => ({
  ipdRegisterFormContainer: {
    width: '100%',
    height: '100%',
    padding: '0.6% 3% 3% 3%',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      fontWeight: 600,
    },
    '& .ql-container': {
      height: '60px !important',
    },
  },

  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '17%',
    flexWrap: 'wrap',
    width: '100%',
    '& .ql-container.ql-snow': {
      height: '200px !important',
    },
    '& .ql-toolbar.ql-snow': {
      width: '794px !important',
    },
  },
  ipdRegisterFormRow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    width: '72%',
  },
  ipdRegisterFormField: {
    flex: '0 0 30%',
    display: 'flex',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
  },
  ipdLabelField: {
    fontSize: '13px',
    fontWeight: 500,
    margin: '4px 0',
  },
  panelInputLabel: {
    flex: '0 0 50%',
    display: 'flex',
    justifyContent: 'end',
  },
  formInputField: {
    padding: '2px 10px',
    border: '1px solid #ddd',
    marginBottom: '4px',
    margin: '5px 0 0',
    minWidth: '255px',
    fontSize: '13px',
  },
})
const formats = [
  'header',
  'font',
  'size',
  'bold',
  'italic',
  'underline',
  'strike',
  'color',
  'background', // color and background
  'align',
  'script',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
]
const modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    ['clean'],
  ],
}

const CreateUpdatePanelForm = () => {
  const dispatch = useDispatch()
  const [createOdoo, setCreateOdoo] = useState(true)
  const [isLoading, setIsloading] = useState(false)
  // const appSelector = useAppSelector(selectedLmsFormMode)
  const id = useAppSelector(selectedLmsItemId)
  const theme = useTheme()
  useEffect(() => {
    if (id) {
      startSxpProxy(LABS_PROJECT_ID, 'getPanelById', {
        panelId: parseInt(id),
      }).then((data) => {
        setPanelData(data?.data?.panel_by_pk)
      })
    }
  }, [id])
  const classes = useCustomStyles(styles, theme)
  const initialFormState = {
    panelId: '',
    panelName: '',
    panelDescription: '',
    sampleId: -1,
    departmentId: '',
    handledAt: '',
    interpretationImage: '',
    tatValue: '',
    tatSelect: '',
    price: null as number | null,
    odoo_test_id: null as number | null,
  }
  const [samples, setSamples] = useState([])
  const [department, setDepartments] = useState([])
  const [panelData, setPanelData] = useState<any>(null)
  const [imageURL, setImageURL] = useState('')
  // const [image, setImage] = useState('')
  // const [categories, setCategories] = useState([])
  const [showAdminConfig, setShowAdminConfig] = useState(false)
  const [showAdminTable, setShowAdminTable] = useState(false)
  // const [files, setFiles] = useState<FileList | null>(null)
  const [interpretation, setInterpretation] = useState('')

  const {
    values,
    // setFieldValue,
    setValues,
    // errors,
    // touched,
    handleBlur,
    handleChange,
    submitForm,
  } = useFormik({
    initialValues: initialFormState,
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      handleRegistrationSubmit(values, interpretation)
    },
    enableReinitialize: true,
  })
  const handleRegistrationSubmit = async (
    data: Record<string, any>,
    interpretation: string
  ) => {
    const {
      panelId,
      panelName,
      panelDescription,
      sampleId,
      departmentId,
      handledAt,
      tatSelect,
      tatValue,
      interpretationImage,
      price,
      // odooTestId,
    } = data
    const formatted: Record<string, any> = {
      panelId,
      panelName,
      panelDescription,
      sampleId,
      departmentId,
      handledAt,
      tatValue,
      tatSelect,
      price,
      interpretation,
      interpretationImage,
      isodoo: createOdoo,
      odooTestId: panelData?.odoo_test_id,
      status: panelData?.status,
    }
    if (price !== null) {
      formatted.price = price
    }
    const intent = panelId ? 'updatePanelById' : 'createUpdatePanel'
    setIsloading(true)
    startSxpProxy(LABS_PROJECT_ID, intent, formatted)
      .then((data) => {
        if (data?.status === 200) {
          const successMessage = panelId
            ? `${data?.data?.returning?.name || ''} ${messages?.updatedsuccess}`
            : `${messages?.createdSuccessfully}`

          toast.success(successMessage)

          if (panelId) {
            setShowAdminTable(true)
          } else {
            setShowAdminConfig(true)
            dispatch(setLmsItem('panel'))
            dispatch(setLmsFormMode('none'))
          }
        }
      })
      .catch(() => {
        toast.error(data?.data?.message)
      })
      .finally(() => {
        setIsloading(false)
      })
  }

  const getSamples = () => {
    startSxpProxy(LABS_PROJECT_ID, 'getAllSamplesApi', {})
      .then((data) => {
        const sampleData = data?.data?.sample?.map((sample: any) => {
          const sampleName = sample?.name
          const id = sample?.id
          return {
            sampleName,
            id,
          }
        })
        setSamples(sampleData)
      })
      .catch((err) => {
        console.error(err, 'err')
        setSamples([])
      })
  }
  const getDepartments = () => {
    startSxpProxy(LABS_PROJECT_ID, 'getAllDepartmentsApi', {})
      .then((data) => {
        const departmentData = data?.data?.department?.map((e: any) => {
          const departmentName = e?.department_name
          const id = e?.id
          return {
            departmentName,
            id,
          }
        })
        setDepartments(departmentData)
      })
      .catch((err) => {
        console.error(err, 'err')
        setSamples([])
      })
  }
  useEffect(() => {
    getSamples()
    getDepartments()
  }, [])

  useEffect(() => {
    if (panelData) {
      setValues({
        panelId: panelData?.id,
        panelName: panelData?.name ?? '',
        panelDescription: panelData?.description ?? '',
        sampleId: panelData?.sample_id ?? -1,
        departmentId: panelData?.department_id ?? '',
        handledAt: panelData?.handled_at ?? '',
        interpretationImage: panelData?.interpretation_image ?? '',
        tatValue: panelData?.tat?.split(' ')?.[0],
        tatSelect: panelData?.tat?.split(' ')?.[1],
        price: panelData?.price ?? '',
        odoo_test_id: panelData?.odoo_test_id ?? '',
      })
      setInterpretation(panelData?.interpretation)
      setImageURL(`${FILE_SERVER_URL}/${panelData?.interpretation_image}`)
    }
  }, [panelData, setValues])
  if (showAdminTable) {
    dispatch(setLmsItem('panel'))
    dispatch(setLmsFormMode('none'))
    return <AdminTable projectId={LABS_PROJECT_ID} />
  }
  if (showAdminConfig) {
    dispatch(setLmsItem('panel'))
    dispatch(setLmsFormMode('none'))
    return <LmsAdmin />
  }
  const isEditable = !panelData?.odoo_test_id
  const handleCheckboxChange = () => {
    if (isEditable) {
      setCreateOdoo((prevChecked) => !prevChecked)
    }
  }
  return (
    <>
      <div>
        {showAdminConfig ? (
          <LmsAdmin />
        ) : (
          <div className={classes?.mainContainer}>
            <form className='panel-form w100'>
              <div className='admin-form-flex w-72'>
                <div className='doc-container'>
                  <label htmlFor='test-name' className='doc-input-label'>
                    Test Name
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <input
                    type='text'
                    className='panel-input-content'
                    required
                    name='panelName'
                    value={values?.panelName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>

                <div className='doc-container'>
                  <label htmlFor='testDescription' className='doc-input-label'>
                    Test Decsription
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <input
                    className='panel-input-content'
                    required
                    name='panelDescription'
                    type='text'
                    placeholder='Panel Description'
                    value={values?.panelDescription}
                    onChange={handleChange}
                    maxLength={40}
                  />
                </div>
                <div className='doc-container'>
                  <label htmlFor='sampleId' className='doc-input-label'>
                    Sample
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <select
                    className='panel-input-content'
                    required
                    name='sampleId'
                    value={values?.sampleId}
                    onChange={handleChange}
                  >
                    <option value={emptyString}>
                      {CreatePartnerForm?.SelectOption}
                    </option>
                    {samples?.map((item: any, index) => {
                      return (
                        <option key={index} value={item?.id}>
                          {item?.sampleName}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='doc-container'>
                  <label htmlFor='departmentId' className='doc-input-label'>
                    Department
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <select
                    className='panel-input-content'
                    required
                    name='departmentId'
                    value={values?.departmentId}
                    onChange={handleChange}
                  >
                    <option value={emptyString}>
                      {CreatePartnerForm?.SelectOption}
                    </option>
                    {department?.map((item: any, index) => {
                      return (
                        <option key={index} value={item?.id}>
                          {item?.departmentName}
                        </option>
                      )
                    })}
                  </select>
                </div>
                <div className='doc-container'>
                  <label htmlFor='dob' className='doc-input-label'>
                    Handled At
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <select
                    className='panel-input-content'
                    required
                    name='handledAt'
                    value={values?.handledAt}
                    onChange={handleChange}
                  >
                    <option value=''>{CreatePartnerForm?.SelectOption}</option>
                    <option value='Medunited'>Medunited</option>
                    <option value='Outsourced'>Outsourced</option>
                  </select>
                </div>
                <div className='doc-container'>
                  <label htmlFor='testDescription' className='doc-input-label'>
                    Price &nbsp;:
                  </label>
                  <input
                    className='panel-input-content'
                    name='price'
                    type='text'
                    placeholder='Price'
                    value={values?.price || ''}
                    onChange={handleChange}
                  />
                </div>
                <div className='doc-container'>
                  <label htmlFor='tat' className='doc-input-label'>
                    TAT
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <input
                      required
                      name='tatValue'
                      type='number'
                      placeholder='TAT Value'
                      value={values?.tatValue}
                      onChange={handleChange}
                      maxLength={3}
                    />
                    <select
                      required
                      name='tatSelect'
                      value={values?.tatSelect}
                      onChange={handleChange}
                      style={{ width: '30% !important' }}
                    >
                      <option value=''>select An Option</option>
                      <option value='day'>Day</option>
                      <option value='hour'>Hour</option>
                      <option value='week'>Week</option>
                    </select>
                  </div>
                </div>
                <div className='location-container'>
                  <label htmlFor='hasInventory' className='doc-input-label'>
                    Odoo &nbsp;:
                  </label>
                  <input
                    type='checkbox'
                    name='isodoo'
                    onChange={handleCheckboxChange}
                    checked={
                      createOdoo === true &&
                      (!panelData?.id ||
                        (panelData?.id && !!panelData?.odoo_test_id))
                    }
                    className='location-input-content'
                  />
                </div>
                <div className='doc-container mt4'>
                  <label htmlFor='interpretation' className='doc-input-label'>
                    Interpretation &nbsp;:
                  </label>
                  {/* <textarea
                  name='interpretation'
                  value={values?.interpretation}
                  className='panel-input-content'
                  placeholder='Interpretation'
                  onChange={handleChange}
                /> */}

                  <ReactQuill
                    theme='snow'
                    value={interpretation}
                    onChange={setInterpretation}
                    formats={formats}
                    modules={modules}
                  />
                </div>
                <div className='doc-container'>
                  <label
                    htmlFor='interpretationImage'
                    className='doc-input-label'
                  >
                    Interpretation Image
                  </label>
                  <input
                    type='text'
                    name='interpretationImage'
                    value={values?.interpretationImage}
                    className='panel-input-content'
                    placeholder='Interpretation Image'
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                <div className='doc-container'>
                  <div className='doc-input-label'></div>
                  <InterpretationImage
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                  />
                </div>
                <div className='doc-container'>
                  <div className='doc-input-label'></div>
                  <div className='panel-input-content'>
                    <Box className='box-btn-panel'>
                      <Button
                        variant='contained'
                        color='primary'
                        size='small'
                        className='SubmitPanelForm'
                        disabled={isLoading}
                        onClick={submitForm}
                      >
                        {isLoading ? 'Submitting...' : 'Submit'}
                      </Button>
                    </Box>
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  )
}
export default CreateUpdatePanelForm

import React from 'react'
import DatePicker from 'react-datepicker'
import Calendar from '../../../assets/images/calendar.png'
import { sendSxpMessage } from '../../../utils/api'
import { CHAT_PROJECT_ID } from '../../../utils/constants'
import KeycloakService from '../../../utils/keycloakService'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { selectSearchValue, setSearchValue } from '../appointmentsSlice'
import { SXP_CHAT } from '../../../utils/roles'
import { create } from '../../Radiology/Constants/constants'

type Props = {
  startDate: Date
  onChange: (date: Date) => void
}

const AppointmentsFilter = ({ startDate, onChange }: Props) => {
  const searchValue = useAppSelector(selectSearchValue)
  const dispatch = useAppDispatch()
  const createAppointment = async () => {
    if (KeycloakService.hasRole([SXP_CHAT])) {
      await sendSxpMessage(CHAT_PROJECT_ID, '::restart')
      window.chatSendMsg('book', KeycloakService.getUsername())
    }
  }

  return (
    <div className='text-right mbt10' style={{ marginTop: '0rem' }}>
      <div className='handleCal inline-block'>
        <img alt={'Calendar'} src={Calendar} />
        <DatePicker
          className='datePickerUI'
          placeholderText='DD-MM-YYYY'
          selected={startDate}
          onChange={onChange}
          dateFormat='dd-MM-yyyy'
        />
      </div>
      <input
        type='search'
        className='appointments-search'
        value={searchValue}
        onChange={({ target: { value } }) =>
          dispatch(setSearchValue(value ?? ''))
        }
        placeholder='Search by Patient Id, Name, Mobile, Doctor, Specialty'
      />

      <button
        className={`btn btn-primaryBtn ${
          KeycloakService.hasRole([SXP_CHAT]) ? '' : 'auth-disabled'
        }`}
        onClick={createAppointment}
      >
        {create}
      </button>
    </div>
  )
}

export default AppointmentsFilter

import { useEffect, useRef, useState } from 'react'
import { startSxpProxy } from '../../../utils/api'
import { IPD_PROJECT_ID } from '../../../utils/constants'
import { allocateApi, allocation, tableHeadText } from '../modals'
import { useBeds } from './useBeds'
import { getWards } from '../../lms/utils'
import { bedManagementDashboard } from '../../Radiology/Constants/constants'

const BedManagementDashboard = () => {
  const [wards, setWards] = useState([])
  const [locationName, setLocationName] = useState<string>('')
  const locations = useBeds()
  const filtered = getWards(wards, locationName)
  filtered?.sort?.((a: { wardName: string }, b: { wardName: string }) =>
    a.wardName?.toLowerCase() < b.wardName?.toLowerCase() ? -1 : 1
  )
  useEffect(() => {
    startSxpProxy(IPD_PROJECT_ID, allocateApi.getAllWardsAndBeds, {}).then(
      (data) => {
        const processedData = data.data.ward_details.map((ward: any) => {
          const bedsCountByStatus = ward.room_details.reduce(
            (accumulator: any, room: any) => {
              room.beds.forEach((bed: any) => {
                accumulator[bed.status] = (accumulator[bed.status] || 0) + 1
              })
              return accumulator
            },
            {}
          )

          const totalBeds = ward.room_details.reduce(
            (accumulator: any, room: any) => {
              accumulator += room.beds.length
              return accumulator
            },
            0
          )

          return {
            wardName: ward.ward_name,
            location: ward.location_id,
            floorNumber: ward.floor_number,
            bedCategoryId:
              ward.room_details.length > 0
                ? ward.room_details[0].beds[0]?.bed_category_id
                : null,
            bedsCountByStatus: bedsCountByStatus,
            totalBeds: totalBeds,
          }
        })
        setWards(processedData)
      }
    )
  }, [])
  const patientTableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = patientTableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  return (
    <>
      <div
        className='lms-filters'
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <div className='membershipText mt3 ml10'>{bedManagementDashboard}</div>
        <div>
          <select
            className='category-filter'
            name='location'
            value={locationName}
            onChange={(e) => setLocationName(e.target.value)}
          >
            <option value=''>{allocation.selectLocation}</option>
            {locations.map((op: any, i: number) => (
              <option key={i} value={op}>
                {op}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='overall-container'>
        <div className='sub-container'>
          <div className='input-content'>
            <div className='bedTableHead' ref={patientTableContainerRef}>
              {' '}
              <table className='data-table table-fixed admin-table relative'>
                <thead className='sticky'>
                  <tr>
                    <th className='table-w-5'>Sl No</th>
                    <th className='text-center w25'>{allocation.ward}</th>
                    <th className='text-center'>
                      {tableHeadText.operatingUnit}
                    </th>
                    <th className='text-center'>{tableHeadText.floor}</th>
                    <th className='text-center'>{tableHeadText.bedCategory}</th>
                    <th className='text-center w9'>{tableHeadText.total}</th>
                    <th className='text-center w9'>
                      {allocation.occupiedCase}
                    </th>
                    <th className='text-center w9'>
                      {allocation.availableCase}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filtered.length > 0 ? (
                    filtered.map((d: any, i: number) => (
                      <tr key={i}>
                        <td className='table-w-5'>{i + 1}</td>
                        <td className='text-left'>
                          <span>{d.wardName}</span>
                        </td>
                        <td className='text-center'>{d.location}</td>
                        <td className='text-center'>
                          {d.floorNumber ?? allocation.hyphen}
                        </td>
                        <td className='text-center'>
                          {d?.bedCategoryId === allocation.criticalNumber
                            ? allocation.critical
                            : d?.bedCategoryId === allocation.nonCriticalNumber
                            ? allocation.nonCritical
                            : '-'}
                        </td>
                        <td className='text-center'>{d.totalBeds}</td>
                        <td className='text-center'>
                          {d?.bedsCountByStatus?.OCCUPIED ??
                            allocation.zeroNumber}
                        </td>
                        <td className='text-center'>
                          {d?.bedsCountByStatus?.AVAILABLE ??
                            allocation.zeroNumber}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td className='text-center' colSpan={7}>
                        {allocation.noBeds}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default BedManagementDashboard

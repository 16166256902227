import React, { useRef, useEffect, useState } from 'react'
import { AdminTableProps, Package, toastOptions } from '../../models'
import { useAppSelector } from '../../../../app/hooks'
import { selectedLmsAdminSearch } from '../../lmsSlice'
import { getSearchResults } from '../../utils'
import LinkButton from './LinkButton'
import { LabTests } from '../../../labTests/models'
import { startSxpProxy } from '../../../../utils/api'
import { LABS_PROJECT_ID } from '../../../../utils/constants'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material'
import DeleteOutlined from '@mui/icons-material/DeleteOutlined'
import { toast } from 'react-toastify'
import { forms, messages } from '../../../administration/constants'
import { AdminIntent } from '../../constants'
import KeycloakService from '../../../../utils/keycloakService'
import { SUPER_ADMIN_DELETE } from '../../../../utils/roles'

const PanelTable = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)

  const [tableData, setTableData] = useState(data)
  const textLength = 30
  const [more, setMore] = useState(false)
  const [moreIndex, setMoreIndex] = useState(-1)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [selectedPanel, setSelectedPanel] = useState<Package | null>(null)

  useEffect(() => {
    if (Array?.isArray(data) && data?.length > 0) {
      setTableData(data)
    }
  }, [data])

  const filtered = getSearchResults(tableData, searchParam)
  enum Status {
    Active = 'active',
    Inactive = 'inactive',
  }
  const handleChangeStatus = (
    id: string,
    isStatus: 'active' | 'inactive',
    odooPackageId: any
  ) => {
    startSxpProxy(LABS_PROJECT_ID, AdminIntent?.InactiveTests, {
      id,
      isStatus,
      odooPackageId,
    })
      .then(() => {
        toast.success(messages?.updatedsuccess, toastOptions)
        setTableData((prevData: any) =>
          prevData?.map((item: any) =>
            item?.id === id ? { ...item, status: isStatus } : item
          )
        )
      })
      .catch((err) => {
        console.error(messages?.ErrorMessage, err)
      })
  }

  const handleConfirmDelete = () => {
    if (selectedPanel) {
      startSxpProxy(LABS_PROJECT_ID, AdminIntent?.DeletepanelById, {
        panelId: selectedPanel?.id,
        odooPanelId: selectedPanel?.odoo_test_id,
      })
        ?.then((response) => {
          toast.success(messages?.panelDeletedSuccess, toastOptions) ||
            toast.success(
              response?.data?.name + ' ' + messages?.panelDeletedSuccess
            )
          setTableData((prevData: any) =>
            prevData?.filter((panel: any) => panel?.id !== selectedPanel?.id)
          )

          handleCloseDeleteDialog()
        })
        ?.catch((err) => {
          toast.error(err?.response?.data?.message, toastOptions)
        })
    }
  }

  filtered?.sort?.((a: any, b: any) => {
    if (a?.department?.department_name < b?.department?.department_name) {
      return -1
    } else if (
      a?.department?.department_name > b?.department?.department_name
    ) {
      return 1
    } else {
      return a?.name?.toLowerCase() < b?.name?.toLowerCase() ? -1 : 1
    }
  })

  const tableContainerRef = useRef<HTMLDivElement | null>(null)
  useEffect(() => {
    const resizeHandler = () => {
      const container = tableContainerRef.current
      if (container) {
        const availableHeight: any =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 20}px`
      }
    }

    window.addEventListener('resize', resizeHandler)
    resizeHandler()

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
  }, [])

  const toggleView = (more: boolean, index: number) => {
    setMore(!more)
    setMoreIndex(index === moreIndex ? -1 : index)
  }

  const renderInterpretation = (interpretation: any, index: number) => {
    const text = interpretation.slice(0, textLength)
    if (interpretation.length <= textLength || moreIndex === index) {
      return interpretation
    }

    return <>{text}</>
  }
  const [expandedRows, setExpandedRows] = useState<Set<number>>(new Set())
  const toggleRow = (rowIndex: number) => {
    setExpandedRows((prev) => {
      const newExpandedRows = new Set(prev)
      if (newExpandedRows?.has(rowIndex)) {
        newExpandedRows?.delete(rowIndex)
      } else {
        newExpandedRows?.add(rowIndex)
      }
      return newExpandedRows
    })
  }
  const renderLabTests = (labTests: LabTests[], rowIndex: number) => {
    const initialItemCount = 2
    const isExpanded = expandedRows?.has(rowIndex)

    return (
      <ul className='labTestParameter'>
        {labTests
          ?.slice(0, isExpanded ? labTests?.length : initialItemCount)
          ?.map((item: LabTests) => (
            <li key={item?.id}>• {item?.name}</li>
          ))}
        {labTests?.length > initialItemCount && (
          <li>
            <span onClick={() => toggleRow(rowIndex)} className='linkbtns'>
              {isExpanded ? '...View Less' : '...View More'}
            </span>
          </li>
        )}
      </ul>
    )
  }

  const renderStatusSelect = (d: any) => {
    return (
      <select
        className='medication-input w75'
        value={d?.status === Status?.Active ? Status.Active : Status.Inactive}
        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
          handleChangeStatus(d?.id, e.target.value as Status, d?.odoo_test_id)
        }}
      >
        <option value={Status?.Active}>{forms?.active}</option>
        <option value={Status?.Inactive}>{forms?.inactive}</option>
      </select>
    )
  }

  const handleOpenDeleteDialog = (pkg: Package) => {
    setSelectedPanel(pkg)
    setDeleteDialogOpen(true)
  }
  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
    setSelectedPanel(null)
  }

  return (
    <div
      className='data-table table-fixed admin-table-table-container table-overflow-y'
      ref={tableContainerRef}
    >
      <table className='data-table table-fixed admin-table relative '>
        <thead className='sticky'>
          <tr>
            <th className='table-w-5'>Id</th>
            <th>Test Name</th>
            <th className='table-w-10'>Department</th>
            <th className='table-w-10'>Sample</th>
            <th> Parameter Name</th>
            <th className='table-w-8'> Parameter Count</th>
            <th className='table-w-10'>Handled At</th>
            <th className='table-w-5'>Price</th>
            <th className='table-w-5'>TAT</th>
            <th className='interpretation'>Interpretation</th>
            <th className='table-w-8'> Status</th>
            <th className='table-w-6'>Delete</th>
          </tr>
        </thead>
        <tbody>
          {filtered?.length ? (
            filtered?.map((d: any, index: any) => {
              // const allLabTests =
              //   d?.lab_tests?.flatMap((panel: any) => panel?.name) || []
              const testCount = d?.lab_tests?.length ?? 0
              return (
                <tr key={d?.id}>
                  <td>{d?.id}</td>
                  <td>
                    <LinkButton id={d?.id} name={d?.name} onClick={onEdit} />
                  </td>
                  <td>{d?.department?.department_name ?? '-'}</td>
                  <td>{d?.sample?.name}</td>
                  <td>{renderLabTests(d?.lab_tests || [], index)}</td>
                  <td>{testCount}</td>
                  <td>{d?.handled_at ?? '-'}</td>
                  <td>{d?.price ?? '-'}</td>
                  <td>{d?.tat ?? '1'}</td>
                  <td className='interpretation'>
                    {d?.interpretation ? (
                      <div>
                        {renderInterpretation(d?.interpretation, index)}
                        <span
                          className='linkbtns'
                          onClick={() => toggleView(more, index)}
                        >
                          {index === moreIndex
                            ? '...View Less'
                            : '...View More'}
                        </span>
                      </div>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>{renderStatusSelect(d)}</td>
                  <td>
                    <IconButton
                      size='small'
                      onClick={() => handleOpenDeleteDialog(d)}
                      disabled={!KeycloakService?.hasRole([SUPER_ADMIN_DELETE])}
                    >
                      <DeleteOutlined fontSize='small' />
                    </IconButton>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={5} className='text-center'>
                No Panels Found
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <Dialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        aria-labelledby='delete-dialog-title'
        aria-describedby='delete-dialog-description'
      >
        <DialogTitle id='delete-dialog-title'>Delete Package</DialogTitle>
        <DialogContent>
          <DialogContentText id='delete-dialog-description'>
            Are you sure you want to delete the Test &nbsp;
            <strong>{selectedPanel?.name}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color='secondary' autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PanelTable

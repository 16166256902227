import { LabOrder, LabTest, Panel } from '../labTests/models'
import { Patient } from 'fhir/r4'
import { ToastOptions } from 'react-toastify'

export type AdminOption = {
  label: string
  value: string
}

export type FormMode = 'none' | 'create' | 'edit'

export type DashboardTab = 'collection' | 'testing' | 'validation' | 'dispatch'
export type RadiologyDashboardTab = 'testing' | 'validation' | 'dispatch'

export type AgeType = 'y' | 'm' | 'd' | null

export type AdminTableProps = {
  data: any
  onEdit: (id: string) => void
}

export type LmsOrder = LabOrder & {
  patientThopId: string
  patientUhid: string
  patientName: string
  patientGender: 'M' | 'F' | 'O'
  patientAge: string
  patientMobile?: string
  sample?: string
  document_id: string | null
  dms_upload_retry: boolean
}

export type Organization = {
  id: number
  name: string
}

export type TestResultType = {
  id: number
  result_type: string
  options: string[]
}

export type TestResultLimit = {
  id: number
  gender: 'male' | 'female' | 'other' | null
  min_age: number
  max_age: number
  min_value: number
  max_value: number
  abnormal: string
  test_method: {
    id: number
    method_name: string
  }
  test_unit_type: {
    id: number
    unit_type: string
  }
  age_type: AgeType
}

export type CollectTest = {
  id: number
  name: string
  test_result_limits: TestResultLimit[]
  test_result_type: TestResultType
  panel: {
    id: number
    name: string
    lab_test_sequence: {
      sequence: number[]
    }
  }
}

export type CollectOrder = {
  id: number
  lr_number: string
  payment_status: string
  source: string
  status: 'ORDERED' | 'COLLECTED' | 'SUBMITTED' | 'REJECTED' | 'COMPLETED'
}

export type LmsObservation = {
  id: string
  title: string
  content: string
}

export type LmsValues = {
  summary: string
  observations: LmsObservation[]
}

export type LmsPanel = {
  id: number
  name: string
  sampleId: number
  sampleName: string
  disabled: boolean
  checked: boolean
  collectedBy: string
  collectedOn: string
  lab_tests: LabTest[]
  tests: string
}

export type LabResult = {
  id: number
  patient_id: string
  lab_order_id: number
  lab_order: CollectOrder
  lab_test_id: number
  lab_test: CollectTest
  value: string
  extra_value: string
  values?: LmsValues
  result_limit_id: number
  referred_out: boolean | null
  referred_to: number | null
  organization: Organization
  collected_by: string
  collected_by_id: string
  collected_on: string
  result_entered_by: string
  result_entered_by_id: string
  result_entered_on: string
  test_status: 'COLLECTED' | 'ENTERED' | 'SUBMITTED' | 'APPROVED' | 'REJECTED'
  observation: string | null
  document_url: string
  rejected_on?: string
  rejected_by_id?: string
  rejected_by_name?: string
  rejection_reason?: string
  validated_by?: string
  validated_on?: string
  summary_reason?: string
  authorised_by_id: string
  authorised_by_name?: string
}

export type ResultCount = {
  total: number
  referred: number
  pending: number
}

export type RLabel = {
  name: string
  gender: string
  age: string
  patientId: string
  code: string
}
export type CLabel = {
  name: any
  gender: any
  age: any
  patientId: any
  code: any
}

export type RefLabResult = LabResult & {
  patient: Patient
}

export type Package = {
  id: number
  name: string
  price: number
  panels: Panel[]
  lab_tests: LabTest[]
  active_from: string
  active_till: any
  created_by: string
  created_at: string
  updated_by: string
  updated_at: string
  package_id: any
  odoo_test_id: any
}

export type TestSequence = {
  id: number
  sequence: number[]
}

export type SequenceTest = {
  id: number
  name: string
}

export type SequencePanel = {
  id: number
  name: string
  lab_tests: SequenceTest[]
  lab_test_sequence?: TestSequence
}

export type InvoiceTest = {
  id: string
  name: string
  value: string
  enteredOn: string
  referred: string
  organization: {
    id: number
    name: string
  }
  unit: string
  range: any
  rangeValue: any
  method: string
  abnormal: string
  extraValue: string
  values: LmsValues
  summaryReason?: string
  resultType: string
  enteredName: string
  pathologyDepartment?: string
}
export type ModifiedTestPanel = {
  [key: string]: normal[]
}

export type panelObject = Record<string, testObject[]>

export type panelTests = {
  id: number
  name: string
  value: string
  enteredOn: string
  referredOn: string
  organization?: {
    id: number
    name: string
  }
  unit: string
  range: number[]
  abnormal: string
  method: string
  department: InvoiceDepartment
  resultType: string
  extraValue: string
  values: any
  interpretation?: string
  interpretationImage?: string
}

export type testObject = {
  id: string | number
  name: string
  sequence?: number[] | null
  tests: panelTests[]
  department: InvoiceDepartment
  resultType: string
  interpretation?: string
  interpretationImage?: string
  sample: string
  pathologyName?: string
  pathologySignature?: string
}

export type InvoicePanel = {
  id: string
  name: string
  sequence: number[]
  tests: InvoiceTest[]
  department: InvoiceDepartment
  resultType: string
  interpretation: string
  interpretationImage: string
  sample: string
  pathologyName?: string
  pathologySignature?: string
  labDepartments: any
}

export type InvoiceDepartment = {
  id: string
  department_name: string
}
export type normal = {
  id: number
  name: string
  value: string
  enteredOn: string
  referredOn: string
  organisation: string | undefined
  unit: string
  range: number[]
  abnormal: string
  method: string
  department: InvoiceDepartment
  panel: panelOne
  resultType: string
  extraValue: string
  values: any
  interpretation: string
  pathologyName: string
  pathologySignature: string
}
export type panelOne = {
  id: number
  name: string
  interpretation: string | null
  interpretation_image?: string | null
  lab_test_sequence?: { sequence: number[] }
  sample: {
    name: string
    id: number
  }
}
export type InvoiceData = {
  id: string
  name: string
  age: string
  gender: string
  mobile: string
  requestedBy: string
  lrNumber: string
  orderedOn: string
  completedOn: string
  validatedOn: string
  panels: InvoicePanel[]
  summary: any
  orderType: string
  patientAddress?: any
  invoiceWithHeaderValue?: boolean
  panelId?: string
  pathologySignature?: string
  pathologistName?: string
}
export const panelSummary = 'panelsSummary'
export const dateFormat = {
  startDate: 'Start Date:',
  endDate: 'End Date',
}
export const active = 'active'
export const dispatch = 'dispatch'
export const locationValues = {
  amalapuram: 'Amalapuram',
  razole: 'Razole',
  tatipaka: 'Tatipaka',
  malkipuram: 'Malkipuram',
  All: 'All',
}
export const resultsSummary = 'Result Summary:'
export const intentLab = {
  getLabOrdersByOrderedDate: 'getLabOrdersByOrderedDate',
}
export const numericOutcome = 'Numeric Outcome'
export const toastOptions: ToastOptions = {
  position: 'bottom-center',
  hideProgressBar: true,
  closeButton: false,
  theme: 'dark',
  autoClose: 2000,
  bodyStyle: { padding: 0 },
}

export type PatientVisit = {
  id: string
  source: string
  doctor: string
  specialty: string
  date: string
}
export const tableSummaryHeadText = {
  slNo: 'sl No',
  patientId: 'Patient Id',
  name: 'Name',
  age: 'Age',
  gender: 'Gender',
  mobile: 'Mobile No.',
  source: 'Source',
  orderType: 'Order Type',
  lrNumber: 'Lr Number',
  orderedOn: 'Ordered On',
  collectedOn: 'Collected On',
  completedOn: 'Completed On',
  requestedBy: 'Requested By',
  handledAt: 'Handled At',
  total: 'Total',
  completed: 'Completed',
  pending: 'Pending',
  approve: 'Approve',
  export: 'Export',
}
export type labDepartments = {
  name: string
  id: string
  signature: string
}
export const download = 'Download'
export const exportsText = 'Export'
export const selectPathologist = 'Select Pathologist'
export const labOrdersText = 'labOrders'
export const labOrdersHistory = 'Lab Orders History'
export const lab = 'Lab'
export const adminText = {
  panel: 'panel',
  radiologyPackages: 'radiologyPackages',
  radiologyTest: 'radiologyTest',
  edit: 'edit',
  radiology: 'radiology',
  bedManagementAdmin: 'bedManagementAdmin',
  none: 'none',
  packages: 'packages',
}
export const testStatus = {
  collected: 'COLLECTED',
  entered: 'ENTERED',
  submitted: 'SUBMITTED',
  approved: 'APPROVED',
}
export const activeTabs = {
  testing: 'Testing',
  validation: 'Validation',
  collection: 'Collection',
  dispatch: 'Dispatch',
}

import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Asterisk from '../../../components/Asterisk'
import { startSxpProxy } from '../../../utils/api'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ToastMessage from '../../lms/components/ToastMessage'
import { toastOptions } from '../../lms/models'
import { ADMIN_PROJECT_ID } from '../../../utils/constants'
import ProfilePicture from '../../patientRegstration/ProfilePicture'
import '../admin.scss'
import {
  formValues,
  forms,
  intent,
  messages,
  placeHolder,
  tableText,
} from '../constants'
import { emptyString } from '../../Radiology/Constants/constants'
import { Box, Button } from '@mui/material'
import { useAppSelector } from '../../../app/hooks'
import { selectLocations } from '../../location/locationSlice'

export type FormDoctor = {
  id: string
  salutation: string
  firstName: string
  middleName: string
  lastName: string
  speciality: string
  qualification: string
  regNumber: string
  email: string
  phone: string
  gender: string
  dob: string
  active: boolean
  healthService: string[]
  registeredOn?: string
  photo?: string
  signature?: string
  operatingUnit: string
}

const initialData: FormDoctor = {
  id: '',
  salutation: '',
  firstName: '',
  middleName: '',
  lastName: '',
  speciality: '',
  qualification: '',
  regNumber: '',
  email: '',
  phone: '',
  gender: '',
  dob: '',
  active: true,
  healthService: [],
  registeredOn: '',
  photo: '',
  signature: '',
  operatingUnit: '',
}

const DoctorsForm = ({
  doctor,
  isEdit,
}: {
  doctor: FormDoctor | null
  isEdit: any
}) => {
  const [formData, setFormData] = useState<FormDoctor>(doctor ?? initialData)
  const [specialty, setSpecialty] = useState<any>([])
  const [loading, setLoading] = useState(true)
  const [service, setService] = useState<any>([])
  const [registrationFor, setRegistrationFor] = useState('doctor')
  const [ashaWorkerData, setAshaWorkerData] = useState<any>({})

  const navigate = useNavigate()
  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.getSpecilitiesDoctor)
      .then((data) => {
        const specialtyList = [
          ...(data?.data?.entry?.[1]?.resource?.compose?.include?.[0]
            ?.concept || []),
        ]
        if (specialtyList) {
          setSpecialty(specialtyList)
          setAshaWorkerData(
            data?.data?.entry?.[0]?.resource?.compose?.include[0]?.concept[0]
          )
          setLoading(false)
        }
      })
      .catch((err) => console.log(err))
  }, [])

  useEffect(() => {
    startSxpProxy(ADMIN_PROJECT_ID, intent.getHealthCareService)
      .then((data) => {
        const serviceList = data?.data?.entry?.map(
          (e: { resource: any; id: any; name: any }) => {
            const id = e.resource?.id
            const name = e.resource?.name
            return { id, name }
          }
        )
        setService(serviceList)
      })
      .catch((err) => console.log(err))
  }, [])

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault()

    if (formData?.phone?.length !== 10 || !/^\d{10}$/.test(formData?.phone)) {
      toast.error(messages?.validPhoneMessage)
      return false
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    if (
      formData?.email?.trim() !== '' &&
      !emailRegex?.test(formData?.email.trim())
    ) {
      toast.error('Please enter a valid email address.')
      return false
    }

    if (
      !/^[a-z A-Z]+$/.test(formData.firstName) ||
      !/^[a-z A-Z]+$/.test(formData.lastName)
    ) {
      toast.error(messages.validNameMessage)
      return false
    }

    const spec = formData?.speciality?.split('::')

    const state = {
      id: '',
      active: true,
      lastName: formData?.lastName,
      firstName: formData?.firstName,
      middleName: '',
      salutation: formData?.salutation,
      email: formData?.email,
      mobileNumber: formData?.phone,
      regNumber: formData?.regNumber,
      qualificationValue: formData?.qualification,
      gender: formData?.gender,
      dob: formData?.dob,
      specialtyId: spec[1],
      specialtyName: spec[0],
      healthCareServiceId: formData?.healthService?.join(','),
      photo: formData?.photo,
      signature: formData?.signature,
      operatingUnit: formData?.operatingUnit,
    }
    if (doctor) {
      state.id = doctor.id
      state.active = doctor.active
      startSxpProxy(ADMIN_PROJECT_ID, intent.editDoctor, state)
        .then(() => {
          toast.success(messages.successDoctorMessage, toastOptions)
          navigate('/administration/doctors')
        })
        .catch((error) => {
          toast.error(
            <ToastMessage message={error?.response?.data?.message} />,
            toastOptions
          )
        })
    } else {
      startSxpProxy(ADMIN_PROJECT_ID, intent.createDoc, state)
        .then(() => {
          toast.success(messages.createdDoctorSuccess, toastOptions)
          navigate('/administration/doctors')
        })
        .catch((error) => {
          toast.error(
            <ToastMessage message={error?.response?.data?.message} />,
            toastOptions
          )
          setFormData((prev) => ({ ...prev, phone: emptyString }))
        })
    }
  }

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target
    if (name === formValues.healthService) {
      const options = (event.target as HTMLSelectElement).options
      const vls = []
      for (let i = 0; i < options.length; i++) {
        if (options[i].selected) {
          vls.push(options[i].value)
        }
      }

      setFormData({
        ...formData,
        healthService: vls,
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      })
    }
  }
  const handlePhotoChange = (photo: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      photo: photo,
    }))
  }
  const handleSignatureChange = (signature: string) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      signature: signature,
    }))
  }

  const handleRegistrationForChange = (e: any) => {
    setRegistrationFor(e?.target?.value)
    setFormData((prevState) => ({
      ...prevState,
      speciality: `${ashaWorkerData?.display}::${ashaWorkerData?.code}`,
    }))
  }

  useEffect(() => {
    setRegistrationFor(
      doctor?.speciality?.includes('Asha Worker') ? 'ashaWorker' : 'doctor'
    )
  }, [doctor])

  const locations = useAppSelector(selectLocations)
  const activeLocations = locations?.filter(
    (location: any) => location?.resource?.status === formValues?.active
  )
  const fetchedLocations = activeLocations?.map((d: any) => d?.resource?.name)
  return loading ? (
    <p>{forms?.loading}</p>
  ) : (
    <>
      <div className='main-container'>
        <form className='admin-form' onSubmit={handleSubmit}>
          <div className='admin-form-flex w-72'>
            <div className='doc-container'>
              <label htmlFor='salutation' className='doc-input-label'>
                Registration For
                <Asterisk />
                &nbsp;:
              </label>
              <select
                disabled={isEdit}
                className='doc-input-content'
                required
                name=''
                value={registrationFor}
                onChange={handleRegistrationForChange}
              >
                <option value='doctor'>Doctor</option>
                <option value='ashaWorker'>Asha Worker</option>
              </select>
            </div>

            <div className='doc-container'>
              <label htmlFor='salutation' className='doc-input-label'>
                {forms?.salutation}
                <Asterisk />
                &nbsp;:
              </label>
              <select
                className='doc-input-content'
                required
                name='salutation'
                value={formData?.salutation}
                onChange={handleChange}
              >
                <option value=''></option>
                <option value='Mr'>Mr</option>
                <option value='Mrs'>Mrs</option>
                <option value='Miss'>Miss</option>
                <option value='Dr.'>Dr.</option>
                <option value='Master'>Master</option>
              </select>
            </div>

            <div className='doc-container'>
              <label htmlFor='firstName' className='doc-input-label'>
                {forms?.firstName}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                className='doc-input-content'
                required
                name='firstName'
                type='text'
                placeholder={placeHolder?.doctors}
                value={formData?.firstName}
                onChange={handleChange}
                maxLength={40}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='lastName' className='doc-input-label'>
                {forms?.lastName}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                required
                className='doc-input-content'
                name='lastName'
                type='text'
                placeholder={placeHolder?.doctors}
                value={formData?.lastName}
                onChange={handleChange}
                maxLength={40}
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='gender' className='doc-input-label'>
                {forms?.gender}
                <Asterisk />
                &nbsp;:
              </label>
              <select
                className='doc-input-content'
                required
                name='gender'
                value={formData?.gender}
                onChange={handleChange}
              >
                {formValues?.OperatingUnit}
                <option value={emptyString}></option>
                <option value={formValues?.male}>{formValues?.male}</option>
                <option value={formValues?.female}>{formValues?.female}</option>
                <option value={formValues?.other}>{formValues?.other}</option>
              </select>
            </div>
            <div className='doc-container'>
              <label htmlFor='dob' className='doc-input-label'>
                {forms?.dob}
                {registrationFor === 'doctor' && <Asterisk />}
                &nbsp;:
              </label>
              <input
                required={registrationFor === 'doctor' ? true : false}
                type='date'
                id='dob'
                className='form-control doc-input-content'
                value={formData?.dob}
                onChange={(e) =>
                  setFormData({ ...formData, dob: e.target.value })
                }
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='location' className='doc-input-label'>
                {formValues?.OperatingUnit}
                <span className='required_red'>*</span>:
              </label>
              <select
                id='SelectedLocation'
                name='SelectedLocation'
                value={formData?.operatingUnit}
                onChange={(e) =>
                  setFormData({ ...formData, operatingUnit: e.target.value })
                }
                required
              >
                <option value=''>Select</option>
                <option value='External'>External</option>
                {fetchedLocations?.map((location: any) => (
                  <option key={location} value={location}>
                    {location}
                  </option>
                ))}
              </select>
            </div>

            {registrationFor === 'doctor' && (
              <>
                <div className='doc-container'>
                  <label htmlFor='speciality' className='doc-input-label'>
                    {forms?.speciality}
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <select
                    required
                    name='speciality'
                    value={formData?.speciality}
                    className='doc-input-content'
                    onChange={handleChange}
                  >
                    <option value={emptyString}></option>
                    {specialty?.map((item: any) => (
                      <option
                        key={item?.code}
                        value={`${item?.display}::${item?.code}`}
                      >
                        {item?.display}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='doc-container'>
                  <label htmlFor='service' className='doc-input-label'>
                    {forms?.service}
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <select
                    multiple
                    required
                    name='healthService'
                    value={formData?.healthService}
                    onChange={handleChange}
                    className='doc-input-content'
                  >
                    {service?.map((item: any) => (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    ))}
                  </select>
                </div>

                <div className='doc-container'>
                  <label htmlFor='qualification' className='doc-input-label'>
                    {forms?.qualification}
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <input
                    required
                    name='qualification'
                    type='text'
                    placeholder="Enter Doctor's Qualification"
                    value={formData?.qualification}
                    onChange={handleChange}
                    maxLength={70}
                    className='doc-input-content'
                  />
                </div>

                <div className='doc-container'>
                  <label htmlFor='regNumber' className='doc-input-label'>
                    {forms?.registrationNumber}
                    <Asterisk />
                    &nbsp;:
                  </label>
                  <input
                    required
                    name='regNumber'
                    type='text'
                    placeholder="Enter Doctor's Registration Number"
                    value={formData?.regNumber}
                    onChange={handleChange}
                    maxLength={40}
                    className='doc-input-content'
                  />
                </div>
              </>
            )}

            <div className='doc-container'>
              <label htmlFor='email' className='doc-input-label'>
                {tableText?.email}
                {registrationFor === 'doctor' && <Asterisk />}
                &nbsp;:
              </label>
              <input
                required={registrationFor === 'doctor' ? true : false}
                name='email'
                type='text'
                placeholder={placeHolder?.email}
                value={formData?.email}
                onChange={handleChange}
                className='doc-input-content'
              />
            </div>
            <div className='doc-container'>
              <label htmlFor='phone' className='doc-input-label'>
                {forms?.phoneNumber}
                <Asterisk />
                &nbsp;:
              </label>
              <input
                required
                name='phone'
                type='text'
                placeholder={placeHolder?.phone}
                value={formData?.phone}
                onChange={handleChange}
                maxLength={10}
                className='doc-input-content'
              />
            </div>
            <div className='doc-container'>
              <div className='doc-input-label'></div>
              <div className='doc-input-content'>
                <Box className='box-btn-doctor'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className='submit-btn-doctor'
                  >
                    Submit
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </form>
        <div className='picture-container'>
          <ProfilePicture
            photo={formData.photo as string}
            onPhotoChange={handlePhotoChange}
            item='admin'
          />
          <ProfilePicture
            photo={formData.signature as string}
            onPhotoChange={handleSignatureChange}
            item='signature'
          />
        </div>
      </div>
    </>
  )
}

export default DoctorsForm

// MatchedTestsDialogContent.tsx
import React from 'react'
import { RadiologyLabTest } from '../models'

interface MatchedTestsDialogContentProps {
  dialogMessages: {
    anotherPackage: string
    proceedWithPackage: string
  }
  matchedTests: RadiologyLabTest[]
}

const MatchedTestsDialogContent: React.FC<MatchedTestsDialogContentProps> = ({
  dialogMessages,
  matchedTests,
}) => {
  return (
    <>
      {dialogMessages.anotherPackage}
      <ul>
        {matchedTests?.map((test) => (
          <li key={test?.testId}>{test?.testName}</li>
        ))}
      </ul>
      {dialogMessages.proceedWithPackage}
    </>
  )
}

export default MatchedTestsDialogContent

import React from 'react'
import { readableDateFormat } from '../../../../utils/dateUtils'
import { PatientVisit } from '../../models'
import { useNavigate } from 'react-router-dom'

type Props = {
  visits: PatientVisit[]
  type: string
}

const PatientVisitsTable = ({ visits, type }: Props) => {
  const navigate = useNavigate()

  return (
    <div className='mt20'>
      <p className='collect-header'>Patient Visits</p>
      <table className='admin-table'>
        <thead>
          <tr>
            <th>Source</th>
            <th>Doctor</th>
            <th>Specialty</th>
            <th>Date</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {visits.length > 0 ? (
            visits.map((v) => (
              <tr key={v.id}>
                <td>{v.source}</td>
                <td>{v.doctor}</td>
                <td>{v.specialty}</td>
                <td>{v.date ? readableDateFormat(new Date(v.date)) : ''}</td>
                <td>
                  {v.doctor &&
                    v.source &&
                    (type === 'radiology' ? (
                      <button
                        onClick={() =>
                          navigate(`/visits/${v.id}/radiology-orders`)
                        }
                        className='lms-btn'
                      >
                        View
                      </button>
                    ) : (
                      <button
                        onClick={() => navigate(`/visits/${v.id}/lab-orders`)}
                        className='lms-btn'
                      >
                        View
                      </button>
                    ))}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className='text-center' colSpan={5}>
                No Visits found
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default PatientVisitsTable

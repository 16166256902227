import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useCustomStyles from '../../../utils/hooks/CustomStylesHook'
import { radiologyColor } from '../Utils/RadiologyColors'

type CustomDialogProps = {
  open: boolean
  title: string
  content: React.ReactNode
  onClose: () => void
  onConfirm: () => void
  onCancel?: () => void
  primaryButtonText: string
  secondaryButtonText?: string
  isSecondaryButtonVisible?: boolean
  disableScrollLock?: boolean
}

const styles = () => ({
  dialogIconContainer: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: radiologyColor?.charcoal,
  },
  dialogTitle: {
    fontSize: 14,
    color: radiologyColor?.neutralGray,
  },
})

const CustomDialog = ({
  open,
  title,
  content,
  onClose,
  onConfirm,
  onCancel,
  primaryButtonText,
  secondaryButtonText,
  isSecondaryButtonVisible = true,
  disableScrollLock = true,
}: CustomDialogProps) => {
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      disableScrollLock={disableScrollLock}
    >
      <DialogTitle className={classes?.dialogTitle}>{title}</DialogTitle>
      <IconButton
        size='small'
        aria-label='close'
        onClick={onClose}
        className={classes?.dialogIconContainer}
      >
        <CloseIcon fontSize='small' />
      </IconButton>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        {isSecondaryButtonVisible && (
          <Button onClick={onCancel || onClose} color='secondary'>
            {secondaryButtonText}
          </Button>
        )}
        <Button onClick={onConfirm} color='primary'>
          {primaryButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CustomDialog

import { useRef, useEffect, useState, useCallback, ChangeEvent } from 'react'
import { PackageModel, PaginateModel } from '../membership/models'
import { startSxpProxy } from '../../utils/api'
import '../membership/membership.scss'
import { ADMIN_PROJECT_ID, PACKAGE_PROJECT_ID } from '../../utils/constants'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectSelectedLocationId } from '../location/locationSlice'
import { useNavigate } from 'react-router-dom'
import MaskedNumber from '../../components/MaskedNumber'
import {
  selectQuery,
  selectParam,
  setQueryParam,
} from '../membership/membershipSlice'
import PackageSearch from './PackageSearch'
import SelectableSearch from '../../components/SelectableSearch'
import { next, packageName, previous } from '../Radiology/Constants/constants'
import ProvenanceView from '../../components/ProvenanceView'
import { odooPharmacyLink } from '../appointments/models'

const Packages = () => {
  const query = useAppSelector(selectQuery)
  const param = useAppSelector(selectParam)
  const dispatch = useAppDispatch()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [paginate, setPaginate] = useState<PaginateModel>({
    totalCount: 0,
    totalPages: 0,
    data: [],
  })
  const [packages, setPackages] = useState<PackageModel[]>([])
  const location = useAppSelector(selectSelectedLocationId)
  const [expanded, setExpanded] = useState(false)
  const [expandedIndex, setExpandedIndex] = useState(-1)

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage)
  }

  const toggleExpand = (index: number) => {
    if (index === expandedIndex) {
      setExpanded(!expanded)
    } else {
      setExpanded(true)
      setExpandedIndex(index)
    }
  }

  useEffect(() => {
    setCurrentPage(1)
  }, [limit, query, param])

  const getPackages = useCallback(() => {
    const state = {
      location,
      limit: limit,
      offset: (currentPage - 1) * limit,
    }
    startSxpProxy(PACKAGE_PROJECT_ID, 'purchasedPackages', state)
      .then((data) => {
        const members: PackageModel[] = data?.data?.data ?? []
        setPackages(members)
        setPaginate({
          totalCount: data?.data?.total_count,
          totalPages: data?.data?.total_pages,
          data: data?.data?.data,
        })
      })
      .catch((err) => {
        console.log(err)
        setPackages([])
        setPaginate({
          totalCount: 0,
          totalPages: 0,
          data: [],
        })
      })
      .finally(() => setLoading(false))
  }, [location, limit, currentPage])

  const membershipTableContainerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    const resizeHandler = () => {
      const container = membershipTableContainerRef.current
      if (container) {
        const availableHeight =
          window.innerHeight - container.getBoundingClientRect().top
        container.style.maxHeight = `${availableHeight - 60}px`
      }
    }
    window.addEventListener('resize', resizeHandler)
    resizeHandler()
    return () => {
      window.removeEventListener('resize', resizeHandler)
      dispatch(setQueryParam({ q: '', param: '' }))
    }
  }, [dispatch])

  const handleCreateMembership = () => {
    navigate('/package/create')
  }

  const openOdooInvoice = (id: any) => {
    startSxpProxy(ADMIN_PROJECT_ID, odooPharmacyLink, {
      invoiceId: id,
      location: location,
    })
      .then((data) => {
        const url = data?.data?.auth_link
        window.open(url, '_blank', 'noopener,noreferrer')
      })
      .catch((err) => console.error(err, 'err'))
  }

  const handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLimit(Number(e.target.value))
  }

  const handleSearch = useCallback(
    (query: string, param: string) => {
      let q = ''
      switch (query) {
        case 'ID':
          q = 'partner'
          break
        case 'Phone':
          q = 'phone'
          break
        case 'Name':
          q = 'name'
          break
        default:
          break
      }

      dispatch(setQueryParam({ q, param }))
      if (param.trim() === '') {
        getPackages()
      } else {
        startSxpProxy(PACKAGE_PROJECT_ID, 'getPackageForPatient', {
          location: location,
          muhid: param,
        })
          .then((data: any) => {
            if (data?.first_pack_payment === true) {
              setPaginate({
                totalCount: 0,
                totalPages: 0,
                data: [],
              })
              setCurrentPage(0)
            } else {
              const members: PackageModel[] = data?.data?.data ?? []
              setPackages(members)
              setPaginate({
                totalCount: data?.data?.total_count,
                totalPages: data?.data?.total_pages,
                data: data?.data?.data,
              })
              setCurrentPage(1)
            }
          })
          .catch((error: any) => {
            console.error('API Error:', error)
          })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getPackages]
  )

  useEffect(() => {
    setLoading(true)
    getPackages()
  }, [getPackages])

  const index = (currentPage - 1) * limit
  let startIndex = index + 1
  let endIndex = index + limit

  if (
    !isNaN(paginate.totalCount) &&
    paginate.totalCount !== null &&
    paginate.totalCount !== undefined &&
    paginate.totalCount > 0
  ) {
    startIndex = Math.min(startIndex, paginate.totalCount)
    endIndex = Math.min(endIndex, paginate.totalCount)
  } else {
    startIndex = 0
    endIndex = 0
  }

  const handleSearchRef = useRef(handleSearch)
  useEffect(() => {
    handleSearchRef.current = handleSearch
  }, [handleSearch])

  const handleSearchWithRef = useCallback((query: string, param: string) => {
    handleSearchRef.current(query, param)
  }, [])

  const formatDate = (dateTimeString: string) => {
    const dateTime = new Date(dateTimeString)
    return `${dateTime.toDateString()}`
  }

  const formatTime = (dateTimeString: any) => {
    if (dateTimeString) {
      const dateTime = new Date(dateTimeString)
      const utcTimestamp = dateTime.getTime()
      const istTimestamp = utcTimestamp + 5.5 * 60 * 60 * 1000
      const istDate = new Date(istTimestamp)
      const hours = istDate.getHours().toString().padStart(2, '0')
      const minutes = istDate.getMinutes().toString().padStart(2, '0')
      const seconds = istDate.getSeconds().toString().padStart(2, '0')
      return `${hours}:${minutes}:${seconds}`
    } else {
      return ''
    }
  }

  return (
    <div className='layoutcontent'>
      <div className='membershipText'>{packageName}</div>
      <div className='search-container'>
        <SelectableSearch items={['ID']} onSearch={handleSearchWithRef} />
        <PackageSearch onCreate={handleCreateMembership} />
      </div>
      <div
        className='appointments-table-container'
        ref={membershipTableContainerRef}
      >
        <table
          className='data-table admin-table'
          style={{ position: 'relative' }}
        >
          <thead style={{ position: 'sticky', top: '0px' }}>
            <tr style={{ textAlign: 'center' }}>
              <th className='table-w-5'>Sl No</th>
              <th className='table-w-10'>UHID</th>
              <th className='table-w-17'>Name</th>
              <th className='table-w-10'> Mobile No.</th>
              <th className='table-w-10'>Operating Unit</th>
              <th>Package</th>
              <th className='table-w-15'>Invoice Id</th>
              <th className='table-w-10'>Created On</th>
              <th className='table-w-8'>Pay.Status</th>
            </tr>
          </thead>
          <tbody>
            {packages?.length > 0 ? (
              packages.map((d, i) => (
                <tr key={d.id}>
                  <td className='text-center'>{index + i + 1}</td>
                  <td>{d?.partner_id?.ref}</td>
                  <td>{d?.partner_id?.name}</td>
                  <td className='text-center'>
                    {d?.partner_id?.phone ? (
                      <MaskedNumber mobile={d?.partner_id?.phone} />
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>{d?.operating_unit_id?.[1]?.split(' ')?.[1]}</td>
                  <td>
                    {expanded && expandedIndex === i ? (
                      <div>
                        {d?.invoice_line_ids?.map((item, index) => (
                          <div key={index}>{item.name}</div>
                        ))}
                        <span
                          onClick={() => toggleExpand(i)}
                          style={{
                            color: '#007bff',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                          }}
                        >
                          Read less
                        </span>
                      </div>
                    ) : (
                      <div>
                        {d?.invoice_line_ids[0]?.name}
                        {d?.invoice_line_ids.length > 1 && (
                          <span
                            onClick={() => toggleExpand(i)}
                            style={{
                              color: '#007bff',
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                          >
                            <br />
                            {expanded && expandedIndex === i
                              ? 'Read less'
                              : 'Read more'}
                          </span>
                        )}
                      </div>
                    )}
                  </td>
                  <td className='text-center'>{d?.name}</td>
                  <td className='text-center'>
                    <ProvenanceView
                      name={d?.thop_user_name || '-'}
                      date={
                        formatDate(d?.invoice_date_time) +
                        ' ' +
                        formatTime(d?.invoice_date_time)
                      }
                      mode={'CREATE'}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    {d?.payment_state === 'not_paid' ? (
                      <span
                        style={{
                          padding: '2px 4px',
                          backgroundColor: 'red',
                          color: 'white',
                          borderRadius: 2,
                          marginLeft: 6,
                          fontSize: 11,
                          cursor: 'pointer',
                        }}
                        onClick={() => openOdooInvoice(d?.id)}
                      >
                        Unpaid
                      </span>
                    ) : (
                      <span
                        style={{
                          padding: '2px 4px',
                          backgroundColor: 'teal',
                          color: 'white',
                          borderRadius: 2,
                          marginLeft: 6,
                          fontSize: 11,
                          cursor: 'pointer',
                        }}
                        onClick={() => openOdooInvoice(d?.id)}
                      >
                        Paid
                      </span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={7} style={{ textAlign: 'center' }}>
                  {loading ? 'Loading Packages' : 'No Packages found with uhid'}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className='text-right'>
        <div className='inline-block pagination text-right'>
          <span>
            <span className='mr10'>
              Showing <b>{startIndex}</b> to <b>{endIndex}</b> of{' '}
              <b>{paginate?.totalCount}</b>
            </span>
            <select
              className='rowsperpage'
              defaultValue={limit}
              onChange={handleSizeChange}
            >
              {[10, 20, 30, 40, 50].map((size) => (
                <option key={size}>{size}</option>
              ))}
            </select>
          </span>
        </div>

        <div className='inline-block' style={{ marginRight: '80px' }}>
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            className={`mr10 btn btn-primaryBtn ${
              currentPage !== 1 ? '' : 'disableBtn'
            }`}
            disabled={currentPage === 1}
          >
            {previous}
          </button>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            className={`btn btn-primaryBtn ${
              currentPage !== paginate?.totalPages ? '' : 'disableBtn'
            }`}
            disabled={currentPage === paginate?.totalPages}
          >
            {next}
          </button>
        </div>
      </div>
    </div>
  )
}
export default Packages

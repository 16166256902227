import axios from 'axios'
import KeycloakService from '../utils/keycloakService'
import {
  GET_ALL_BEDS_PATIENTS_ENDPOINT,
  GET_ASSIGNED_PATIENTS_FOR_A_BED_ENDPOINT,
} from '../utils/Endpoints'

export const getAllBedAndPatientsByStatusAndLocation = async (
  bedStatus: string,
  locationId: string,
  page: number,
  size: number,
  searchTerm: string
) => {
  const token = KeycloakService.getToken()
  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${GET_ALL_BEDS_PATIENTS_ENDPOINT}?bedStatus=${bedStatus}&locationId=${locationId}&searchTerm=${searchTerm}&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { data, success, message } = response?.data || {}
    if (success) {
      return {
        success: true,
        data: data,
        message: message,
      }
    } else {
      return {
        success: false,
        data: null,
        message: message,
      }
    }
  } catch (error: any) {
    console.error('Error opening file: ', error)
    return {
      success: false,
      data: null,
      message: error?.message,
    }
  }
}

export const getAssignedPatientForBed = async (
  bedStatus: string | null,
  patientId: string | null,
  bedId: string | null
) => {
  const token = KeycloakService.getToken()
  try {
    const response: any = await axios.get(
      `${process.env.REACT_APP_API_GATEWAY_URL}${GET_ASSIGNED_PATIENTS_FOR_A_BED_ENDPOINT}${bedStatus}&patientId=${patientId}&bedId=${bedId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    const { data, success, message } = response?.data || {}
    if (success) {
      return {
        success: true,
        data: data,
        message: message,
      }
    } else {
      return {
        success: false,
        data: null,
        message: message,
      }
    }
  } catch (error: any) {
    console.error('Error fetching assigned patient for bed: ', error)
    return {
      success: false,
      error: error?.message,
    }
  }
}

/* eslint-disable camelcase */
import KeycloakService from '../../../utils/keycloakService'
import { startSxpProxy } from '../../../utils/api'
import { OPD_PROJECT_ID, RADIOLOGY_PROJECT_ID } from '../../../utils/constants'
import {
  RadiologyLabOrder,
  radiologyIntents,
  visit,
  radiologyTestStatus,
  patientText,
  radiologyModes,
  packageLabel,
  tabs,
  patientIdentifier,
  thopIdText,
  genderConstants,
  mobileText,
  registrationConstants,
} from '../Constants/constants'
import { RadiologyOrders, RadiologyResult } from '../models'
import { makeName } from '../../lms/utils'
import { getTotalAge } from '../../../utils/dateUtils'
import { Patient } from 'fhir/r4'

export const saveOrder = (
  mode: 'visit' | 'patient' | undefined,
  order: RadiologyLabOrder,
  id: string,
  patientId: string,
  uhid: string,
  lrNumber: string,
  filteredPanels?: any,
  filteredSamples?: [],
  source?: string,
  packageId?: number,
  price?: number,
  requestedBy?: string,
  origin?: string,
  packageIds?: any,
  combinedResult?: any
) => {
  const intent = order?.id
    ? radiologyIntents?.updateRadiologyOrderById
    : radiologyIntents?.createRadiologyOrder
  const state = {
    radiologyOrderId: order?.id,
    order: order,
    visitId: mode === visit && id ? parseInt(id) : undefined,
    radiologyLabTests: filteredPanels,
    radiologySamples: filteredSamples,
    lrNumber: order?.lr_number ?? lrNumber,
    orderedByName: KeycloakService.getFullname(),
    orderedById: KeycloakService.getSub(),
    patientId: mode === patientText ? id : order?.patient_id ?? patientId,
    patientUhid: uhid ?? order?.patient_uhid ?? undefined,
    status: order?.status ?? radiologyTestStatus?.ordered,
    source: order?.source ?? source ?? undefined,
    packageId: packageId,
    price: price,
    requestedBy: requestedBy,
    origin: origin,
    packageIds: packageIds,
    combinedResult: combinedResult,
  }
  return startSxpProxy(RADIOLOGY_PROJECT_ID, intent, state)
}
export const getDoctorForVisit = (id: string): Promise<string> => {
  const state = { id: id }
  return startSxpProxy(
    OPD_PROJECT_ID,
    radiologyIntents?.getDoctorByVisit,
    state
  )
    .then((doctor) => {
      const { name, qualification } = doctor.data
      return `${name}${qualification ? ` [${qualification}]` : ''}`.trim()
    })
    .catch(() => '')
}
export const TotalPackagePrice = (tests: any[]) => {
  const totalPrice = tests?.reduce((sum, row) => {
    return sum + (row?.packageTests?.price || 0)
  }, 0)
  return totalPrice
}
export const transformData = (data: any, mapped: any) => {
  if (!data?.id) {
    return []
  } else {
    const { package_ids, radiology_order_tests, origin, requested_by } = data
    const radiologyOrderTests = radiology_order_tests?.filter(
      (e: any) => e?.test_status !== radiologyTestStatus?.deleted
    )
    const testModeResults = radiologyOrderTests
      ?.filter((test: any) => test.is_test_select)
      ?.map((test: any) => ({
        mode: { value: 'test', label: 'Test' },
        origin: { value: origin, label: origin },
        requestedBy: { value: requested_by, label: requested_by },
        sampleName: {
          label: test.sample_name,
          value: test.sample_id?.toString(),
        },
        testName: { label: test.test_name, value: test.test_id },
        packageName: null,
        packageTests: null,
      }))

    const packageModeResults = package_ids?.map((packageId: any) => {
      const packageInfo = mapped?.find(
        (pkg: any) => pkg?.packageId === parseInt(packageId)
      )
      const modifiedPackageTests = radiologyOrderTests?.filter((test: any) =>
        test?.package_ids?.includes(packageId)
      )

      return {
        mode: { value: radiologyModes?.package, label: packageLabel },
        origin: { value: origin, label: origin },
        packageName: {
          label: packageInfo?.packageName,
          value: packageInfo?.packageId,
          isDisabled: false,
        },
        packageTests: {
          packageId: packageInfo?.packageId,
          packageName: packageInfo?.packageName,
          price: packageInfo?.price,
          testCount: modifiedPackageTests?.length?.toString(),
          radiology_lab_tests: modifiedPackageTests?.map((test: any) => ({
            testId: test?.test_id,
            sampleId: test?.sample_id?.toString(),
            testName: test?.test_name,
            sampleName: test?.sample_name,
          })),
        },
        requestedBy: { value: requested_by, label: requested_by },
        sampleName: null,
        testName: null,
      }
    })
    const result = [...testModeResults, ...packageModeResults]
    return result
  }
}
export const TestEnteredFilter = (order: RadiologyOrders | null) => {
  if (order) {
    const filteredTests = order?.radiology_test_results?.filter((test: any) => {
      if (test?.test_status === radiologyTestStatus?.entered) {
        const hasValues = test?.values?.summary
        const referredOutWithDocument = test?.referred_out
          ? !!test?.document_url
          : true

        return hasValues && referredOutWithDocument
      }

      return false
    })

    return filteredTests
  }
}
export const TestSubmittedFilter = (order: RadiologyOrders | null) => {
  if (order) {
    const enteredTests = order?.radiology_order_tests?.filter(
      (test: any) => test?.test_status === radiologyTestStatus?.submitted
    )

    return enteredTests
  }
}
export const filteredTabResults = (
  results: RadiologyResult[],
  activeTab: string
) => {
  let statuses: string[]
  switch (activeTab) {
    case tabs?.testing:
      statuses = [
        radiologyTestStatus?.ordered,
        radiologyTestStatus?.orderModified,
        radiologyTestStatus?.entered,
        radiologyTestStatus?.referredEntered,
        radiologyTestStatus?.reOrdered,
      ]
      break
    case tabs?.validation:
      statuses = [radiologyTestStatus?.submitted]
      break
    default:
      statuses = []
  }

  return results?.filter((result) => statuses?.includes(result?.test_status))
}
export const getPatientIdentifierById = (patient: Patient) => {
  const uhid = patient?.identifier?.find(
    (e: any) => e?.system === patientIdentifier
  )?.value
  return uhid ?? ''
}
export const RegistrationType = (regType: string) => {
  switch (regType) {
    case registrationConstants?.fr:
      return registrationConstants?.full
    case registrationConstants?.qr:
      return registrationConstants?.quick
    case registrationConstants?.erShort:
      return registrationConstants?.er
    case registrationConstants?.pmc:
      return registrationConstants?.pharmacy
    case registrationConstants?.labShort:
      return registrationConstants?.lab
    case registrationConstants?.dgs:
      return registrationConstants?.diagnostics
    case registrationConstants?.camp:
      return registrationConstants?.campaign
    case registrationConstants?.membershipShort:
    case registrationConstants?.membershipPrimary:
      return registrationConstants?.membership
    case registrationConstants?.packageshort:
      return registrationConstants?.package
    default:
      return registrationConstants?.other
  }
}
export const mapPatients = async (
  nodes: RadiologyOrders[]
): Promise<RadiologyOrders[]> => {
  try {
    const nodeIds = nodes?.map((n) => n?.patient_id)
    const ids = Array.from(new Set(nodeIds)).join(',')
    const response = await startSxpProxy(
      OPD_PROJECT_ID,
      radiologyIntents?.getPatientsByIdsApi,
      { ids }
    )
    const entry = response?.data?.entry
    if (entry?.length) {
      const patientObj = entry?.reduce(
        (acc: any, cur: any) => ({ ...acc, [cur?.resource?.id]: cur }),
        {}
      )
      return nodes.map((node) => {
        const pat = node.patient_id ? patientObj[node?.patient_id] : null
        const thopId = pat?.resource?.identifier?.[0]?.value ?? thopIdText
        const gender = pat?.resource?.gender
        const assignedGender =
          gender === genderConstants?.male
            ? genderConstants?.capitalMale
            : gender === genderConstants?.female
            ? genderConstants?.capitalFemale
            : genderConstants?.capitalOthers
        return {
          ...node,
          patientThopId: thopId,
          patientUhid:
            pat?.resource?.identifier?.find(
              (e: any) => e?.system === patientIdentifier
            )?.value ?? thopId,
          patientName: makeName(pat?.resource?.name),
          patientGender: assignedGender,
          patientMobile: pat?.resource?.telecom?.find(
            (prt: any) => prt?.use === mobileText
          )?.value,
          patientAge: getTotalAge(
            new Date(pat?.resource?.birthDate ?? new Date())
          ),
        }
      })
    }
    return nodes
  } catch (er) {
    return nodes
  }
}
export const mappedPatients = async (nodes: any): Promise<any> => {
  try {
    const nodeIds = nodes?.map((n: any) => n?.patient_registered_id)
    const ids = Array.from(new Set(nodeIds)).join(',')
    const response = await startSxpProxy(
      OPD_PROJECT_ID,
      radiologyIntents?.getPatientsByIdsApi,
      { ids }
    )
    const entry = response?.data?.entry
    if (entry?.length) {
      const patientObj = entry?.reduce(
        (acc: any, cur: any) => ({ ...acc, [cur.resource.id]: cur }),
        {}
      )
      return nodes.map((node: any) => {
        const pat = node?.patient_registered_id
          ? patientObj[node?.patient_registered_id]
          : null
        const gender = pat?.resource?.gender
        const assignedGender =
          gender === genderConstants?.male
            ? genderConstants?.capitalMale
            : gender === genderConstants?.female
            ? genderConstants?.capitalFemale
            : genderConstants?.capitalOthers
        return {
          patientName: makeName(pat?.resource?.name),
          patientGender: assignedGender,
          patientMobile: pat?.resource?.telecom?.find(
            (prt: any) => prt?.use === mobileText
          )?.value,
          patientAge: getTotalAge(
            new Date(pat?.resource?.birthDate ?? new Date())
          ),
          id: node?.id,
          bedId: node?.bed_id,
          bedStatus: node?.bed_status,
          patientId: node?.patient_id,
          dateCreated: node?.date_created,
          dateStarted: node?.date_started,
          dateOfDischarge: node?.date_of_discharge,
          bedNumber: node?.bed.bed_number,
          location: node?.bed.location_id,
          patient_registered_id: node?.patient_registered_id,
          assigned_doctor: node?.assigned_doctor,
          doctor_department: node?.doctor_department,
        }
      })
    }
    return nodes
  } catch (er) {
    return nodes
  }
}
export const removeSampleDuplicates = (arr: any) => {
  const filteredArray = arr?.map((e: any) => e?.sample_name)
  return [...new Set(filteredArray)]
}
export const groupByTestName = (data: any) => {
  const grouped: any = {}

  data?.forEach((item: any) => {
    if (!grouped?.[item?.test_name]) {
      grouped[item?.test_name] = []
    }
    grouped?.[item?.test_name]?.push(item)
  })

  return Object.keys(grouped)?.map((testName) => ({
    testName,
    testStatuses: grouped[testName],
  }))
}

import { AdminTableProps } from './Constants/constants'
import { readableDateFormat, shortDateRange } from '../../utils/dateUtils'
import { useAppSelector } from '../../app/hooks'
import { selectedLmsAdminSearch } from '../lms/lmsSlice'
import { getSearchResults } from '../lms/utils'
import './radiology.scss'
import useCustomStyles from '../../utils/hooks/CustomStylesHook'
import { Box, useTheme } from '@mui/material'
import { ColDef, ITextFilterParams } from 'ag-grid-community'
import MedAgDataGrid from '../../components/MedAgDataGrid'
import { radiologyColor } from './Utils/RadiologyColors'
const styles = () => ({
  radiologyTestContainer: {
    width: '100%',
    height: '100%',
  },
  centerHeader: {
    color: radiologyColor?.white,
    textAlign: 'center',
    '& .ag-header-cell-text': {
      flex: 1,
    },
  },
})

const RadiologyPackages = ({ data, onEdit }: AdminTableProps) => {
  const searchParam = useAppSelector(selectedLmsAdminSearch)
  const filtered = getSearchResults(data, searchParam)
  const theme = useTheme()
  const classes = useCustomStyles(styles, theme)
  const renderActive = (params: any) => {
    return shortDateRange(params?.data?.active_from, params?.data?.active_till)
  }
  const handleTestCount = (params: any) => {
    return params?.data?.radiology_lab_tests?.length ?? 0
  }
  const handleCreatedOn = (params: any) => {
    return readableDateFormat(new Date(params?.data?.created_at ?? ''))
  }
  const renderName = (params: any) => {
    return (
      <span onClick={() => onEdit(params?.data?.id)}>{params?.data?.name}</span>
    )
  }

  const columnDefs: ColDef[] = [
    {
      headerName: 'Sl No',
      field: 'id',
      width: 90,
      sortable: true,
      filter: false,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', textAlign: 'center' },
    },
    {
      headerName: 'Package Name',
      field: 'name',
      flex: 1,
      sortable: true,
      filter: false,
      cellRenderer: renderName,
      headerClass: classes?.centerHeader,
      cellClass: 'custom-header-cell',
      cellStyle: {
        fontSize: '12px',
        textAlign: 'center',
        cursor: 'pointer',
        color: radiologyColor?.blue,
      },
      minWidth: 165,
    },
    {
      headerName: 'Price',
      field: 'price',
      flex: 1,
      sortable: true,
      filter: true,
      filterParams: { buttons: ['clear'] } as ITextFilterParams,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 50,
    },
    {
      headerName: 'Active',
      field: 'active',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: renderActive,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 145,
    },
    {
      headerName: 'Test Count',
      field: 'testCount',
      flex: 1,
      sortable: true,
      filter: true,
      cellRenderer: handleTestCount,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
      minWidth: 70,
    },
    {
      headerName: 'Created By',
      field: 'created_by',
      flex: 1,
      sortable: true,
      filter: true,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px', display: 'flex' },
      minWidth: 140,
    },
    {
      headerName: 'Created On',
      field: 'created_on',
      flex: 1,
      minWidth: 120,
      sortable: true,
      filter: true,
      cellRenderer: handleCreatedOn,
      cellClass: 'custom-header-cell',
      headerClass: 'custom-header-cell',
      cellStyle: { fontSize: '12px' },
    },
  ]
  return (
    <>
      <Box className={classes?.radiologyTestContainer}>
        <MedAgDataGrid
          tableHeight={'100%'}
          rowData={filtered}
          columnDefs={columnDefs}
          pagination={false}
          headerHeight={30}
          searchFieldWidth={200}
          exportIcon={false}
          searchField={false}
        />
      </Box>
    </>
  )
}

export default RadiologyPackages

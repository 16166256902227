import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  TextareaAutosize,
  useTheme,
} from '@mui/material'
import useCustomStyles from '../../../../utils/hooks/CustomStylesHook'
import { useState, useEffect } from 'react'
import { startSxpProxy } from '../../../../utils/api'
import { RADIOLOGY_PROJECT_ID } from '../../../../utils/constants'
import * as Yup from 'yup'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import Asterisk from '../../../../components/Asterisk'
import { useFormik } from 'formik'
import KeycloakService from '../../../../utils/keycloakService'
import { fieldRequriedError } from '../../../ipRegistration/Constants'
import LmsAdmin from './LmsAdmin'
import {
  selectedLmsFormMode,
  selectedLmsItem,
  selectedLmsItemId,
  setLmsFormMode,
  setLmsItem,
} from '../../lmsSlice'
import { adminText } from '../../models'
import {
  radiologyIntents,
  radiologyTestForm,
} from '../../../Radiology/Constants/constants'
import { radiologyColor } from '../../../Radiology/Utils/RadiologyColors'
interface FormState {
  name: string
  description: string
  interpretation: string
  handledAt: string
  status: string
  price: string
  radiologySampleId: string
  odooTestId: any
}
const styles = () => ({
  testRegisterFormContainer: {
    width: '100%',
    height: '100%',
    padding: '0.6% 3% 3% 3%',
    '& .MuiInputBase-input': {
      fontSize: '12px !important',
    },
    '& .MuiInputLabel-root': {
      fontSize: '12px !important',
    },
    '& .MuiSelect-select': {
      padding: '6px 8px',
    },
    '& .MuiFormControlLabel-label': {
      fontSize: '12px !important',
      fontWeight: 500,
    },
    '& .css-1hpqosy-MuiFormControlLabel-root': {
      marginLeft: '5px !important',
      marginRight: '5px !important',
    },
  },
  testRegisterFormRow: {
    display: 'flex',
    justifyContent: 'start',
    marginBottom: '1rem',
    flexWrap: 'wrap',
    rowGap: 20,
  },
  testRegisterFormFiield: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    fontSize: '12px',
    fontWeight: '600',
  },
  testRegisterHeading: {
    fontSize: '14px',
    fontWeight: '600',
    color: radiologyColor?.deepBlue,
    textDecoration: 'underline',
    textAlign: 'center',
  },
  testRegisterFormActions: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  testRegisterFormColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    gap: 20,
    marginBottom: 30,
  },
  interpretationTestArea: {
    maxHeight: '200px',
    overflow: 'auto',
    resize: 'vertical',
  },
})
const RadiologyTestForm = ({ testId }: { testId?: string }) => {
  const itemId = useAppSelector(selectedLmsItemId)
  const item = useAppSelector(selectedLmsItem)
  const mode = useAppSelector(selectedLmsFormMode)
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const [showAdminConfig, setShowAdminConfig] = useState(false)
  const classes = useCustomStyles(styles, theme)
  const [testData, setTestData] = useState({
    name: '',
    description: '',
    interpretation: '',
    handledAt: '',
    status: '',
    price: '',
    radiologySampleId: '',
    odooTestId: '',
  })
  const [createOdoo, setCreateOdoo] = useState(true)
  const [sampleOptions, setSampleOptions] = useState<
    { id: number; sampleName: string }[]
  >([])
  const isEditable = !testData?.odooTestId
  useEffect(() => {
    if (mode === adminText?.edit && item === adminText?.radiologyTest) {
      getTestById()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testId])
  useEffect(() => {
    getAllSamples()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleCheckboxChange = () => {
    if (isEditable) {
      setCreateOdoo((prevChecked) => !prevChecked)
    }
  }

  const getTestById = () => {
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getRadiologyTestById,
      {
        radiologyTestId: itemId,
      }
    ).then((data) => {
      const test = data?.data?.radiology_lab_test_by_pk
      setTestData({
        name: test?.name,
        description: test?.description,
        interpretation: test?.Interpretation,
        handledAt: test?.handled_at,
        status: test?.status ?? '',
        price: test?.price ?? 0,
        radiologySampleId: test?.sample_id,
        odooTestId: test?.odoo_test_id ?? '',
      })
    })
  }
  const getAllSamples = () => {
    startSxpProxy(
      RADIOLOGY_PROJECT_ID,
      radiologyIntents?.getRadiologySamplesAPI,
      {}
    ).then((data) => {
      const samples = data?.data?.radiology_sample?.map((e: any) => ({
        id: e?.id,
        sampleName: e?.name,
      }))
      setSampleOptions(samples)
    })
  }
  const { values, errors, touched, handleBlur, handleChange, submitForm } =
    useFormik<FormState>({
      initialValues: testData,
      validationSchema: Yup.object({
        name: Yup.string().required(fieldRequriedError),
        price: Yup.number(),
      }),
      onSubmit: (values) => {
        handleRegistrationSubmit(values)
      },
      enableReinitialize: true,
    })

  const handleRegistrationSubmit = async (data: Record<string, any>) => {
    const {
      name,
      description,
      interpretation,
      handledAt,
      price,
      status,
      radiologySampleId,
      odooTestId,
    } = data
    const formatted = {
      name: name,
      radiologyTestId: itemId,
      description: description,
      Interpretation: interpretation,
      handled_at: handledAt,
      price: price,
      odooTestId: odooTestId,
      status: status,
      sampleId: radiologySampleId,
      isodoo: createOdoo,
      createdBy: KeycloakService?.getUsername(),
      updatedBy: KeycloakService?.getUsername(),
    }
    const intent = itemId
      ? radiologyIntents?.updateRadiologyLabTest
      : radiologyIntents?.createRadiologyLabTests
    startSxpProxy(RADIOLOGY_PROJECT_ID, intent, formatted).then(() => {
      setShowAdminConfig(true)
      dispatch(setLmsItem('radiologyTest'))
      dispatch(setLmsFormMode('none'))
    })
  }
  if (showAdminConfig) {
    dispatch(setLmsItem('radiologyTest'))
    dispatch(setLmsFormMode('none'))
    return <LmsAdmin />
  }
  return (
    <>
      <div className={classes?.testRegisterFormContainer}>
        <form>
          <div className={classes?.testRegisterFormColumn}>
            <div className={classes?.testRegisterHeading}>
              {radiologyTestForm}
            </div>
            <Grid
              container
              spacing={1}
              className={classes?.testRegisterFormRow}
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.testRegisterFormFiield}
              >
                <label>
                  Name
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='name'
                  placeholder='Enter Name'
                  value={values?.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.testRegisterFormFiield}
              >
                <label>
                  Description
                  <Asterisk />
                </label>
                <TextField
                  size='small'
                  name='description'
                  placeholder='Enter Description'
                  value={values.description}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.testRegisterFormFiield}
              >
                <label>Radiology Sample Id</label>
                <TextField
                  select
                  size='small'
                  name='radiologySampleId'
                  placeholder='radiologySampleId'
                  value={values?.radiologySampleId}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  {sampleOptions?.map((option) => (
                    <MenuItem key={option?.id} value={option?.id}>
                      {option?.sampleName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.testRegisterFormFiield}
              >
                <label>Handled At</label>
                <TextField
                  select
                  size='small'
                  name='handledAt'
                  placeholder='Handled AT'
                  value={values?.handledAt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value='medunited'>Medunited</MenuItem>
                  <MenuItem value='outSourced'>Outsourced</MenuItem>
                </TextField>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.testRegisterFormFiield}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name='isodoo'
                      onChange={handleCheckboxChange}
                      checked={
                        createOdoo &&
                        (!itemId ||
                          (Boolean(itemId) && Boolean(testData?.odooTestId)))
                      }
                    />
                  }
                  label='Is Odoo'
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.testRegisterFormFiield}
              >
                <label>Interpretation</label>
                <TextareaAutosize
                  minRows={5}
                  name='interpretation'
                  placeholder='interpretation'
                  value={values?.interpretation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes?.interpretationTestArea}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                xl={3}
                className={classes?.testRegisterFormFiield}
              >
                <label>Price</label>
                <TextField
                  size='small'
                  name='price'
                  placeholder='price'
                  value={values?.price}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            </Grid>
          </div>
          <div className={classes?.testRegisterFormActions}>
            <Button
              variant='contained'
              color='primary'
              size='small'
              className='ip-register-btn test-submit-btn'
              onClick={submitForm}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </>
  )
}
export default RadiologyTestForm
